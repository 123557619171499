import React from 'react'
import thumb from "../../assets/images/home/Thumbs_Up.png"

const Thank = () => {
  return (
    <>

    <section className='thank-you'>

        <div className='container'>
            <div className='d-flex justify-content-center flex-column align-items-center vh-100'> 
            <div className='thumbs'>
            <img src={thumb} alt="" />
            </div>
           
            <h1 className='thankyou-title'>
            We have received Your Inquiry!
            </h1>
            <p className='cust-width text-light w-50' style={{ textAlign: 'center' }}>Thank you for reaching out to us. One of our team member will get back to you with a possible solution. We appreciate your interest in TaskTak & look forward for the opportunity to work with you.</p>
            <a href="/" className='col-md-auto col-5 btn btn-yellow border-0 rounded-pill fs-5 lh-23 px-4 py-2 me-2 me-md-4 my-margin-none' style={{ color: '#fff' }}>Explore More</a>
            </div>
            
        </div>
    </section> 
   



    
    </>
  )
}

export default Thank