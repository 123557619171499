import React from 'react'
import { useLocation } from "react-router-dom";

import { Container, Row, Accordion } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
const Accordions = () => {
	const { t } = useTranslation(["accordion"]);
	const { pathname } = useLocation();
	return (
		<section className='mb-5'>
			<Container>
				<Row>
					<div className="col-10 col-md-12 text-center text-md-start mx-auto">
						<div className='col-10 col-md-7 mb-5 mx-auto text-center'>
							<h5 className='fw-medium addons-title'>Frequent questions</h5>
							<p>Do you have some questions ? Find answers in our FAQ.</p>
						</div>
					</div>
					{pathname === "/npo" ? (
						<>
					<div className="col-12 mt-5 col-md-6 mx-auto ms-md-auto mt-5 mt-md-0 ft-p-0">
						<div className='acc'>
							<h5>{t("faq_na")}</h5>
							<p>{t("faq_na_desc")}</p>
						</div>
						<div className='acc'>
							<h5>{t("faq_nb")}</h5>
							<p>{t("faq_nb_desc")}</p>
						</div>
						<div className='acc'>
							<h5>{t("faq_nc")}</h5>
							<p>{t("faq_nc_desc")}</p>
						</div>
						<div className='acc'>
							<h5>{t("faq_nd")}</h5>
							<p>{t("faq_nd_desc")}</p>
						</div>
					</div>
					
					<div className="col-12 mt-5 col-md-6 mx-auto ms-md-auto mt-5 mt-md-0 ft-p-0">
						<div className='acc'>
							<h5>{t("faq_ne")}</h5>
							<p>{t("faq_ne_desc")}</p>
						</div>
						<div className='acc'>
							<h5>{t("faq_nf")}</h5>
							<p>{t("faq_nf_desc")}</p>
						</div>
						<div className='acc'>
							<h5>{t("faq_ng")}</h5>
							<p>{t("faq_ng_desc")}</p>
						</div>
					</div>
					</>
					):(
					<>
					<div className="col-12 mt-5 col-md-6 mx-auto ms-md-auto mt-5 mt-md-0 ft-p-0">
						<div className='acc'>
							<h5>{t("faq_a")}</h5>
							<p>{t("faq_a_desc")}</p>
						</div>
						<div className='acc'>
							<h5>{t("faq_b")}</h5>
							<p>{t("faq_b_desc")}</p>
						</div>
						<div className='acc'>
							<h5>{t("faq_c")}</h5>
							<p>{t("faq_c_desc")}</p>
						</div>
						<div className='acc'>
							<h5>{t("faq_d")}</h5>
							<p>{t("faq_d_desc")}</p>
						</div>
					</div>
					<div className="col-12 mt-5 col-md-6 mx-auto ms-md-auto mt-5 mt-md-0 ft-p-0">
						<div className='acc'>
							<h5>{t("faq_e")}</h5>
							<p>{t("faq_e_desc")}</p>
						</div>
						<div className='acc'>
							<h5>{t("faq_f")}</h5>
							<p>{t("faq_f_desc")}</p>
						</div>
						<div className='acc'>
							<h5>{t("faq_g")}</h5>
							<p>{t("faq_g_desc")}</p>
						</div>
					</div>
					</>
					)}

				</Row>
			</Container>
		</section >
	);
}
export default Accordions