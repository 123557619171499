import React from "react";
import Global_Section_Main from "./Global_Section_Main";
import Global_Section_A from "./Global_Section_A";
import Global_Section_B from "./Global_Section_B";
import Banner from "../../../assets/images/excel-banner.svg";
import Section_A from '../../../assets/images/excel-a.svg';
import Section_B from '../../../assets/images/excel-b.svg';
import Section_C from '../../../assets/images/excel-c.svg';

import { useTranslation } from "react-i18next";


const Excel = () => {
    const { t } = useTranslation(["innerpages"]);
    return (
      <>

        <Global_Section_Main
          title={'CRM Excel Spreadsheet'}
          description={'Using TaskTak BMP, you can manage, share, and edit files among your team members and with external users. It means that team members can collaborate and work on projects together, even if they are in different locations. It also helps to ensure that sensitive information remains secure, as access permissions can be set and managed to control who has access to what documents.'}
          image={Banner}
        />
        <Global_Section_A
          title={'One-stop cloud storage'}
          description={'It is a one-stop information database containing all the necessary files accessible from any device. With TaskTak, documents can be uploaded, and shared, access permissions can be managed, templates can be easily accessed, and real-time collaboration is possible. '}
          image={Section_A}
          color="gray mt-top"
        />
        <Global_Section_B
          title={'No more multiple spreadsheets'}
          description={'An all-in-one client relation management allows you to manage all of your business activities in one window interface. With this tool, multiple spreadsheets can be opened at the same time, duplicate data can be avoided, and time will not be wasted.'}
          image={Section_B}
          color="gray"
        />
        <Global_Section_A
          title={'Works remotely'}
          description={'If the spreadsheets are stored on office computers, the employee will be unable to quickly resolve the problem after leaving the office. On the other hand, CRM systems provide an easy and secure way of accessing and managing data from any device, so that employees can continue to work without disruption.'}
          image={Section_C}
          color="white"
        />
      </>
    );
}

export default Excel