import React from 'react'


const Terms = () => {
  return (
    <>

    <div className="privacy-sec">
      <div className="container">
        <div className="privacy-inner">
          <h1 className="main-heading-privacy">Terms And Conditions</h1>
          <p className="date v-small-date"><b>November 2, 2021</b></p>
          <div className="privacy-conatiner">
            <p className="pb-3">
              PLEASE READ THE FOLLOWING TERMS OF SERVICE AGREEMENT CAREFULLY. BY ACCESSING OR USING OUR WEBSITE (THE "SITE") OR OUR SERVICES, YOU HEREBY AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS AND ALL TERMS INCORPORATED HEREIN BY REFERENCE. IT IS THE RESPONSIBILITY OF YOU, THE USER, CUSTOMER, OR PROSPECTIVE CUSTOMER TO READ THE TERMS AND CONDITIONS BEFORE PROCEEDING TO USE THIS SITE. IF YOU DO NOT EXPRESSLY AGREE TO ALL OF THE TERMS AND CONDITIONS, THEN PLEASE DO NOT ACCESS OR USE OUR SITE OR OUR SERVICES.

              The present terms and conditions (this "Agreement" or "Terms") is a legal agreement between you and Tuslr Technologies Inc. (hereinafter " Tuslr"), a company duly organized and validly existing, located at Redmond, Washington 98052. This Agreement annuls and voids all previous agreements.
            </p>

            <p className="pb-3">
            The Site (https://tasktak.com/) is operated by Tuslr. Throughout the Site, the terms "we", "us" and "our" refer to Tuslr. Tuslr offers this Site, including all information, tools and services available from this Site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.
            </p>

            <p className="pb-3">
            By visiting our Site and/or purchasing something from us, you engage in our "Service" and agree to be bound by the following terms and conditions, including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms apply to all users of the Site, including without limitation users who are browsers, vendors, customers, merchants, and/or contributors of content. In the event of an inconsistency between this Agreement and any additional terms or policies referenced herein, the provisions of the additional terms or policies shall control.
            </p>

            <p className="pb-3">
            Please read these Terms carefully before accessing or using our Site. By accessing or using any part of the Site, you agree to be bound by these Terms. If you do not agree to all the Terms of this Agreement, then you may not access the Site or use any Service. If these Terms are considered an offer, acceptance is expressly limited to these Terms.
            </p>
            <p className="pb-3">
            Any new features or tools which are added to the current store shall also be subject to the Terms. You can review the most current version of the Terms at any time on this page. We reserve the right to update, change or replace any part of these Terms by posting updates and/or changes to our Site. It is your responsibility to check this page periodically for changes. Your continued use of or access to the Site following the posting of any changes constitutes acceptance of those changes.
            </p>



            <h5>SECTION 1 - GENERAL TERMS</h5>

            <p className="pb-3">
            By agreeing to these Terms, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence, and you have given us your consent to allow any of your minor dependents to use this Site.
            </p>

            <p className="pb-3">
            You may not use our products or Site for any illegal or unauthorized purpose nor may you, in the use of our products or Site, violate any laws in your jurisdiction (including but not limited to motor vehicle laws).
            </p>

            <p className="pb-3">
            You must not transmit any worms or viruses or any code of a destructive nature. A breach or violation of any of the Terms will result in an immediate termination of your account and right to use our Service.
            </p>

            
            <p className="pb-3">
            We have the right, but not the obligation, to take any of the following actions in our sole discretion at any time and for any reason without giving you any prior notice:
            </p>





            <ol type="1">
              <li>
              Restrict, suspend or terminate your access to all or any part of our Site;
              </li>
              <li>
              Change, suspend or discontinue all or any part of our products or Site;
              </li>
              <li>
              Refuse, move, or remove any content that is available on all or any part of our Site;
              </li>
              <li>
             
                Deactivate or delete your accounts;
              </li>
              <li>
              Establish general practices and limits concerning use of our Site.
              </li>
            </ol>

            <p className="pb-3">
            You agree that we will not be liable to you or any third party for taking any of these actions. You understand and agree that our Site may include communications such as service announcements and administrative or legal notices from us. Please note that you cannot opt out of receiving these notices.
            </p>

            <p className="pb-3">
            You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform andadapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.
            </p>
            <p className="pb-3">
            You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Site, use of theSite, or access to the Site or any contact on the Site, without express written permission by us.You may not modify, publish, transmit, reverse engineer, participate in the transfer or sale,createderivative works, or in any way exploit any of the content, in whole or in part, found on the Site. Tasktak content is not for resale. Use of the Site does not entitle users to make any unauthorized use of anyprotected content, and in particular you will not delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for your personal use and will make no other use of the content without the express written permission of Tasktak and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to the intellectual property of Tasktak or our licensors except as expressly authorized by these Terms.
            </p>


            <h4>SECTION 2 - CREATING AN ACCOUNT</h4>

            <p className="pb-3">
            Once you create an account with us, you are registered on the Tasktak Site. The terms "member," "membership," and "account" all refer to this registration as a member on Tasktak's Site. If you are merely surfing or browsing through the Site and have not yet created an account, your use of the Site is still subject to this Agreement; if you do not agree to this Agreement, do not use the Site.
            </p>

            <p className="pb-3">
            When you create an account, you will provide a unique username and email. We will also ask you to create a password. Because any activities that occur under your username or password are your responsibility it is important for you to keep your username and/or password secure. You may not assign or otherwise transfer your account to any other person or entity. You acknowledge that Tasktak is not responsible for third party access to your account that results from theft or misappropriation of your account. Notify us immediately if you believe that someone has used your username, email, or password without your authorization. Furthermore, the registering party hereby acknowledges, understands and agrees to:
            </p>

            <ul>
              <li>
              furnish factual, correct, current and complete information with regards to yourself as may be requested by the data registration process, and
              </li>
              <li>
              maintain and promptly update your registration and profile information in an effort to maintain accuracy and completeness at all times.
              </li>
              
             
            </ul>
            <p>
            If anyone knowingly provides any information of a false, untrue, inaccurate or incomplete nature, Tasktak Technologies Inc. will have sufficient grounds and rights to suspend or terminate the member in violation of this aspect of the Agreement, and as such refuse any and all current or future use of Tasktak Technologies Inc. Services, or any portion thereof.
            </p>

            <h4>SECTION 3 - CONDUCT</h4>
            <p className="pb-3">
            As a user or member of the Site, you herein acknowledge, understand and agree that all information, text, software, data, photographs, music, video, messages, tags or any other content, whether it is publicly or privately posted and/or transmitted, is the expressed sole responsibility of the individual from whom the content originated. In short, this means that you are solely responsible for any and all content posted, uploaded, emailed, transmitted or otherwise made available by way of the Tasktak Services, and as such, we do not guarantee the accuracy, integrity or quality of such content. It is expressly understood that by use of our Services, you may be exposed to content including, but not limited to, any errors or omissions in any content posted, and/or any loss or damage of any kind incurred as a result of the use of any content posted, emailed, transmitted or otherwise made available by Tasktak. Furthermore, you herein agree not to make use of Tasktak Technologies Inc.'s Services for the purpose of:
            </p>
            <ul>
              <li>
              uploading, posting, emailing, transmitting, or otherwise making available any content that shall be deemed unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, or invasive of another's privacy or which is hateful, and/or racially, ethnically, or otherwise objectionable;
              </li>
              <li>
              causing harm to minors in any manner whatsoever;
              </li>
              <li>
              impersonating any individual or entity, including, but not limited to, any Tasktak officials, forum leaders, guides or hosts or falsely stating or otherwise misrepresenting any affiliation with an individual or entity;

              </li>
              <li>
              forging captions, headings or titles or otherwise offering any content that you personally have no right to pursuant to any law nor having any contractual or fiduciary relationship with;
              </li>
              <li>
              uploading, posting, emailing, transmitting or otherwise offering any such content that may infringe upon any patent, copyright, trademark, or any other proprietary or intellectual rights of any other party;
              </li>
              <li>uploading, posting, emailing, transmitting or otherwise offering any content that you do not personally have any right to offer pursuant to any law or in accordance with any contractual or fiduciary relationship;</li>
              <li>
              uploading, posting, emailing, transmitting, or otherwise offering any unsolicited or unauthorized advertising, promotional flyers, "junk mail," "spam," or any other form of solicitation, except in any such areas that may have been designated for such purpose;
              </li>
              <li>
              uploading, posting, emailing, transmitting, or otherwise offering any source that may contain a software virus or other computer code, any files and/or programs which have been designed to interfere, destroy and/or limit the operation of any computer software, hardware, or telecommunication equipment;
              </li>
              <li>
              disrupting the normal flow of communication, or otherwise acting in any manner that would negatively affect other users' ability to participate in any real time interactions;
              </li>
              <li>
              interfering with or disrupting any Tasktak Technologies Inc. Services, servers and/or networks that may be connected or related to our website, including, but not limited to, the use of any device software and/or routine to bypass the robot exclusion headers;
              </li>
              <li>
              intentionally or unintentionally violating any local, state, federal, national or international law, including, but not limited to, rules, guidelines, and/or regulations decreed by the U.S. Securities and Exchange Commission, in addition to any rules of any nation or other securities exchange, that would include without limitation, the New York Stock Exchange, the American Stock Exchange, or the NASDAQ, and any regulations having the force of law;
              </li>
              <li>
              providing informational support or resources, concealing and/or disguising the character, location, and or source to any organization delegated by the United States government as a "foreign terrorist organization" in accordance to Section 219 of the Immigration Nationality Act;
              </li>
              <li>
              "stalking" or with the intent to otherwise harass another individual; and/or
              </li>
              <li>
              collecting or storing of any personal data relating to any other member or user in connection with the prohibited conduct and/or activities which have been set forth in the aforementioned paragraphs.
              </li>
            </ul>
            <p className="pb-3">
            Tasktak Technologies Inc. herein reserves the right to pre-screen, refuse and/or delete any content currently available through our Services. In addition, we reserve the right to remove and/or delete any such content that would violate the Terms or which would otherwise be considered offensive to other visitors, users and/or members. Tasktak Technologies Inc. herein reserves the right to access, preserve and/or disclose member account information and/or content if it is requested to do so by law or in good faith belief that any such action is deemed reasonably necessary for:
            </p>

            <ul>
              <li>
              compliance with any legal process;
              </li>
              <li>
              enforcement of the Terms;
              </li>
              <li>
              responding to any claim that therein contained content is in violation of the rights of any third party;
              </li>
              <li>
              responding to requests for customer service; or
              </li>
              <li>
              protecting the rights, property or the personal safety of Tasktak Technologies Inc., its visitors, users and members, including the general public.
              </li>
            </ul>
            <p className="pb-3">
            Tasktak Technologies Inc. herein reserves the right to include the use of security components that may permit digital information or material to be protected, and that such use of information and/or material is subject to usage guidelines and regulations established by Tasktak Technologies Inc. or any other content providers supplying content services to Tasktak Technologies Inc.. You are hereby prohibited from making any attempt to override or circumvent any of the embedded usage rules in our Services. Furthermore, unauthorized reproduction, publication, distribution, or exhibition of any information or materials supplied by our Services, despite whether done so in whole or in part, is expressly prohibited.
            </p>

           
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Terms