import React from 'react'
import GlobalA from "./NpoGlobal";
import icon1 from '../../../assets/images/webmail/gym-1.png'
import icon2 from '../../../assets/images/webmail/gym-2.png'
import icon3 from '../../../assets/images/webmail/gym-3.png'
import icon4 from '../../../assets/images/webmail/check.png'

import icon_1 from '../../../assets/images/webmail/icon_1.svg'
import icon_2 from '../../../assets/images/webmail/icon_2.svg'
import icon_3 from '../../../assets/images/webmail/icon_3.svg'
import digram from '../../../assets/images/webmail/digram.png'


import img from '../../../assets/images/home/list-dot.svg'
import Section_A from "../../../assets/images/webmail/col-1.png";
import Section_B from "../../../assets/images/webmail/col-2.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import HomeReview from './HomeReview'


const Gym = () => {
  const data = [{
    title: 'Membership Management',
  },
  {
    title: 'Billing and Payment Management'
  },
  {
    title: 'Class and Appointment Scheduling'
  },
  {
    title: 'Marketing and Promotions'
  },
  {
    title: 'Reporting and Analytics'
  },
  {
    title: 'Inventory Management'
  },
  {
    title: 'Staff Management'
  }

  ]
  const datass = [{
    title: 'streamline their operations',
  },
  {
    title: 'reduce costs'
  },
  {
    title: 'stay competitive in the market'
  },

  ]
  const datas = [{
    icon: icon1,
    title: ' Manages the Workflow',
  },
  {
    icon: icon2,
    title: 'Improves the Customer Experience',
  },
  {
    icon: icon3,
    title: 'Time Saving',
  },

  ]
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    rewind: false,
    dots: true,
    lazyLoad: "true",
    lazyContent: "true",
    loop: false,
    autoplay: false,
    navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 3,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
  };
  const optionss = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    rewind: false,
    dots: true,
    lazyLoad: "true",
    lazyContent: "true",
    loop: false,
    autoplay: false,
    navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 3,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 4,
      },
      1200: {
        items: 4,
      },
    },
  };
  const datasss = [
    {
      id: 1,
      icon: icon_1,
      title: "Improve daily productivity Increase knowledge reuse",
      point1: "4% to 6%",
      point2: "Overall productivity increase for a thousand people owner-operator team during a large capital project ",
    },

    {
      id: 2,
      icon: icon_2,
      title: "Minimize costs overruns Reduce schedule deviations",
      point1: "10% to 15%",
      point2: "Cost reduction during the detailed engineering of a complex facility ",
    },

    {
      id: 3,
      icon: icon_3,
      title: "Increase output quality Avoid rework",
      point1: "10% to 15%",
      point2: "Time reduction for critical path work processes, including proofing, reviewing, validating & certifying construction procedures",
    },
  ];
  return (
    <>

      <div className='oil-section banner gym-section'>
        <div className='container'>
          <div className='oil-wrapper'>
            <div className='oil-main d-flex justify-content-center align-items-center flex-column'>
              <h1 class="inner-title fw-bold display-4 mb-3 aos-init aos-animate">How TaskTak enhances collaboration and
                communication in the Gym Industry</h1>
              <div className='btn-group'>
                <a
                  href="/pricing"
                  className="col-md-auto col-5 btn btn-yellow border-0 rounded-pill fs-5 lh-23 px-4 py-2 me-2 me-md-4 inner-btn-ngo"
                  bis_skin_checked="1"
                  style={{ color: '#fff' }}
                >
                  Get Started
                </a>
              </div>
              <p className='no-req'>No credit card required *</p>
            </div>
          </div>
        </div>
      </div>
      <section className='section-2'>
        <div className='container'>
          <div className='section-2-inner'>
            <h3 className='text-uppercase text-second fs-4 mb-3 position-relative h2 text-center'>TaskTak's BMP can help gyms streamline their
              operations with the following features:</h3>
          </div>
          <div className='section-2-main'>
            <h4>Key Functions</h4>

            <div className='section-2-border'>
              <ul>
                <div className='d-flex justify-content-between align-items-center pt-4'>
                  {data.map((item, i) => (
                    i < 3 ? (

                      <li><img src={img} alt="" /> {item.title}</li>
                    ) : ''
                  ))}
                </div>
                <div className='d-flex d-flex justify-content-between align-items-center pt-4 px-70'>
                  {data.map((item, i) => (
                    i > 2 ? (

                      <li><img src={img} alt="" /> {item.title}</li>
                    ) : ''
                  ))}
                </div>


              </ul>
            </div>
          </div>


        </div>
      </section>
      <section className='vustom-my-bg-grey'>
        <div className='container'>
          <div className='text-center'>
            <h2 className='text-second fs-4 mb-3 position-relative h2 text-center'>TaskTak can automate Gym’s routine <br />
              Tasks & Helps them with: -</h2>
          </div>
          <div className="mt-3 pt-0 mt-md-5 row custom-pt mx-width">

            <OwlCarousel className="owl-theme" {...options}>
              {datas.map((item, i) => (
                <div className="item">
                  <div className="save-time">
                    <div className="save-icon">
                      <img src={item.icon} alt="" />
                      <h5 className="text-center save-text pt-3">{item.title}</h5>
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>

          <section className='section-2 '>
            <div className='section-2-main bg-white'>
              <h4>TaskTak's BMP provides valuable insights to help gym owners
                make informed business decisions. Ultimately, TaskTak's BMP
                can help gyms to: -</h4>

              <div className='section-2-border'>
                <ul>
                  <div className='d-flex justify-content-between align-items-center pt-4 px-70'>
                    {datass.map((item, i) => (
                      i < 3 ? (

                        <li><img src={img} alt="" /> {item.title}</li>
                      ) : ''
                    ))}
                  </div>

                </ul>
              </div>
            </div>


          </section>

        </div>
      </section>

      <section className='section-2 '>
        <div className='container'>
          <div className='section-2-inner'>
            <h3 className='text-uppercase text-second fs-4 mb-3 position-relative h2 text-center'>TaskTak's BMP can help gyms streamline their
              operations with the following features:</h3>
          </div>
          <div>
            <img src={digram} alt="workflow" className='custom-workflow' />
          </div>


        </div>
      </section>




      <GlobalA
        title={'Here are some reasons why you should consider using TaskTak BMP for your gym:'}
        image={Section_B}
        color="mt-top"
        npoTitle={'Why use TaskTak BMP for your Gym?'}
        npoClasses="npo-inner-section gym-inner"
        npoDesc={'Running a gym can be a challenging task, from managing memberships and billing to scheduling classes and appointments. That\'s where TaskTak\'s BMP comes in to simplify and automate your gym\'s routine tasks.'}
        ngo1={'Efficient Membership Management'}
        ngo2={'Streamlined Workflow Management'}
        ngo3={'Enhanced Customer Experience'}
        ngo4={'Comprehensive Reporting and Analytics'}
        npoLast={'Summary: TaskTak BMP is a powerful tool for gyms looking to improve operational efficiency, enhance customer experience, and drive growth. With its easy-to-use interface, customizable features, and comprehensive reporting and analytics, it\'s a must-have for gym owners and managers.'}
      />

      <section className='section-2 inner_main'>
        <div className='container'>
          <div className='section-2-inner'>
            <h3 className='text-uppercase text-second fs-4 mb-3 position-relative h2 text-center'>Benefits of using TaskTak's BMP in a Gym</h3>
          </div>

        </div>
        <div className='container'>
          <div className='row'>

            <div class="col-md-6 col-lg-6 col-12"><div class="col-image">
              <img src={Section_A} alt="" />
            </div></div>
            <div className='col-md-6'>
              <div class="ngo-check gym style4 mb-40 list-center" bis_skin_checked="1">
                <ul>
                  <li>
                    <img src={icon4} alt="" />
                    Increased Efficiency</li>
                  <li>
                    <img src={icon4} alt="" />
                    Improved Member Experience</li>
                  <li>
                    <img src={icon4} alt="" />
                    Enhanced Marketing and Promotions</li>
                  <li>
                      <img src={icon4} alt="" />
                      Better Insights and Analytics Comprehensive Reporting and Analytics
                  </li>
                  <li>
                      <img src={icon4} alt="" />
                      Improved Financial Management
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </section>


    </>

  )
}

export default Gym