import React from "react";
import GlobalMain from "./Global_Section_Main";
import GlobalA from "./Global_Section_A";
import GlobalB from "./Global_Section_B";
import Banner from "../../../assets/images/home/about.png";
import Section_A from '../../../assets/images/home/about-a.svg';
import Section_B from '../../../assets/images/home/about-b.svg';
import Section_C from '../../../assets/images/home/about-c.svg';
import Section_D from '../../../assets/images/home/about-d.svg';

// import { useTranslation } from "react-i18next";

const AboutPage = () => {
    // const { t } = useTranslation(["innerpages"]);
    return (
      <>
        <GlobalMain
          title={'About Us'}
          description={'TaskTak consist of experienced professionals’ team who are dedicated to providing the clients with the best possible business solution under one roof. The team at TaskTak is passionate about helping businesses of all sizes succeed and thrive without any obstacles and work hard to ensure that the clients accomplish their goals.'}
          image={Banner}
        />
        <GlobalA
          title={'Our Aim'}
          description={'Here at TaskTak we help businesses manage their customer relationships with ease and efficiency. Our aim is to empower businesses of all sizes to build a lasting impact and meaningful relationships with their customers by providing them with the best tools and support in the industry.'}
          image={Section_A}
          color="gray mt-top"
        />
        <GlobalB
          title={'Streamlining Business Strategies'}
          description={'TaskTak\'s simplifies the way for the businesses so that they can manage their sales, marketing, and customer service operations. TaskTak’s BMP offers a wide range of features and tools that empower businesses to track leads, manage contacts, automate tasks, collaborates, manage projects, and improves the customer support. We believe that strong customer relationships are the key to business success. Our tools are designed to be intuitive, allowing businesses to provide exceptional customer experiences that foster trust and loyalty. With TaskTak BMP, businesses can streamline their operations, increase productivity, and provide exceptional customer service. Join us today and discover the power of our comprehensive business management platform.'}
          image={Section_B}
          color="gray"
        />
        <GlobalA
          title={'Our Experience'}
          description={'The Team at TaskTak is made up of experienced professionals who have spent years working in the industry. TaskTak have a deep understanding of the challenges that businesses face when it comes to managing customer relationships, we are committed to providing a solution which is easy to use and delivers results. We are also well aware of the obstacles that startups and small businesses have faced, therefore we have created BMP i.e., Business Management Platform which is both intuitive and easy to work with.'}
          image={Section_C}
          color="white"
        />
        <GlobalB
          title={'Our Mission'}
          description={'At TaskTak, our mission is to empower businesses maintain strong relationships with their customers. We believe that customer relationships are the foundation of any successful business, and our goal is to provide a comprehensive business solution that makes it easy for businesses to manage customer data, track interactions, and provide an exceptional customer experience. We are committed to helping businesses streamline their operations and improve their bottom line by providing tools that are easy to use, customizable, and scalable. We believe that our service can help businesses of all sizes achieve their goals, whether that\'s increasing sales, improving customer retention, or driving growth. We are dedicated to providing outstanding customer support and are always looking for ways to improve our service. Our mission is to be a partner in our customers success and to provide them with the tools they need to build a long-lasting relationship with their customers. If you have any questions or want to learn more about TaskTak, please don\'t hesitate to get in touch. We would be happy to answer any questions you may have, we`ll be more than willing help you determine if our service is the right fit for your business.'}
          image={Section_D}
          color="gray"
        />
      </>
  )
}

export default AboutPage