import React from "react";
import { Container, Row } from "react-bootstrap";

const Global_Section_B = ({ title, description, image, color ,text }) => {

  return (
    <div className={`inner_main mb-reverts ${color}`}>
      <Container>
        <div className="row align-items-center mb-revert ">
          <div className="col-md-6 col-lg-6 col-12">
            <div className="col-image col-text">
              <img src={image} alt="" />
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-12">
            <div className="col-text">
              <h3 className="h2 text-second fs-4 mb-3 position-relative">
                {title}
              </h3>
              <p>{description}</p>
              <div
                class="checklist style4 mb-40 list-center"
                bis_skin_checked="1"
              >
                <ul>
                  {text}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Global_Section_B;
