import React, { useState } from 'react'
import axios from "axios";
import $ from "jquery";
import Constant from "../../../utils/Constant";
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const LeadPopup = ({text ,Class}) => {
    const { t } = useTranslation(["home"]);
    const [open, setOpen] = React.useState(false);
    const [state, setState] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        company_name: "",
        web_url: "",
    })
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
    const handleClickOpen = () => {
        setOpen(true);
    };
  
    const handleClose = () => {
        setOpen(false);
    };
  
    const handleChange = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    }
  
    const callApi = () => {
        $("#submit_btn").html('Processing...')
        $('#submit_btn').attr('disabled', true);
        let classRef = this;
        var first_name = state.first_name
        var last_name = state.last_name
        var email = state.email
        var phone = state.phone
        var company_name = state.company_name
        var web_url = state.web_url
        axios.get('https://' + Constant.url + '.tasktak.com/Subscription/getDemo', {
            params: { first_name: first_name, last_name: last_name, email: email, phone: phone, company_name: company_name, web_url: web_url }
        }).then(response => {
            $("#submit_btn").html('Submit')
            setTimeout(function(){
              window.location.href = '/enterprise-thankyou';
            },2000)
        });
  
    }
  
    return (
      <div>
        <a variant="outlined" className={Class ? (Class) : "col-md-auto col-5 btn btn-yellow border-0 rounded-pill fs-5 lh-23 px-4 py-2 me-2 me-md-4 inner-btn-ngo"}
                              style={{color: '#fff'}} onClick={handleClickOpen}>
          {text}
        </a>
        <Dialog
                      fullScreen={fullScreen}
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="responsive-dialog-title"
                  >
                      <DialogTitle id="responsive-dialog-title">
                      Get A Demo
  
                      </DialogTitle>
                      <DialogContent>
                          <DialogContentText>
                              <form action="javascript:void(0)">
                                  <div className="row">
                                      <div className="col-lg-6 mb-3">
                                          <label className="tasktak_form-label">{t("first_name")} *</label>
                                          <input type="text" className="tasktak_form-control" required
                                              id="first_name"
                                              name="first_name"
                                              value={state.first_name}
                                              onChange={handleChange}
                                          />
                                      </div>
                                      <div className="col-lg-6 mb-3">
                                          <label className="tasktak_form-label">{t("last_name")} *</label>
                                          <input type="text" className="tasktak_form-control" required
                                              id="last_name"
                                              name="last_name"
                                              value={state.last_name}
                                              onChange={handleChange}
                                          />
                                      </div>
                                      <div className="col-lg-6 mb-3">
                                          <label className="tasktak_form-label">{t("email_field")} *</label>
                                          <input type="email" className="tasktak_form-control" required
                                              id="email"
                                              name="email"
                                              value={state.email}
                                              onChange={handleChange}
                                          />
                                      </div>
                                      <div className="col-lg-6 mb-3">
                                          <label className="tasktak_form-label">{t("phone_field")} *</label>
                                          <input type="number" className="tasktak_form-control" required
                                              id="phone"
                                              name="phone"
                                              value={state.phone}
                                              onChange={handleChange}
                                          />
                                      </div>
                                      <div className="col-lg-6 mb-3">
                                          <label className="tasktak_form-label">{t("company_email")} *</label>
                                          <input type="text" className="tasktak_form-control" required
                                              id="company_name"
                                              name='company_name'
                                              value={state.company_name}
                                              onChange={handleChange}
                                          />
                                      </div>
  
                                      <div className="col-lg-6 mb-3">
                                          <label className="tasktak_form-label">{t("web_url")}</label>
                                          <input type="text" className="tasktak_form-control"
                                              id="web_url"
                                              name='web_url'
                                              value={state.web_url}
                                              onChange={handleChange}
                                          />
                                      </div>
                                      <div className="col-lg-12 mb-5 custom-mb-5">
                                          <input className="form-check-input" type="checkbox" required id="sub_desc" name='sub_desc' />
                                          <label className="form-check-label" style={{ paddingLeft: "10px" }}>{t("sub_desc")}</label>
                                      </div>
  
                                      <div>
                                          <p className="tasktak_textbox-label">{t("privacy_text")}<a href="/privacy-policy">Privacy Policy.</a></p>
                                      </div>
  
                                      <div className="mb-3">
                                          <button type="submit"  className="btn btn-yellow mx-auto border-0 d-block rounded mt-3 px-4 py-2" style={{ width: "200px" , color : 'white' }} id="submit_btn" onClick={callApi}>Submit</button>
                                      </div>
  
                                  </div>
                              </form>
                          </DialogContentText>
                      </DialogContent>
                  </Dialog>
      </div>
    );
  }

export default LeadPopup