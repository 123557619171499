import React from "react";
import GlobalMain from "./Global_Section_Main";
import GlobalA from "./Global_Section_A";
import GlobalB from "./Global_Section_B";
import Banner from "../../../assets/images/webmail/web-banner.png";
import SectionA from "../../../assets/images/webmail/web-a.png";
import SectionB from "../../../assets/images/webmail/web-b.png";
import SectionC from "../../../assets/images/webmail/web-c.png";
import SectionD from "../../../assets/images/webmail/web-d.png";

import { useTranslation } from "react-i18next";
import Web1 from "./Sections/Web1";
import Web2 from "./Sections/Web2";

const Webmail = () => {
  const { t } = useTranslation(["home"]);

  return (
    <>

      <GlobalMain
        title={t('email')}
        description={<>
          <ul className="global-check">
            <li>
              •	{t('p1')}
            </li>
            <li>
              •	{t('p2')}
            </li>
            <li>
              •	{t('p3')}
            </li>
            <li>
              •	{t('p4')}
            </li>
            <li>
              •	{t('p5')}
            </li>
            <li>
              •	{t('p6')}
            </li>
            <li>
              •  {t('p7')}
            </li>
            <li>
              • {t('p8')}
            </li>
            <li>
              • {t('p9')}
            </li>
          </ul>

        </>}
        image={Banner}
      />
      <div
        id="service"
        className="overflow-hidden  position-relative service__section inner_main  mt-top npo-inner-sections "
      >
        <div className="container">
          <div className="col-12 text-center">
            <h2 className="text-second fs-4 mb-3 pt-5 position-relative h2 ">
              {t('power')}</h2>
            <p className="min-width-para">{t('cloud')}</p>

          </div>
          <div className="clearfix"></div>


          <div className="mt-3 pt-0 mt-md-1 row custom-owl-nav">
            {t("service_condition") == "1" ?
              <Web2/>
              :
              <Web1 />
            }
          </div>
        </div>

      </div>



      <GlobalA
        title={t('revolutionize')}
        description={<>{t('transform')}</>}
        image={SectionA}
        color=" mt-top"
      />
      <GlobalB
        title={t('efficient')}
        description={<>{t('streamlines')}</>}
        image={SectionB}
        color=" mt-top"
      />
      <GlobalA
        title={t('comprehensive')}
        description={<>{t('management')} <br /> <br /> {t('management_a')}</>}
        image={SectionC}
        color=" mt-top"
      />
      <GlobalB
        title={t('scalable')}
        description={<>{t('solution')}<br /><br />{t('solution_a')} <br /><br />{t('solution_b')} </>}
        image={SectionD}
        color=" mt-top"
      />
      <section className="cta">
        <div className="container">
          <div className="cta-inner-ngo">
            <div className="row">
              <div className="col-md-7">
                <h3>{t('experiences')}</h3>
              </div>
              <div className="col-md-5">
                <a
                  href="/pricing"
                  className="col-md-auto col-5 text-uppercase btn btn-yellow border-0 rounded-pill fs-5 lh-23 px-4 py-2 me-2 me-md-4 inner-btn-ngo"
                  style={{ color: 'rgb(255, 255, 255)', float: 'right' }}
                >
                  {t('get_demo_button')}
                </a>
                <p className="no-credit-req">{t('no_crdeit_card')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Webmail;