import React from "react";
import Global_Section_Main from "./Global_Section_Main";
import Global_Section_A from "./Global_Section_A";
import Global_Section_B from "./Global_Section_B";
import Banner from "../../../assets/images/saas-banner.png";
import Section_A from "../../../assets/images/saas-a.svg";
import Section_B from "../../../assets/images/saas-b.svg";
import Section_C from "../../../assets/images/saas-c.svg";
import { useTranslation } from "react-i18next";

const Saas = () => {
  const { t } = useTranslation(["innerpages"]);
  return (
    <>
      <Global_Section_Main
        title={'CRM SaaS Software'}
        description={'With TaskTak SaaS CRM, small and large companies can organize and manage their customer database and stay in touch with their customers. It helps them to keep their customers engaged and improve customer retention. As it is cloud-based, there is no need to download software; instead, you can log into all services from a single browser.'}
        image={Banner}
      />
      <Global_Section_A
        title={'Improves business productivity'}
        description={'SaaS CRM can improve workflow management and productivity by eliminating manual tasks. Automating mundane tasks such as data entry, tracking customer interactions, and analyzing customer trends, can free up employees to focus on higher-value tasks and be more productive with time and money.         '}
        image={Section_A}
        color="gray mt-top"
      />
      <Global_Section_B
        title={'Help strengthen client relationships!'}
        description={'Our relationship with the client extends beyond the sale. With our SaaS solution, you can track customer interactions and create tailored experiences for each customer. It also helps you stay in touch with each customer and keep them updated on their order status, which builds trust and customer loyalty. '}
        image={Section_B}
        color="gray"
      />
      <Global_Section_A
        title={'Help identify gaps in your sales process!'}
        description={'Our SaaS system helps you track gaps in your sales funnels, conversions, and overall activity without having to sift through truckloads of data that don\'t make sense. TaskTak\'s sales pipelines, performance analysis, and customized reports help you identify where you\'re succeeding and where you\'re failing.          '}
        image={Section_C}
        color="white"
      />
    </>
  );
};

export default Saas;
