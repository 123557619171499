import React from "react";
import Global_Section_Main from "./Global_Section_Main";
import Global_Section_A from "./Global_Section_A";
import Global_Section_B from "./Global_Section_B";
import Banner from "../../../assets/images/task-banner.svg";
import Section_A from "../../../assets/images/task-a.svg";
import Section_B from "../../../assets/images/task-b.svg";
import Section_C from "../../../assets/images/task-c.svg";
import { useTranslation } from "react-i18next";

const Task = () => {
    const { t } = useTranslation(["innerpages"]);
    return (
      <>
        <Global_Section_Main
          title={'CRM Task Management'}
          description={'The task management CRM helps you monitor the progress of your project\'s tasks throughout its various stages. It allows you to quickly assess the status of each task in the project and identify any potential bottlenecks or roadblocks that may prevent the project from meeting its deadline. It also provides an overview of the project\'s timeline, making it easier to stay on track and ensure that all tasks are completed on time.'}
          image={Banner}
        />
        <Global_Section_A
          title={'Opportunity Management'}
          description={'With TaskTak opportunity management, you\'ll have a complete picture of your team\'s deals. You can view stages, products, competitions, quotes, and more. This way, you can always be aware of where your deals are in the sales process and what resources are being used to close them.'}
          image={Section_A}
          color="gray mt-top"
        />
        <Global_Section_B
          title={'Lead Management'}
          description={'TaskTak offers detailed analytics that allows users to track their leads from the first click to the final close; so that they can make data-driven decisions about how to optimize their campaigns for maximum ROI. It also makes sure leads don\'t fall through the cracks, and the right sales reps take care of them as soon as they\'re available.'}
          image={Section_B}
          color="gray"
        />
        <Global_Section_A
          title={'Customer Management'}
          description={'TaskTak CRM helps you manage and streamline the processes that make up your customer relationships. Store, track, manage, and report on the tasks and activities that make up the customer relationship. Additionally, it ensures that all customer needs are satisfied timely and effectively.'}
          image={Section_C}
          color="white"
        />
      </>
  )
}

export default Task