import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import circle from '../../assets/images/home/pricing-check.svg';

export const Table = () => {
    const { t } = useTranslation(["table"]);
    const [show, setShow] = useState(false);
    
    return (
        <>
        <div className="container  mt-3 pt-5 pb-5 align-items-center d-flex justify-content-center">
            <button className={show ? 'cs-btn rotate' : 'cs-btn'} onClick={() => setShow(prev => !prev)}>See all Features <img src="assets/images/downarrow.svg" alt="" className='feature-down'/></button>
        </div>
        
        {show && <div className="container  mb-5 pb-5 d-lg-block anim ov-x">

     
                <table >
                    <thead>
                        <tr className="sticky">
                            <th colspan="2" className="bg-colors bt-radius">All Modules</th>
                            
                            <th className="bg-color bt-radius">Core</th>
                            <th className="spacer"></th>
                            <th className="bg-color bt-radius">Core+</th>
                            <th className="spacer"></th>
                            <th className="bg-color bt-radius">Corporate</th>
                            <th className="spacer"></th>
                            <th className="bg-color bt-radius">Enterprise</th>
                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="2" className="pt pb" >
                                <strong></strong>
                            </td>
                            <td className="bg-color">
                            
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                        </tr>

                        <br/>

                        <tr>
                            <td colspan="2" className="pt pb heading" >
                                <strong>Project management</strong>
                            </td>
                            <td className="bg-color">
            
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                        </tr>

                        <br/>

                        <tr className="hover">
                            <td colspan="2">
                                Project
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                        </tr>

                        <tr className="hover">
                            <td colspan="2">
                                Project Scheduling

                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>

                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>

                        <tr className="hover">
                            <td colspan="2">
                                Kanban
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                        </tr>
                        <br/>


                        <tr>
                            <td colspan="2" className="pt pb heading" >
                                <strong>Daily Operations</strong>
                            </td>
                            <td className="bg-color">
                     
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                        </tr>

                        <br/>

                        <tr className="hover">
                            <td colspan="2">
                                Reminders
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>

                        <tr className="hover">
                            <td colspan="2">
                                Tasks
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>

                        <tr className="hover">
                            <td colspan="2">
                                Idea Hub
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>

                        <tr className="hover">
                            <td colspan="2">
                                Spreadsheets Online
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>
                        <tr className="hover">
                            <td colspan="2">
                                Diagramy
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>
                        <tr className="hover">
                            <td colspan="2">
                                File Sharing
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>
                        <tr className="hover">
                            <td colspan="2">
                                Video Library
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>
                        <tr className="hover">
                            <td colspan="2">
                                Email Integration
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>
                        <tr className="hover">
                            <td colspan="2">
                                Knowledge Base
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>
                        <tr className="hover">
                            <td colspan="2">
                                Utilities MGMT
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>
                        <tr className="hover">
                            <td colspan="2">
                                General Reports
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>
                        <br/>

                        <tr>
                            <td colspan="2" className="pt pb heading" >
                                <strong>Communications</strong>
                            </td>
                            <td className="bg-color">
               
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                        </tr>
                        <br/>


                        <tr className="hover">
                            <td colspan="2">
                                Team Chat
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>

                     


                        <tr className="hover">
                            <td colspan="2">
                                Call Logs
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                        </tr>

                  

                        <tr className="hover">
                            <td colspan="2">
                                Appointments
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>

                        <tr className="hover">
                            <td colspan="2">
                                Zoom Meetings
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>

                        <tr className="hover">
                            <td colspan="2">
                                Custom Emails/SMS
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>

                        <br/>

                        <tr>
                            <td colspan="2" className="pt pb heading" >
                                <strong>Vendor Management</strong>
                            </td>
                            <td className="bg-color">
    
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                        </tr>

                        <br/>

                        <tr className="hover">
                            <td colspan="2">
                                Purchase management
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>


                        <tr className="hover">
                            <td colspan="2">
                                Inventory Management
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>

                        <tr className="hover">
                            <td colspan="2">
                                Asset tracking
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>

                        <br/>

                        <tr>
                            <td colspan="2" className="pt pb heading" >
                                <strong>Marketing</strong>
                            </td>
                            <td className="bg-color">
  
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                        </tr>

                        <br/>

                        <tr className="hover">
                            <td colspan="2">
                                Marketing Campaign
                            </td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                        </tr>
                        <tr className="hover">
                            <td colspan="2">
                                SEO
                            </td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                        </tr>

                        <tr className="hover">
                            <td colspan="2">
                                Automated Marketing Analytics and reports
                            </td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                        </tr>


                        <tr className="hover">
                            <td colspan="2">
                                Social Media Management
                            </td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                        </tr>


                        <tr className="hover">
                            <td colspan="2">
                                Campaign scheduling
                            </td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                        </tr>

                        <tr className="hover">
                            <td colspan="2">
                                Campaign automation
                            </td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                        </tr>

                        <tr className="hover">
                            <td colspan="2">
                                Lead mamagement
                            </td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                        </tr>

                        <tr className="hover">
                            <td colspan="2">
                                Bulk email
                            </td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                        </tr>

                        <br/>

                        <tr>
                            <td colspan="2" className="pt pb heading" >
                                <strong>CRM</strong>
                            </td>
                            <td className="bg-color">
                          
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                        </tr>

                        <br/>

                        <tr className="hover">
                            <td colspan="2">
                                Lead Management
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>

                        <tr className="hover">
                            <td colspan="2">
                                Subscription Management
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>

                        <tr className="hover">
                            <td colspan="2">
                                Clients Management
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>

                        <tr className="hover">
                            <td colspan="2">
                                Deal Management
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>

                        <tr className="hover">
                            <td colspan="2">
                                Zoom Meetings
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>

                        <tr className="hover">
                            <td colspan="2">
                                Opportunities
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>

                        <tr className="hover">
                            <td colspan="2">
                                Document Management
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>

                        <br/>


                        <tr>
                            <td colspan="2" className="pt pb heading" >
                                <strong>Accounting</strong>
                            </td>
                            <td className="bg-color">
                           
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                        </tr>

                        <br/>


                        <tr className="hover">
                            <td colspan="2">
                                Invoicing
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>

                        <tr className="hover">
                            <td colspan="2">
                                Sales recording
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>

                        <tr className="hover">
                            <td colspan="2">
                                Expense recording
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>

                        <br/>


                        <tr>
                            <td colspan="2" className="pt pb heading" >
                                <strong>Human Resources</strong>
                            </td>
                            <td className="bg-color">
                       
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                        </tr>

                        <br/>

                        <tr className="hover">
                            <td colspan="2">
                                HR Recruitment
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" /></td>
                                <td className="spacer"></td>
                                <td className="bg-color">
                                    <img src={circle} alt="" />
                                </td>
                                <td className="spacer"></td>
                        </tr>

                        <tr className="hover">
                            <td colspan="2">
                                HR Records
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>


                        <tr className="hover">
                            <td colspan="2">
                                HR Management
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>

                        <tr className="hover">
                            <td colspan="2">
                                Staff Booking
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>

                        <tr className="hover">
                            <td colspan="2">
                                Staff Workload
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>

                        <tr className="hover">
                            <td colspan="2">
                                Timesheets & Leave
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>

                        <tr className="hover">
                            <td colspan="2">
                                HR Payroll
                            </td>
                            <td className="bg-color">
                            <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color">
                                <img src={circle} alt="" />
                            </td>
                            <td className="spacer"></td>
                        </tr>

                        <br/>

                        <tr>
                            <td colspan="2" className="pt pb heading" >
                                <strong>Support</strong>
                            </td>
                            <td className="bg-color">
                            
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                        </tr>

                        <br/>

                        <tr className="hover">
                            <td colspan="2">
                                Support Ticketing
                            </td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                        </tr>

                        <tr className="hover">
                            <td colspan="2">
                                Customer Support
                            </td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                        </tr>

                        <tr className="hover">
                            <td colspan="2">
                                Vendor support
                            </td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                        </tr>


                        <tr className="hover">
                            <td colspan="2">
                                Staff support
                            </td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                        </tr>
                        <br/>

                        <tr>
                            <td colspan="2" className="pt pb heading" >
                                <strong> TaskTak Mail</strong>
                            </td>
                            <td className="bg-color">

                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                        </tr>
                        <br/>

                        <tr className="hover">
                            <td colspan="2">
                                MailTak
                            </td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                        </tr>
                        <br/>
                        <tr>
                            <td colspan="2" className="pt pb heading" >
                                <strong>Customer Help Center</strong>
                            </td>
                            
                            <td className="bg-color">
                            
                            </td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                            <td className="spacer"></td>
                            <td className="bg-color"></td>
                        </tr>
                        <br/>

                        <tr className="hover">
                            <td colspan="2">
                                Help Chat
                            </td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                        </tr>

                        <tr className="hover">
                            <td colspan="2">
                                Live Phone call
                            </td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                            <td className="bg-color"><img src={circle} alt="" /></td>
                            <td className="spacer"></td>
                        </tr>

                        <tr>
                            <td colspan="2"></td>
                            <td className="bg-color"></td>
                            <td className="spacer"></td>
                            <td className="bg-color bb-radius"></td>
                            <td className="spacer"></td>
                            <td className="bg-color bb-radius"></td>
                            <td className="spacer"></td>
                            <td className="bg-color bb-radius"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        }
        </>
    )
}
export default Table;