import React from 'react'
import thumb from "../../assets/images/home/Thumbs_Up.png"
import notfound from "../../assets/images/404.svg"

const NotFound = () => {
    return (
        <>

            <section className='thank-you'>

                <div className='container'>
                    <div className='d-flex justify-content-center flex-column align-items-center vh-100'>
                    <div className='thumbss'>
            <img src={notfound} alt="" />
            </div>
                        <h1 className='thankyou-title'>
                            <img src="" alt="" />
                        </h1>
                        <a href="/" className='col-md-auto col-5 btn btn-yellow border-0 rounded-pill fs-5 lh-23 px-4 py-2 me-2 me-md-4 my-margin-none' style={{ color: '#fff' }}>Explore More</a>
                    </div>

                </div>
            </section>
        </>
    )
}

export default NotFound