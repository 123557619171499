import React from "react";
import Global_Section_Main from "./Global_Section_Main";
import Global_Section_A from "./Global_Section_A";
import Global_Section_B from "./Global_Section_B";
import Banner from "../../../assets/images/bmp-mark.svg";
import Section_A from "../../../assets/images/bmp-mark-a.svg";
import Section_B from "../../../assets/images/bmp-mark-b.svg";
import Section_C from "../../../assets/images/bmp-mark-c.svg";
import { useTranslation } from "react-i18next";

const BmpMarketing = () => {
  const { t } = useTranslation(["innerpages"]);
  return (
    <>

      <Global_Section_Main
        title={'CRM Marketing Software'}
        description={'Marketing in the past had always been hit-or-miss: it was not customized to any particular demographic or group, which made it both ineffective and costly. The TaskTak CRM is a cutting-edge invention in modern marketing for businesses. It enables firms to customize marketing tactics to specific audiences and make those tactics more effective by targeting the right individuals at the right time. It makes online marketing incredibly cost-effective and successful.'}
        image={Banner}
      />
      <Global_Section_A
        title={'Bespoke marketing campaigns'}
        description={'TaskTak CRM collects relevant customer data from multiple sources and creates a single source of truth. This makes it easier to understand customer behavior and preferences, track customer journeys, and create a personalized customer experience. In addition, it helps marketing teams optimize their campaigns for better performance by determining their effectiveness.        '}
        image={Section_A}
        color="gray mt-top"
      />
      <Global_Section_B
        title={'No more guesswork'}
        description={'In the past, marketing initiatives required manually analyzing thousands of client interactions and developing trends to influence future marketing decisions. Besides being time-consuming, this process also required a great deal of guesswork in determining which strategies would be worth your investment. By providing specific and targeted data, TaskTak CRM allows you to make fact-based decisions rather than assumptions.'}
        image={Section_B}
        color="gray"
      />
      <Global_Section_A
        title={'Detailed marketing report'}
        description={'With the TaskTak report feature, determine the status of all your leads throughout a specified period, including How many qualified leads are there? How many are now in the fostering stage? How many are almost ready for market? A quick glance at this report will give you an overview of how well each marketing source is performing.'}
        image={Section_C}
        color="white"
      />
    </>
  )
}

export default BmpMarketing