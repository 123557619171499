import React from "react";
import { Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const Global_Section_A = ({
  title,
  description,
  image,
  color,
  text,
  npoClasses,
}) => {
  const { pathname } = useLocation();
  return (
    <div className={`inner_main ${color} ${npoClasses}`}>
      <Container>
        <div className="row align-items-center">
          <div className="col-md-6 col-lg-6 col-12">
            <div className="col-text">
              <h3 className="text-second fs-4 mb-3 position-relative h2 ">
                {title}
              </h3>
              <p >{description}</p>
              <div
                class="checklist style4 mb-40 list-center"
                bis_skin_checked="1"
              >
                <ul>{text}</ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-12">
            <div className="col-image">
              <img src={image} alt="" />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Global_Section_A;
