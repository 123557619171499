import React from "react";
import Global_Section_Main from "./Global_Section_Main";
import Global_Section_A from "./Global_Section_A";
import Global_Section_B from "./Global_Section_B";
import Banner from "../../../assets/images/acc-banner.svg";
import Section_A from "../../../assets/images/acc-a.svg";
import Section_B from "../../../assets/images/acc-b.svg";
import Section_C from "../../../assets/images/acc-c.svg";
import { useTranslation } from "react-i18next";

const Erp = () => {
    const { t } = useTranslation(["innerpages"]);
    return (
      <>

        <Global_Section_Main
          title={'ERP CRM Software'}
          description={'It is an enterprise resource planning system that offers a customer-driven, end-to-end strategy to small and medium-sized organizations. Essentially, it handles all of your company\'s core functions, such as finance, service delivery, warehousing, and human resources, freeing you up to concentrate on customers. Keeping the consumer at the forefront allows businesses to evolve quickly, give maximum value, and enhance efficiency.'}
          image={Banner}
        />
        <Global_Section_A
          title={'Improves productivity'}
          description={'With TaskTak ERP software, companies can be more productive by automating time-consuming processes, avoiding data-calculation errors, and maintaining real-time access to relevant information.'}
          image={Section_A}
          color="gray mt-top"
        />
        <Global_Section_B
          title={'Mobility'}
          description={'TaskTak\'s mobile-friendly ERP system provides users with the ability to access their cloud systems from anywhere anytime. It also allows for increased efficiency and productivity by enabling employees to quickly and easily access the data and tools they need to do their jobs.'}
          image={Section_B}
          color="gray"
        />
        <Global_Section_A
          title={'Improved customer service'}
          description={'Sales managers and customer service representatives can communicate more effectively with customers thanks to TaskTak BMP\'s centralized data repository, which contains all the necessary information regarding sales and clients. It can lead to a higher level of customer satisfaction and an increase in sales.'}
          image={Section_C}
          color="white"
        />
      </>
    )
}

export default Erp