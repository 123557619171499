import React, { useState } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Container, Row } from 'react-bootstrap';
import star from "../../assets/images/webmail/stars.svg"


const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <p className="text">
            {isReadMore ? text.slice(0, 60) : text}
            <span onClick={toggleReadMore} className="read-or-hide">
                {isReadMore ? "...read more" : " show less"}
            </span>
        </p>
    );
};


const Reviews = () => {

    const options = {
        margin: 30,
        responsiveClass: true,
        nav: false,
        dots: true,
        loop: true,
        autoplay: true,
        navText: ["Prev", "Next"],
        smartSpeed: 500,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 2,
            },
            700: {
                items: 2,
            },
            1000: {
                items: 3,

            }
        },
    };
    const data = [
        {
            id: 1,
            image: "assets/images/r-0.jpg",
            title: "Patrick Saffomilla",
            link: 'https://www.alignable.com/groton-ct/teamsaff-llc',
            review: <ReadMore>If you use NetSuite or other ERP software...switch to TaskTak today!</ReadMore>,
            divClass: "snip",
            dicription: "TeamSaff LLC",
            facebook_link: "#",
            twitter_link: "#",
            instagram_link: "#"
        },
        {
            id: 2,
            image: "assets/images/r-4.jpg",
            title: "Joanna Denos",
            link: 'https:www.alignable.com/ogden-ut/joanna-denos',
            review: <ReadMore>I had the luxury of meeting the Task Tak team and especially Brent, Samantha, Ishraque, and Nebula. If you have not seen what TaskTak can do you are missing out. The number of tools involved in this software will blow your mind. After the demonstration of this product, I realize that you will only need one software for your whole business. Please take the time to reach out to Brent Cearley at TaskTak it will change your perspective of everything you have been doing and how easy it will be to have one solution. Thank you so much TaskTak team!!</ReadMore>,
            divClass: "snip",
            dicription: "Joanna Denos",
            facebook_link: "#",
            twitter_link: "#",
            instagram_link: "#"
        },
        {
            id: 3,
            image: "assets/images/r-3.jpg",
            title: "Shameka Young",
            review: <ReadMore>Brent has the perfect solution to your business needs, with innovative technology that will but you ahead of your competitors!</ReadMore>,
            link: "https://www.alignable.com/accokeek-md/2m-consulting-group-llc",
            divClass: "snip",
            dicription: "2M Consulting Group LLC",
            facebook_link: "#",
            twitter_link: "#",
            instagram_link: "#"
        },
         {
             id: 4,
             image: "assets/images/r-5.png",
             title: "Houman Adabi",
             link: 'https://www.alignable.com/derwood-md/visore-security-solutions',
             review: <ReadMore>TaskTak is comprehensive and Brent is a great guy to talk to. He understands struggles of small companies.</ReadMore>,
             divClass: "snip",
             dicription: "Visore Security Solutions",
             facebook_link: "#",
             twitter_link: "#",
             instagram_link: "#"
         },
         {
             id: 5,
             image: "assets/images/r-4.jpg",
             title: "Kristi Hagen",
             review: <ReadMore>I had the pleasure of speaking with Brent and Samantha and they are very personable. Their product that they offer is a great all-in-one solution for businesses. I have already recommended them to someone that is looking for just that!</ReadMore>,
             link: "https://www.alignable.com/redding-ca/set-free-2-health",
             divClass: "snip",
             dicription: "Set Free 2 Health",
             facebook_link: "#",
             twitter_link: "#",
             instagram_link: "#"
         },
         {
            id: 5,
            image: "assets/images/r-6.png",
            title: "Nishat Zia",
            review: <ReadMore>TaskTak's All-In-One Solution is the first ever of its kind I have seen in Bangladesh. We at Shield can now do a lot of more work across different departments in less time, while all the data and confidential information is being stored in cloud! Happy with the awesome value TaskTak has empowered us with.</ReadMore>,
            link: "https://shield20.com.bd/",
            divClass: "snip",
            dicription: "Founder, Shield 20 ",
            facebook_link: "#",
            twitter_link: "#",
            instagram_link: "#"
        },


    ];
    return (

        <section className='my-5 py-5 pt-0'>
            <Container>
                <Row>
                    <div className='col-10 col-md-7 mt-5 mx-auto text-center'>
                        <h2 className='fw-medium'>What Clients Say.</h2>
                        <p className='text-gray'>What Our Customers are saying about us!</p>
                    </div>
                    <div className='clearfix'></div>
                    <div className='col-12 col-md-10 mt-5 mx-auto mt-5'>
                        <OwlCarousel className='owl-theme team-owl' {...options}>
                            {data.map(item => (
                                <div className='snap-item'>
                                    <figure className={item.divClass} id={item.id}>
                                        
                                        <img src={item.image} alt="sq-sample3" className="my-r-v-i" /> 

                                        <blockquote>{item.review}

                                        </blockquote>



                                        <div className="author">
                                            <img src={star} alt="" />
                                            <h5>{item.title} <br/><span> <a href={item.link}>{item.dicription}</a></span></h5>
                                        </div>
                                    </figure>
                                </div>
                            ))}
                        </OwlCarousel>

                    </div>
                </Row>
            </Container>
        </section >

    )
}
export default Reviews  