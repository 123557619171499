import React from 'react';
import Service from './Service';
import Features from './Features';
import Home from './Home';
import About from '././About';
import HomeReview from './Inner_components/HomeReview';
import { useTranslation } from "react-i18next";



const Landing = () => {
  const { t } = useTranslation(["home"]);

  return (
    <>
                <Home />
                <About />
                <Service />
                <Features />
                <HomeReview />
                </>
  )
}

export default Landing