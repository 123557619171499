import React from 'react'

const NgoGlob = ({
    title,
    description,
    image,
    text,
}) => {
  return (
    <>
    <div className="row align-items-center">
          <div className="col-md-8 col-lg-8 col-12">
            <div className="col-text">
              <h3 className="text-second fs-4 mb-3 position-relative h2 ">
                {title}
              </h3>
              <p >{description}</p>
              <div
                class="ngo-check style4 mb-40 list-center"
                bis_skin_checked="1"
              >
                <ul>{text}</ul>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-4 col-12">
            <div className="col-image">
              <img src={image} alt="" />
            </div>
          </div>
        </div>
    
    </>
  )
}

export default NgoGlob