import React from "react";
import GlobalMain from "./Global_Section_Main";
import GlobalA from "./Global_Section_A";
import GlobalB from "./Global_Section_B";
import Banner from "../../../assets/images/email-banner.svg";
import Section_A from "../../../assets/images/email-a.svg";
import Section_B from "../../../assets/images/email-b.svg";
import Section_C from "../../../assets/images/email-c.svg";
// import { useTranslation } from "react-i18next";


const Marketing = () => {
    // const { t } = useTranslation(["innerpages"]);
    return (
      <>
        <GlobalMain
          title={'Email Marketing CRM'}
          description={'Email marketing is widely used by large and small businesses to seek new customers and keep existing ones. However, integrating TaskTak email marketing CRM allows businesses to make the most of this strategy. It can connect every email correspondence with the contact record, conduct customized email campaigns in bulk, and monitor the performance of each campaign. It also allows being alerted when a lead opens an email, among other things. '}
          image={Banner}
        />
        <GlobalA
          title={'Deliver more emails in less time!'}
          description={'Whether you have to engage with an individual prospect or a large group of applicants, TaskTak\'s automated email templates and personalized email sequences make it easy to stay on top of customers inboxes.'}
          image={Section_A}
          color="gray mt-top"
        />
        <GlobalB
          title={'Keep an eye on email\'s impact!'}
          description={'With TaskTak email marketing CRM, large and small businesses can send beautifully designed email campaigns to thousands of customers at once, create bespoke audiences using CRM data, and quickly track the effectiveness of their campaigns.'}
          image={Section_B}
          color="gray"
        />
        <GlobalA
          title={'Make your business more profitable!'}
          description={'As nobody has the same needs and wants as another, personalized marketing is on the rise these days. TaskTak\'s personalized marketing helps to build greater brand loyalty and trust, which may lead to improved customer retention rates and more repeat sales.'}
          image={Section_C}
          color="white"
        />
      </>
  )
}


export default Marketing