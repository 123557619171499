import React from "react";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as ReactDOMClient from 'react-dom/client';

import "./i18n";


const root = ReactDOMClient.createRoot(document.getElementById('root'));
root.render(
 <React.StrictMode>
   <BrowserRouter>
       <App />
   </BrowserRouter>
 </React.StrictMode>
);
reportWebVitals();
