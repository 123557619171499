import React from "react";
import $ from "jquery";

$("body").on("click", "#move-footer", function () { 
$('#section-footer').toggleClass("d-bg-block main"); 
if ($('#section-footer').css('display') == 'block') 
{ $('html, body').animate({ 
    scrollTop: $("#myDiv").offset().top 
}, 500); } 
});
$(document).on("ready" , function () {
    $("body").on("click", "#section1s2s", function () { 
        $('html, body').animate({
            scrollTop: $("#section1s2").offset().bottom
        }, 500); 
        return false; 
    });
});