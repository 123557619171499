import React from 'react'
import thumb from "../../assets/images/home/Thumbs_Up.png"

const Thankyou = () => {
    return (
        <>

            <section className='thank-you'>

                <div className='container'>
                    <div className='d-flex justify-content-center flex-column align-items-center vh-100'>
                    <div className='thumbs'>
            <img src={thumb} alt="" />
            </div>
                        <h1 className='thankyou-title'>
                            We have received Your Inquiry for Enterprise Plan!
                        </h1>
                        <p className='cust-width text-light w-50' style={{ textAlign: 'center' }}>Thank you for sending inquiry for our Enterprise subscription plan! We are thrilled to have you on board and excited to see the impact that TaskTak's business process management workflow automation will have on your business.
                            <br />
                            We understand that time is money, and we want to help you save both. That's why our platform is designed to streamline your business operations, improve efficiency, and increase productivity.
                            <br />
                            Thank you again for choosing TaskTak Technologies Inc.. We look forward to working with you and helping your business thrive!</p>
                        <a href="/" className='col-md-auto col-5 btn btn-yellow border-0 rounded-pill fs-5 lh-23 px-4 py-2 me-2 me-md-4 my-margin-none' style={{ color: '#fff' }}>Explore More</a>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Thankyou