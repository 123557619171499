import React from 'react'
import Global_Section_A from './Global_Section_A'
import Global_Section_B from './Global_Section_B'
import SectionA from '../../../assets/images/webmail/appointly-2.png'
import SectionB from '../../../assets/images/webmail/appointly-3.png'
import SectionC from '../../../assets/images/webmail/appointly-4.png'


const Appointly = () => {
  return (
    <>
    <Global_Section_A
        title={'Customer book an appointment'}
        description={'Booking an appointment is a breeze with our user-friendly system. Customers can simply fill out a straightforward form to secure their desired appointment. By streamlining the booking process, we make it quick and hassle-free for customers to schedule their appointments.'}
        image={SectionA}
        color="white"
      />
    <Global_Section_B
        title={'Customer gets a confirmation email'}
        description={'After successfully booking an appointment through the salon booking software, customers can expect to receive a prompt confirmation email. This confirmation email serves as an important communication tool to reassure customers that their appointment request has been received and confirmed.'}
        image={SectionB}
        color="gray"
      />
    <Global_Section_A
        title={'Customer visits you on the agreed time'}
        description={'Ensuring a seamless and positive customer experience is paramount in any business, and the agreed-upon time of customer visits plays a vital role in achieving this goal. When a customer arrives at the scheduled time, it reflects their trust and confidence in your business.'}
        image={SectionC}
        color="white"
      />
    
    
    </>
  )
}

export default Appointly