import React from "react";
import { Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const Global_Section_Main = ({ title, description, image,npo }) => {
  const { pathname } = useLocation();
  return (
    <>
      <div
        id="home"
        className="banner inner-banners position-relative text-center text-md-start"
      >
        <Container className="h-100">
          <Row className="h-100 align-items-center">
            
            <div className={pathname === "/npo" ? ('col-12 col-md-8') : ('col-12 col-md-6')}>
              <div className="hr-bmp-top ">
              {pathname === "/email-management-software" || pathname === "/npo" ?  (
                ""
              ): <span
              className="hero-subtitle"
              data-aos="fade-right"
              data-aos-delay="5"
              data-aos-offset="5"
            >
              Solution For Your Business
            </span>}
            {pathname === "/email-management-software" ? (
                <h1
                className="nowwrap inner-title fw-bold display-4 mb-3 "
                data-aos="fade-right"
                data-aos-delay="10"
                data-aos-offset="10"
              >
                {title}
              </h1>
              ):
                <h1
                  className=" inner-title fw-bold display-4 mb-3 "
                  data-aos="fade-right"
                  data-aos-delay="10"
                  data-aos-offset="10"
                >
                  {title}
                </h1>
                }
                <p
                  className="text-gray main-para mb-4 pb-2"
                  data-aos="fade-right"
                  data-aos-delay="20"
                  data-aos-offset="20"
                >
                  {description}
                </p>
                {pathname === "/npo" ? (
                <p
                  className="text-gray main-para mb-4 pb-2 inner-npo-text"
                  data-aos="fade-right"
                  data-aos-delay="20"
                  data-aos-offset="20"
                >
                  {npo}
                </p>
                ): ""
                }
                {pathname === "/faqs" || pathname === "/testimonials" ? null : (
                  <div
                    className="btn-group"
                    data-aos="fade-right"
                    data-aos-delay="25"
                    data-aos-offset="25"
                  >
                    <a
                      href="/pricing"
                      className="col-md-auto col-5 btn btn-yellow border-0 rounded-pill fs-5 lh-23 px-4 py-2 me-2 me-md-4 inner-btn-ngo"
                      bis_skin_checked="1"
                      style={{color: '#fff'}}
                    >
                      Get Started
                    </a>
                  </div>
                )}
              </div>
            </div>

            <div className={pathname === "/npo" ? ('col-12 col-md-4') : ('col-12 col-md-6')}>
              <div className="hr-bmp d-flex align-items-center justify-content-center">
                <img
                  src={image}
                  alt="img"
                  className="img-fluid pt-0 mt-4  mt-md-5 banner-images-inr"
              
                />
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Global_Section_Main;
