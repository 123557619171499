import React, { useState } from 'react'
import { Container, Row } from "react-bootstrap";
import axios from "axios";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import Constant from "../../utils/Constant";
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';
import circle from '../../assets/images/home/pricing-check.svg';
import arrow from '../../assets/images/home/arrow-icon.svg'
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = () => {
  const { t } = useTranslation(["home"]);
  const [open, setOpen] = React.useState(false);
  const [state, setState] = useState({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      company_name: "",
      web_url: "",
  })
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
      setOpen(true);
  };

  const handleClose = () => {
      setOpen(false);
  };

  const handleChange = (e) => {
      const { id, value } = e.target
      setState(prevState => ({
          ...prevState,
          [id]: value
      }))
  }

  const callApi = () => {
      $("#submit_btn").html('Processing...')
      $('#submit_btn').attr('disabled', true);
      let classRef = this;
      var first_name = state.first_name
      var last_name = state.last_name
      var email = state.email
      var phone = state.phone
      var company_name = state.company_name
      var web_url = state.web_url
      axios.get('https://' + Constant.url + '.tasktak.com/Subscription/addLead', {
          params: { first_name: first_name, last_name: last_name, email: email, phone: phone, company_name: company_name, web_url: web_url }
      }).then(response => {
          $("#submit_btn").html('Submit')
          setTimeout(function(){
            window.location.href = '/enterprise-thankyou';
          },2000)
      });

  }

  return (
    <div>
      <a variant="outlined"  className="text-decoration-none  text-white" onClick={handleClickOpen}>
        Contact Sales
      </a>
      <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                    Contact Sales

                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <form action="javascript:void(0)">
                                <div className="row">
                                    <div className="col-lg-6 mb-3">
                                        <label className="tasktak_form-label">{t("first_name")} *</label>
                                        <input type="text" className="tasktak_form-control" required
                                            id="first_name"
                                            name="first_name"
                                            value={state.first_name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <label className="tasktak_form-label">{t("last_name")} *</label>
                                        <input type="text" className="tasktak_form-control" required
                                            id="last_name"
                                            name="last_name"
                                            value={state.last_name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <label className="tasktak_form-label">{t("email_field")} *</label>
                                        <input type="email" className="tasktak_form-control" required
                                            id="email"
                                            name="email"
                                            value={state.email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <label className="tasktak_form-label">{t("phone_field")} *</label>
                                        <input type="number" className="tasktak_form-control" required
                                            id="phone"
                                            name="phone"
                                            value={state.phone}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <label className="tasktak_form-label">{t("company_email")} *</label>
                                        <input type="text" className="tasktak_form-control" required
                                            id="company_name"
                                            name='company_name'
                                            value={state.company_name}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="col-lg-6 mb-3">
                                        <label className="tasktak_form-label">{t("web_url")}</label>
                                        <input type="text" className="tasktak_form-control"
                                            id="web_url"
                                            name='web_url'
                                            value={state.web_url}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-lg-12 mb-5 custom-mb-5">
                                        <input className="form-check-input" type="checkbox" required id="sub_desc" name='sub_desc' />
                                        <label className="form-check-label" style={{ paddingLeft: "10px" }}>{t("sub_desc")}</label>
                                    </div>

                                    <div>
                                        <p className="tasktak_textbox-label">{t("privacy_text")}<a href="/privacy-policy">Privacy Policy.</a></p>
                                    </div>

                                    <div className="mb-3">
                                        <button type="submit"  className="btn btn-yellow mx-auto border-0 d-block rounded mt-3 px-4 py-2" style={{ width: "200px" , color : 'white' }} id="submit_btn" onClick={callApi}>Contact Sales</button>
                                    </div>

                                </div>
                            </form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
    </div>
  );
}


class Pricing extends React.Component {
  constructor() {
    super();
    this.handlePricingList = this.handlePricingList.bind(this);
    this.callApi = this.callApi.bind(this);
    this.state = {
      isYearly: false,
      pricingPlan: {
        isLoaded: false,
        msg: "Loading...",
        list: [],

      },

    };

  }

  componentDidMount() {
    // get_product_ar
    const { t } = this.props;


    t("service_condition") == "2"
      ? this.callApi(
        "https://" + Constant.url + ".tasktak.com/Subscription/get_product"
      )
      : this.callApi(
        "https://" + Constant.url + ".tasktak.com/Subscription/get_product"
      );
  }
  componentWillReceiveProps() {
    const { t } = this.props;

    t("service_condition") == "2"
      ? this.callApi(
        "https://" + Constant.url + ".tasktak.com/Subscription/get_product"
      )
      : this.callApi(
        "https://" + Constant.url + ".tasktak.com/Subscription/get_product"
      );
  }

  randomNum = () => {
    return Math.floor(Math.random() * (4 - 1 + 1)) + 1;
  };

  radioButton = (item_id) => {
    if (item_id == "month") {
      this.setState({ isYearly: false }, function () {
        $(".lbl_mo").addClass("pricechecbox");
        $(".lbl_yr").removeClass("pricechecbox");
      });
    } else {
      this.setState({ isYearly: true }, function () {
        $(".lbl_mo").removeClass("pricechecbox");
        $(".lbl_yr").addClass("pricechecbox");
      });
    }

    // if ($('input[name="group1"]:checked').val() == "montly" + itemID) {
    //     console.log("#price_sec_" + itemID)
    //     $("#price_sec_" + itemID).html(symbol + ' ' + price)
    // } else {
    //     if (itemID == 5) {
    //         $("#price_sec_" + itemID).html(symbol + ' 540')
    //     } else if (itemID == 11) {
    //         $("#price_sec_" + itemID).html(symbol + ' 864')
    //     } else if (itemID == 12) {
    //         $("#price_sec_" + itemID).html(symbol + ' 1296')
    //     }
    // }

    // console.log($('input[name="group1"]:checked').val(), itemID)
  };

  handlePricingList = () => {
    const { t } = this.props;
    let pricings = "";
    pricings = this.state.pricingPlan.list.map((item) =>
      item.id == 5 || item.id == 11 || item.id == 12 || item.id == 4 ? (
        <div id={"price_" + item.id} className="col-md-6 col-lg-3" key={item.id} >
          <div className="price-box rounded-3 position-relative overflow-hidden mb-4">
            <div className="best-seller">
              {item.id == 4 ? (
                <h3 className="text-second fs-4 mb-3 text-left position-relative">
                  Enterprise
                </h3>
              ) : (
                <h3 className="text-second fs-4 mb-3 text-left position-relative">
                  {item.name}
                </h3>
              )}

              {item.id == 11 ? (
                <h5 className="most-popular">Best Seller</h5>
              ) : (
                ""
              )}
            </div>
            <span
              className="align-items-center d-flex justify-content-start rounded-pill mb-2 mx-auto fw-bold "
              id={"price_sec_" + item.id}
            >
              {item.price_symbol}{item.price} <span className="month">/month</span>
            </span>
            {item.id == 4 ? (
              <p className="tag-line">Use technology to improve your workflows and increase productivity.</p>
            ) : (
              ""
            )}
            {item.id == 5 ? (
              <p className="tag-line">Train employees on best practices to ensure proper execution.</p>
            ) : (
              ""
            )}
            {item.id == 11 ? (
              <p className="tag-line">Simplify your business processes for smoother operations.</p>
            ) : (
              ""
            )}
            {item.id == 12 ? (
              <p className="tag-line">Streamline your business processes for greater efficiency.</p>
            ) : (
              ""
            )}



{
              item.id == 4 ? 
                <Alert />
               :
                <a
                  href={"https://" +
                    Constant.url +
                    ".tasktak.com/authentication/register?a=" +
                    item.id
                  }
                  className="text-decoration-none  text-white"
                >
                  Get it Now
                </a>
            }

            {item.id == 4 ?
              <p className="custom-starting">Starting From*</p>

              :

              <a
                href={"https://" +
                  Constant.url +
                  ".tasktak.com/authentication/register"
                }
                className="text-decoration-underline  free-packages"
              >
                Try it now for free<img src={arrow} alt="" className="ml-3" />
              </a>
            }


            <ul className="list-unstyled text-gray small mb-3 custom-ul">
              <li className="mb-2">
                <img src={circle} alt="" className="me-2" />
                {item.id == 4 ? "11 Users" :
                  item.max_users + " " + t("pricing:user_data")
                }
              </li>
              <li className="mb-2">
                <img src="" alt="" />
                <img src={circle} alt="" className="me-2" />
                {item.id == 4 ? "325 Customers" :
                  item.max_customers + " " + t("pricing:customer_data")
                }
              </li>
              <li className="mb-2">
                <img src={circle} alt="" className="me-2" />
                {item.id == 4 ? "9000 Leads" :
                  item.max_leads + " " + t("pricing:leads_data")
                }
              </li>
            </ul>

          </div>
        </div>

      ) : (
        ""
      )
    );
    return pricings;
  };

  handlePricingListtt = () => {
    const { t } = this.props;
    let pricingss = "";
    pricingss = this.state.pricingPlan.list.map((item) =>
      item.id == 13 || item.id == 14 || item.id == 15 || item.id == 4 ? (
        <div id={"price_" + item.id} className="col-md-6 col-lg-3">
          <div className="price-box rounded-3 position-relative overflow-hidden mb-4">
            <div className="best-seller">
              {item.id == 4 ? (
                <h3 className="text-second fs-4 mb-3 text-left position-relative">
                  Enterprise
                </h3>
              ) : (
                <h3 className="text-second fs-4 mb-3 text-left position-relative">
                  {item.name}
                </h3>
              )}
              {item.id == 14 ? (
                <h5 className="most-popular">Best Seller</h5>
              ) : (
                ""
              )}
            </div>
            <span
              className="align-items-center d-flex justify-content-start rounded-pill mb-2 mx-auto fw-bold "
              id={"price_sec_" + item.id}
            >
              {item.price_symbol}{item.price} <span className="month">/yearly</span>
            </span>
            {item.id == 4 ? (
              <p className="tag-line">Use technology to improve your workflows and increase productivity.</p>
            ) : (
              ""
            )}
            {item.id == 13 ? (
              <p className="tag-line">Train employees on best practices to ensure proper execution.</p>
            ) : (
              ""
            )}
            {item.id == 14 ? (
              <p className="tag-line">Simplify your business processes for smoother operations.</p>
            ) : (
              ""
            )}
            {item.id == 15 ? (
              <p className="tag-line">Streamline your business processes for greater efficiency.</p>
            ) : (
              ""
            )}

            {
              item.id == 4 ? <Alert />
               :
                <a
                  href={"https://" +
                    Constant.url +
                    ".tasktak.com/authentication/register?a=" +
                    item.id
                  }
                  className="text-decoration-none  text-white"
                >
                  Get it Now
                </a>
            }

            {item.id == 4 ? <p className="custom-starting">Starting From*</p> :

              <a
                href={"https://" +
                  Constant.url +
                  ".tasktak.com/authentication/register"
                }
                className="text-decoration-underline  free-packages"
              >
                Try it now for free<img src={arrow} alt="" className="ml-3" />
              </a>
            }

            <ul className="list-unstyled text-gray small mb-3 custom-ul">
              <ul className="list-unstyled text-gray small mb-3 custom-ul">
                <li className="mb-2">
                  <img src={circle} alt="" className="me-2" />
                  {item.id == 4 ? "11 Users" :
                    item.max_users + " " + t("pricing:user_data")
                  }
                </li>
                <li className="mb-2">
                  <img src="" alt="" />
                  <img src={circle} alt="" className="me-2" />
                  {item.id == 4 ? "325 Customers" :
                    item.max_customers + " " + t("pricing:customer_data")
                  }
                </li>
                <li className="mb-2">
                  <img src={circle} alt="" className="me-2" />
                  {item.id == 4 ? "9000 Leads" :
                    item.max_leads + " " + t("pricing:leads_data")
                  }
                </li>
              </ul>
            </ul>
          </div>
        </div>
      ) : (
        ""
      )
    );
    return pricingss;
  };

  callApi = (urll) => {
    let classRef = this;
    axios({
      method: "GET",
      url: urll,
    }).then(function (result) {
      console.log(result.data.data)
      classRef.setState({
        pricingPlan: {
          isLoaded: true,
          list: result.data.data,
        },
      });
    });
  };

  render() {
    const { t } = this.props;
    return (
      <section id="pricing" className="mt-5 pt-4">
        <Container>
          <Row>
            <div className="col-12 col-md-12 mx-auto text-center">
              <h2 className="fw-medium">Enhance your team's collaboration and communication<br /> with a well-tailored workflow plan!</h2>
              <div className="check-tt">
                <ul>
                  <li><img src={circle} alt="" /> Switch Plans Anytime</li>
                  <li><img src={circle} alt="" /> No Credit Card Required</li>
                  <li><img src={circle} alt="" /> Cancel Anytime</li>

                </ul>
              </div>

            </div>

            <div className="clearfix"></div>
            <div className="col-lg-12 col-xl-12 mx-auto mt-5">
              <Row>
                <div className="tt_pricing">
                  <fieldset id={"group"} className="group2">
                    <input
                      type="radio"
                      onClick={(e) => this.radioButton("month", e)}
                      id={"mont"}
                      value={"month"}
                      name={"group"}
                    />
                    <label className="lbl_mo pricechecbox" for={"mont"}>
                      Pay Monthly <br />
                      <span>Commit monthly</span>
                    </label>
                    <input
                      type="radio"
                      onClick={(e) => this.radioButton("year", e)}
                      id={"year"}
                      value={"year"}
                      name={"group"}
                    />
                    <label className="lbl_yr" for={"year"}>
                      Pay Yearly save <span className="lbl__yr">10%</span>
                      <br />
                      <span>Commit yearly</span>
                    </label>
                  </fieldset>
                </div>
                {this.state.pricingPlan.isLoaded === true &&
                  this.state.isYearly == false ? (
                  <div id="monthly-packages" className="row">
                    {this.handlePricingList()}
                  </div>
                ) : this.state.pricingPlan.isLoaded === true &&
                  this.state.isYearly == true ? (
                  <div id="yearly-packages" className="row">
                    {this.handlePricingListtt()}
                  </div>
                ) : (
                  ""
                )}
              </Row>
            </div>
          </Row>
        </Container>
      </section>
    );
  }
}
export default withTranslation("pricing")(Pricing);
// export default Pricing;
