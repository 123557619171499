import React from "react";
import Global_Section_Main from "./Global_Section_Main";
import Global_Section_A from "./Global_Section_A";
import Global_Section_B from "./Global_Section_B";
import Banner from "../../../assets/images/coll-banner.svg";
import Section_A from '../../../assets/images/coll-a.svg';
import Section_B from '../../../assets/images/coll-b.svg';
import Section_C from '../../../assets/images/coll-c.svg';
import { useTranslation } from "react-i18next";

const Collarborative = () => {
  const { t } = useTranslation(["innerpages"]);
  return (
    <>

      <Global_Section_Main
        title={'Collaborative CRM Software'}
        description={'TaskTak\'s Collaborative feature assists enterprises in creating secure and real-time communication among various departments and institutions. The exchange of data in this way also promotes safe and secure decision-making, faster customer care response times, and better client support. Additionally, collaborating as a team ensures the best possible experience for each customer.        '}
        image={Banner}
      />
      <Global_Section_A
        title={'Collaborative customer journey'}
        description={'We shift our focus away from transactional sales and more towards conversational marketing and customer co-creation. Moving away from transactional sales also allows for improved customer relationships and better customer service, as it focuses more on understanding their needs and less on making a sale. '}
        image={Section_A}
        color="gray mt-top"
      />
      <Global_Section_B
        title={'Increased revenues'}
        description={'TaskTak has established a sterling reputation by providing superior customer service and fostering client loyalty. It increases the sale of your goods and services. Increased sales lead to higher returns and profits, which are the primary performance measures for every business.'}
        image={Section_B}
        color="gray"
      />
      <Global_Section_A
        title={'Tool Integration '}
        description={'We provide integrations with diverse applications so that you may combine data from several channels. This eliminates the need to manually switch between applications, giving you time to concentrate on more important activities. It also provides you with greater control over your data and helps you to get insights that you would not have had otherwise.'}
        image={Section_C}
        color="white"
      />
    </>
  )
}

export default Collarborative