import React from 'react';
import { useTranslation } from "react-i18next";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import banner from '../../assets/images/home/section-girl.png'
import icon_1 from '../../assets/images/home/icon-1.svg'
import icon_2 from '../../assets/images/home/icon-2.svg'
import icon_3 from '../../assets/images/home/icon-3.svg'
import icon_4 from '../../assets/images/home/icon-4.svg'
import icon_5 from '../../assets/images/home/icon-5.svg'
import icon_6 from '../../assets/images/home/icon-6.svg'
import icon_7 from '../../assets/images/home/icon-7.svg'
import icon_8 from '../../assets/images/home/icon-8.svg'
import icon_9 from '../../assets/images/home/icon-8.svg'

const Sero = () => {
    const { t } = useTranslation(["service", "home"]);
    const options = {
        margin: 30,
        responsiveClass: true,
        nav: false,
        rewind: false,
        dots: true,
        lazyLoad: 'true',
        lazyContent: 'true',
        loop: false,
        autoplay: false,

        navText: ["Prev", "Next"],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
                autoWidth: true,
            },
            400: {
                items: 2,
                autoWidth: true,
            },
            600: {
                items: 2,
            },
            700: {
                items: 2,
                autoWidth: true,
            },
            1000: {
                items: 4,
                autoWidth: true,

            },
            1200: {
                items: 4,
                autoWidth: true,

            },
            1600: {
                items: 8,
                autoWidth: true,

            },
            1700: {
                items: 8,
                autoWidth: true,

            },
            2000: {
                items: 8,
                autoWidth: true,

            }
        },
    };

    const dataArr = [
        {
            id: 1,
            icon: icon_1,
            title: " .أدارة العلاقات الخاصة بالعملاء ",
            point1: " تتبع العملاء المستقبليين   ",
            point2: " بريد إلكتروني ",
            point3: "المواعيد ",
            point4: " تقويم  ",
            point5: " سجلات المكالمات  ",
            point6: " تقنية الاجتماع عبر زووم  ",
            point7: "  اشتراكات العملاء   ",
            divClass: "serv_1",
            link: "أبدأ "
        },

        {
            id: 2,
            icon: icon_2,
            title: "  إدارة المشروع   ",
            point1: " مشروع لكل عميل  ",
            point2: " مسار تقدم المشروع  ",
            point3: " فاتورة لكل مشروع  ",
            point4: "المهام",
            point5: " تتبع الوقت  ",
            point6: "  تحديد الأهداف  ",
            point7: " منصة للناقش   ",
            divClass: "serv_2",
            link: "أبدأ "
        },

        {
            id: 3,
            icon: icon_3,
            title: " أدارة الموارد البشرية   ",
            point1: "  سجلات الموظفين  ",
            point2: "  قائمة التحقق للمرشحين الجدد    ",
            point3: "التوظيف ",
            point4: "التقارير ",
            point5: "أدوات العمل الإيجابي",
            point6: " قائمة التحقق الخاصة بالتسريح  ",
            point7: " إعدادات   ",
            divClass: "serv_3",
            link: "أبدأ "
        },

        {
            id: 4,
            icon: icon_4,
            title: "  العمل التعاوني  ",
            point1: " المحادثة الفردية   ",
            point2: " المحادثة الجماعية   ",
            point3: "    مشاركة الملفات ",
            point4: " احالة المهمة  ",
            point5: " اجتماعات الزووم   ",
            point6: "  تتبع الهدف  ",
            point7: " الرسائل الصوتية / الملفات  ",
            divClass: "serv_4",
            link: "أبدأ "
        },

        {
            id: 5,
            icon: icon_5,
            title: "  المحاسبة   ",
            point1: "الفواتير",
            point2: "  سجل المبيعات  ",
            point3: "  سجل المصاريف  ",
            point4: "تتبع الشراء",
            point5: "تتبع المخزون",
            point6: " سجل الأصول   ",
            point7: "إدخالات دفتر اليومية",
            divClass: "serv_5",
            link: "أبدأ "
        },

        {
            id: 6,
            icon: icon_6,
            title: " العمليات الداخلية    ",
            point1: " رسائل البريد الإلكتروني   ",
            point2: "تصميم المخططات",
            point3: " أوراق الجداول الألكترونية    ",
            point4: "سجلات النشاط",
            point5: "الإعلانات",
            point6: "الدراسات الاستقصائية",
            point7: "  بناء قائمة الأعمال   ",
            divClass: "serv_6",
            link: "أبدأ "
        },
        {
            id: 7,
            icon: icon_7,
            title: "  الدوام  ",
            point1: " الحضور   ",
            point2: "طلبات الإجازة",
            point3: " تعيين طرق العمل   ",
            point4: "  جدول الورديات   ",
            point5: " أماكن التواجد   ",
            point6: "مكان العمل",
            point7: "التقارير",
            divClass: "serv_7",
            link: "أبدأ "
        },
        {
            id: 8,
            icon: icon_8,
            title: " أدوات التسويق والتحليل   ",
            point1: " حملة تسويق   ",
            point2: "سيو",
            point3: "التسويق الآلي",
            point4: " إدارة العملاء المستقبليين   ",
            point5: " إدارة مواقع التواصل الإجتماعي   ",
            point6: " جدولة الحملات الأعلانية   ",
            point7: " البريد الإلكتروني الدعائي  ",
            divClass: "serv_8",
            link: "أبدأ "
        },
        {
            id: 9,
            icon: icon_9,
            title: "الدعم",
            point1: "قاعدة المعرفة",
            point2: " تذاكر الدعم  ",
            point3: " دردشة المساعدة   ",
            point4: " سجل النشاط  ",
            point5: "الإعلانات",
            point6: " الدراسات الاستقصائية   ",
            point7: "  بناء قائمة الأعمال    ",
            divClass: "serv_9",
            link: "أبدأ "
        },
    ];
    return (
        <>




                    <OwlCarousel className="owl-theme" {...options}>
                        {

                            dataArr.map(item => (

                                <div className='item' key={item}>
                                    <div id={item.divClass} className="features-box" >
                                        <div className='service-box mb-4 p-3'>
                                            <span className='align-items-center d-flex justify-content-around rounded-pill mb-2'>
                                                <img src={item.icon} alt='img' className='service-images' />
                                            </span>
                                            <h3 className="text-second fs-4">{item.title}</h3>
                                            <ul className='list-unstyled text-gray small mb-3 white-space-nowrap'>
                                                <li className="mb-1">{item.point1}</li>
                                                <li className="mb-1">{item.point2}</li>
                                                <li className="mb-1">{item.point3}</li>
                                                <li className="mb-1">{item.point4}</li>
                                                <li className="mb-1">{item.point5}</li>
                                                <li className="mb-1">{item.point6}</li>
                                                <li className="mb-1">{item.point7}</li>
                                            </ul>
                                            <a href="/pricing" className='text-decoration-none'> {item.link} </a>
                                        </div>
                                    </div>

                                </div>
                            ))

                        }

                    </OwlCarousel>
         


        </>
    )
}
export default Sero  