import React from "react";
import Global_Section_Main from "./Global_Section_Main";
import Global_Section_A from "./Global_Section_A";
import Global_Section_B from "./Global_Section_B";
import Banner from "../../../assets/images/help-banner.svg";
import Section_A from "../../../assets/images/help-a.svg";
import Section_B from "../../../assets/images/help-b.svg";
import Section_C from "../../../assets/images/help-c.svg";
import { useTranslation } from "react-i18next";

const HelpDesk = () => {
  const { t } = useTranslation(["innerpages"]);
  return (
    <>
      <Global_Section_Main
        title={'CRM and Helpdesk Software'}
        description={'Take advantage of TaskTak Helpdesk to help you out. Make repetitive tasks easier and faster by automating them. Automate your workflows to speed up your business processes. Organize your daily tasks even faster.'}
        image={Banner}
      />
      <Global_Section_A
        title={'Ticket Filters'}
        description={'With our powerful ticket filtering system, you get a clutter-free environment that ensures the right tickets go to the right department. The system ensures that tickets are handled; by the right team in a timely manner and lessens the amount of time spent on irrelevant tickets.'}
        image={Section_A}
        color="gray mt-top"
      />
      <Global_Section_B
        title={'Dashboard Reports'}
        description={'Our dashboard gives you real-time insights into the performance of your help desk, such as the number of tickets resolved, the average resolution time, and the volume of customer inquiries. It helps you quickly identify areas of improvement and ensure that your customers are getting the best support possible.'}
        image={Section_B}
        color="gray"
      />
      <Global_Section_A
        title={'Get ticket notifications'}
        description={'By setting up notifications, you will be able to receive an alert when a ticket arrives. Keeping an eye on your account this way ensures; that you don\'t miss any important announcements. Plus, it takes away the stress of constantly checking your account for updates.'}
        image={Section_C}
        color="white"
      />
    </>
)
}

export default HelpDesk