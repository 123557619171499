import React from "react";
import { Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import banner from "../../assets/images/home/about.png";
import AboutLogo from "./AboutLogo";


const About = () => {

  const { t } = useTranslation(["home"]);
  return (
    <section
      id="about"
      className="bg-tk overflow-hidden position-relative about-main"
    >
      
        <AboutLogo/>
        
       
        <Container>
        
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6">
          <h6 className="text-orange mb-center test-top">{t("discover")}</h6>
            <h2 className="fw-medium lh-sm mb-5 custom-about">
              {t("tech")} <br />  {t("experience")}
              
             
            </h2>
            <p className="text-gray lh-19 mb-4 pb-2">{t("about_detail")} </p>
            <p className="text-gray lh-19 mb-4 pb-2">{t("about_detail_a")}</p>
          </div>
          <div className="col-12 col-md-6 text-center">
            <img src={banner} alt="" className="about-image" />
          </div>
          <p className="text-gray lh-19 mb-4 pb-2">{t("about_detail_b")}</p>
          <p className="text-gray lh-19 mb-4 pb-2">{t("about_detail_c")}</p>
        </div>
      </Container>
    </section>
  );
};

export default About;
