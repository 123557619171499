import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Navbar, NavDropdown, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import $ from "jquery";
import { menuItems } from "./Mega";
import MenuItems from "./MenuItems";

import axios from "axios";
import Logo from "../../assets/images/logo.png";
import Constant from "../../utils/Constant";
import uae from "../../assets/images/uae-flag.png";
import america from "../../assets/images/america.webp";
import { useLocation } from "react-router-dom";


const Header = () => {


    $('#sub-menu').on("click", function(e) { 
      e.preventDefault();
      $('#sub-menu-ul').addClass('d-block'); 
      }
    );
    $('#sub-sub-menu').on("click", function(e) { 
      e.preventDefault();
      $('#sub-menu-ul-ul').addClass('d-block'); 
      }
    );

    const { pathname } = useLocation();

  
  const [navbar, setNavbar] = useState(false);
  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  });

  const { i18n, t } = useTranslation(["home"]);

  

  useEffect(() => {
    axios
      .get(
        "https://" +
          Constant.url +
          ".tasktak.com/Subscription/getStaffDefaultLanguage"
      )
      .then(function (response) {
        console.log(response.data, "responseresponse");
        if (response.data.code == 200) {
          i18next.changeLanguage("ar");
        } else {
          i18next.changeLanguage("en");
        }
      });
    // if (localStorage.getItem("i18nextLng")?.length > 2) {
    //     i18next.changeLanguage("en");
    // }
  }, []);

  const handleLanguageChange = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  return (
    <div className="">
      <header className="fixed-top">
        <Navbar
          collapseOnSelect
          expand="lg"
          className={
            navbar
              ? "navbar shadow-th mb-0 pt-3 pb-3 active w3_megamenu "
              : "navbar mb-0 pt-3 pb-3 w3_megamenu"
          }
        >
          <Container>
            <Navbar.Brand>
              <Nav.Link href="/">
                <img src={Logo} alt="logo" />
              </Nav.Link>
            </Navbar.Brand>
            <Nav.Link
              href="/pricing" className="ms-auto me-"
            >
              <button className="d-block d-lg-none border-0  btn-blue small   px-4 py-2 rounded-pill">
                Sign Up
              </button>
            </Nav.Link>

            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              className="bg-yellow border-0 rounded-circle p-2"
            />
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
              <Nav className=""> 

              {pathname === "/npo" ? (

              <Nav.Link
                  className={"nav-link text-main px-md-3"}
                  href="#npo"
                >
                 {t("links_pricing")}
                </Nav.Link> 

              ) : (
                <Nav.Link
                  className={"nav-link text-main px-md-3"}
                  href="/pricing"
                >
                 {t("links_pricing")}
                </Nav.Link> 
              )}

   
                <Nav.Link
                  className={"nav-link text-main px-md-3"}
                  href={
                    "https://" + Constant.url + ".tasktak.com/knowledge-base"
                  }
                  target="_blank"
                >
                  {t("links_knowledge")}
                </Nav.Link>
   
                  
                    {/* {menuItems.map((menu, index) => {
                      const depthLevel = 0;
                      return <MenuItems items={menu} key={index} depthLevel={depthLevel} />;
                    })} */}


                <NavDropdown title={t("link_indurties")} id="basic-nav-dropdown">
                  <NavDropdown.Item
                    href="/npo"
                    className={"nav-link text-main px-md-3"}
                  >
                   {t("links_profit")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="/oil-and-gas"
                    className={"nav-link text-main px-md-3"}
                  >
                    {t("links_oil")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="/gym"
                    className={"nav-link text-main px-md-3"}
                  >
                    {t("links_gym")}
                  </NavDropdown.Item>
                </NavDropdown>
                  


                <NavDropdown title={t("sign_in")} id="basic-nav-dropdown">
                  <NavDropdown.Item
                    href={
                      "https://" +
                      Constant.url +
                      ".tasktak.com/authentication/login"
                    }
                    className={"nav-link text-main px-md-3"}
                  >
                    {t("login_admin")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href={
                      "https://" +
                      Constant.url +
                      ".tasktak.com/admin/authentication/companies"
                    }
                    className={"nav-link text-main px-md-3"}
                  >
                    {t("login_staff")}
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
              {/* <Nav className="d-none d-lg-block">
                <Nav.Link
                  href={
                    "https://office-app.tasktak.com/fundraising"
                  }
                >
                  <button className="border-0  btn-blue small  px-4 py-2 rounded-pill">
                    {t("Donate Now")}
                  </button>
                </Nav.Link>
              </Nav> */}
              <Nav className=" d-lg-block">
                {/* <form action="" value={localStorage.getItem("i18nextLng")}
                  onChange={handleLanguageChange} className="language-change">
                    <div class="custom-radio">
                    <input type="radio" name="lang_ee" value="en" id="one" />
                  <label for="one">
                    <img src={america} alt="" />
                    </label>
                  
                  </div>
                  <div class="custom-radio">
                  <input type="radio" name="lang_ee" value="ar" id="second" />
                  
                  <label for="second">
                  <img src={uae} alt="" />
                  </label>
                  </div>
                  
                </form> */}
                

                <select value={localStorage.getItem("i18nextLng")}
                  onChange={handleLanguageChange} className="language-change border-0  "
                  
                >
                  <option value="en">{t("Eng")}</option>
                  <option value="ar">{t("عربی")}</option>
                </select>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </div>
    
  );
};

export default Header;
