import React from "react";
import { Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import banners from "../../assets/images/home/tuslr.png";
import bannerT from "../../assets/images/home/tow.png";
import bannerU from "../../assets/images/home/lpc.png";
import bannerV from "../../assets/images/home/logic.png";
import bannerA from "../../assets/images/home/shield.png";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";


const AboutLogo = () => {
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    rewind: false,
    dots: true,
    lazyLoad: "true",
    lazyContent: "true",
    loop: false,
    autoplay: false,
    navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      600: {
        items: 2,
      },
      700: {
        items: 5,
      },
      1000: {
        items: 5,
      },
      1200: {
        items: 5,
      },
    },
  };
  const { t } = useTranslation(["home"]);
  return (
    <>
    <Container className="px-5 px-md-3">
    <h6 className="text-orange text-center test-top">{t("strategic")}</h6>
    <h4 className="text-center logo-text">{t("building")}</h4>
    <div className="mt-5 mb-5">


        <OwlCarousel className="owl-theme" {...options}>

          <div className="item item-logo-img">
            <img src={banners} alt="" />
          </div>
          <div className="item item-logo-img">
          <img src={bannerT} alt="" />
          </div>
          <div className="item item-logo-img">
          <img src={bannerU} alt="" />
          </div>
          <div className="item item-logo-img">
          <img src={bannerV} alt="" />
          </div>
          <div className="item item-logo-img">
          <img src={bannerA} alt="" />
          </div>
          
        </OwlCarousel>
            
    </div>
    </Container>
    </>
      
  );
};

export default AboutLogo;