import React, { useState } from 'react'
import { Container, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import banner from '../../assets/images/home/hero-banner.png'
import axios from 'axios';
import $ from 'jquery';
import Constant from "../../utils/Constant";
const Home = () => {
    const { t } = useTranslation(["home"]);
    const [open, setOpen] = React.useState(false);
    const [state, setState] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        company_name: "",
        web_url: "",
    })
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    const callApi = () => {
        $("#submit_btn").html('Processing...')
        $('#submit_btn').attr('disabled', true);
        let classRef = this;
        var first_name = state.first_name
        var last_name = state.last_name
        var email = state.email
        var phone = state.phone
        var company_name = state.company_name
        var web_url = state.web_url
        axios.get('https://' + Constant.url + '.tasktak.com/Subscription/addLead', {
            params: { first_name: first_name, last_name: last_name, email: email, phone: phone, company_name: company_name, web_url: web_url }
        }).then(response => {
            $("#submit_btn").html('Submit')
            setOpen(false)
            // $('#submit_btn').attr('disabled', false);
        });

    }

    return (
       
            <div id="home" className='banner position-relative text-center text-md-start'>
                <Container className='h-100'>
                    <Row className='h-100 align-items-center'>
                        <div className='col-12 col-md-6'>
                          
                            <h1 className=' fw-bold display-4 mb-3 text-main-heading'>{t("home_main_heading")} <span class="task">{t("tas")}</span><span class="tak">{t("tak")}</span> {t("othr")}</h1>
                            {/* <p className='text-main fs-3 fw-medium lh-35'>To the future with the most advanced, User friendly business management system</p> */}
                            <p className='text-gray lh-19 mb-4 pb-2'>{t("home_detail")}</p>
                            <div className='d-block d-md-flex align-items-start flex-column'>
                                <a href="/pricing" className='col-md-auto col-5 btn btn-yellow border-0 rounded-pill fs-5 lh-23 px-4 py-2 me-2 me-md-4' style={{ color: '#fff' }}>{t("try_it_button")}</a>
                                <p className='col-md-auto mb-0 text-orange mt-3 mt-md-2' >{t("no_crdeit_card")}</p>
                            </div>

                        </div>
                        <div className='col-12 col-md-6'>
                            <img src={banner} alt='img' className='img-fluid pt-0 mt-4 mt-md-5' />
                        </div>
                    </Row>
                </Container>
                <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        {t("platform")}

                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <form action="javascript:void(0)">
                                <div className="row">
                                    <div className="col-lg-6 mb-3">
                                        <label className="tasktak_form-label">{t("first_name")} *</label>
                                        <input type="text" className="tasktak_form-control" required
                                            id="first_name"
                                            name="first_name"
                                            value={state.first_name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <label className="tasktak_form-label">{t("last_name")} *</label>
                                        <input type="text" className="tasktak_form-control" required
                                            id="last_name"
                                            name="last_name"
                                            value={state.last_name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <label className="tasktak_form-label">{t("email_field")} *</label>
                                        <input type="email" className="tasktak_form-control" required
                                            id="email"
                                            name="email"
                                            value={state.email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <label className="tasktak_form-label">{t("phone_field")} *</label>
                                        <input type="number" className="tasktak_form-control" required
                                            id="phone"
                                            name="phone"
                                            value={state.phone}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <label className="tasktak_form-label">{t("company_email")} *</label>
                                        <input type="text" className="tasktak_form-control" required
                                            id="company_name"
                                            name='company_name'
                                            value={state.company_name}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="col-lg-6 mb-3">
                                        <label className="tasktak_form-label">{t("web_url")}</label>
                                        <input type="text" className="tasktak_form-control"
                                            id="web_url"
                                            name='web_url'
                                            value={state.web_url}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-lg-12 mb-5">
                                        <input className="form-check-input" type="checkbox" required id="sub_desc" name='sub_desc' />
                                        <label className="form-check-label" style={{ paddingLeft: "10px" }}>{t("sub_desc")}</label>
                                    </div>

                                    <div>
                                        <p className="tasktak_textbox-label">{t("privacy_text")}<a href="/privacy-policy">Privacy Policy.</a></p>
                                    </div>

                                    <div className="mb-3">
                                        <button type="submit" className="btn btn-yellow mx-auto border-0 d-block rounded mt-3 px-4 py-2" style={{ width: "200px" }} id="submit_btn" onClick={callApi}>{t("get_demo_button")}</button>
                                    </div>

                                </div>
                            </form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>





    )
}
export default Home;