import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Container, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

function Screens() {
    const { t } = useTranslation(["service"]);
    const options = {
        margin: 30,
        responsiveClass: true,
        nav: true,
        dots: false,
        loop: true,
        autoplay: false,
        navText: ["<i class='bi bi-arrow-left fs-3'></i>", "<i class='bi bi-arrow-right fs-3'></i>"],
        smartSpeed: 500,
        responsive: {
            0: {
                items: 1,
                stagePadding: 0,
            },
            400: {
                items: 1,
                stagePadding: 0,
            },
            600: {
                items: 1,
                stagePadding: 0,
            },
            700: {
                items: 1,
                stagePadding: 0,
            },
            1199: {
                items: 1,

            }
        },
    };
    const { pathname } = useLocation();
    return (
        <>
            <div className='our__screen__conatiners position-relative bg-white'>
                <Container>
                <OwlCarousel className='owl-theme screen' {...options}>
                {pathname === "/oil-and-gas" ?  (
                    <>
                    <div className='item'>
                        <img src='assets/images/d2.png?v=a' alt='img' />
                    </div>
                    <div className='item'>
                        <img src='assets/images/d3.png' alt='img' />
                    </div>
                    <div className='item'>
                        <img src='assets/images/d4.png' alt='img' />
                    </div>
                    <div className='item'>
                        <img src='assets/images/d5.png?v=a' alt='img' />
                    </div>
                    <div className='item'>
                        <img src='assets/images/d6.png' alt='img' />
                    </div>
                    <div className='item'>
                        <img src='assets/images/d1.png' alt='img' />
                    </div>
                    <div className='item'>
                        <img src='assets/images/d2.png' alt='img' />
                    </div>
                  </>
                ): ""
                }
                {pathname === "/npo" ?  (
                    <>
                    <div className='item'>
                        <img src='assets/images/s-1.png' alt='img' />
                    </div>
                    <div className='item'>
                        <img src='assets/images/s-2.png' alt='img' />
                    </div>
                    <div className='item'>
                        <img src='assets/images/s-3.png' alt='img' />
                    </div>
                    <div className='item'>
                        <img src='assets/images/s-4.png?v=a' alt='img' />
                    </div>
                    <div className='item'>
                        <img src='assets/images/s-5.png' alt='img' />
                    </div>
                  </>
                ): ""
                }
                </OwlCarousel>
                </Container>
            </div>

        </>
    )
}
export default Screens  