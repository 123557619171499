import React from 'react';
import { useTranslation } from "react-i18next";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import banner from '../../assets/images/home/section-girl.png'
import icon_1 from '../../assets/images/home/icon-1.svg'
import icon_2 from '../../assets/images/home/icon-2.svg'
import icon_3 from '../../assets/images/home/icon-3.svg'
import icon_4 from '../../assets/images/home/icon-4.svg'
import icon_5 from '../../assets/images/home/icon-5.svg'
import icon_6 from '../../assets/images/home/icon-6.svg'
import icon_7 from '../../assets/images/home/icon-7.svg'
import icon_8 from '../../assets/images/home/icon-8.svg'
import icon_9 from '../../assets/images/home/icon-8.svg'
import Ser from './Ser';
import Sero from './Sero';

const Service = () => {
    const { t } = useTranslation(["service", "home"]);
    const options = {
        margin: 30,
        responsiveClass: true,
        nav: false,
        rewind: false,
        dots: true,
        lazyLoad: 'true',
        lazyContent: 'true',
        loop: false,
        autoplay: false,

        navText: ["Prev", "Next"],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
                autoWidth: true,
            },
            400: {
                items: 2,
                autoWidth: true,
            },
            600: {
                items: 2,
            },
            700: {
                items: 2,
                autoWidth: true,
            },
            1000: {
                items: 4,
                autoWidth: true,

            },
            1200: {
                items: 4,
                autoWidth: true,

            },
            1600: {
                items: 8,
                autoWidth: true,

            },
            1700: {
                items: 8,
                autoWidth: true,

            },
            2000: {
                items: 8,
                autoWidth: true,

            }
        },
    };
    const data = [
        {
            id: 1,
            icon: icon_1,
            title: "CRM",
            point1: "Lead Tracking",
            point2: "Email",
            point3: "Appointments",
            point4: "Calendar",
            point5: "Call Logs",
            point6: "Zoom Meetings",
            point7: "Client Subscriptions",
            divClass: "serv_1",
            link: "Get Started"
        },

        {
            id: 2,
            icon: icon_2,
            title: "Project Management",
            point1: "Per Client Projects",
            point2: "Project Progress",
            point3: "Invoice Per Project",
            point4: "Tasks",
            point5: "Time Tracking",
            point6: "Milestones",
            point7: "Discussion Board",
            divClass: "serv_2",
            link: "Get Started"
        },

        {
            id: 3,
            icon: icon_3,
            title: "HR Management",
            point1: "Employee Records",
            point2: "Onboarding Checklist",
            point3: "Recruiting",
            point4: "Reports",
            point5: "Affirmative Action Tools",
            point6: "Layoff Checklist",
            point7: "Settings",
            divClass: "serv_3",
            link: "Get Started"
        },

        {
            id: 4,
            icon: icon_4,
            title: "Team Collaboration",
            point1: "Individual Chat",
            point2: "Group Chat",
            point3: "File Sharing",
            point4: "Task Assignment",
            point5: "Zoom Meetings",
            point6: "Goal Tracking",
            point7: "Voice / File Messages",
            divClass: "serv_4",
            link: "Get Started"
        },

        {
            id: 5,
            icon: icon_5,
            title: "Accounting",
            point1: "Invoicing",
            point2: "Sales Recording",
            point3: "Expense Recording",
            point4: "Purchase Tracking",
            point5: "Inventory Tracking",
            point6: "Asset Recording",
            point7: "Journal Entries",
            divClass: "serv_5",
            link: "Get Started"
        },

        {
            id: 6,
            icon: icon_6,
            title: "Operations",
            point1: "Emails",
            point2: "Diagrams Design",
            point3: "Online Spreadsheets",
            point4: "Activity Logs",
            point5: "Announcements",
            point6: "Surveys",
            point7: "To-do Checklist",
            divClass: "serv_6",
            link: "Get Started"
        },
        {
            id: 7,
            icon: icon_7,
            title: "Timesheets",
            point1: "Attendance",
            point2: "Leave Requests",
            point3: "Assign Work Routes",
            point4: "Shift Scheduling",
            point5: "Location Based",
            point6: "Workplace",
            point7: "Reports",
            divClass: "serv_7",
            link: "Get Started"
        },
        {
            id: 8,
            icon: icon_8,
            title: "Marketing Tools & Analytics",
            point1: "Marketing Campaign",
            point2: "SEO",
            point3: "Automated Marketing",
            point4: "Lead Management",
            point5: "Social Media Management",
            point6: "Campaign scheduling",
            point7: "Bulk email",
            divClass: "serv_9",
            link: "Get Started"
        },
        {
            id: 9,
            icon: icon_9,
            title: "Support",
            point1: "Knowledge Base",
            point2: "Ticket Support",
            point3: "Help Chat",
            point4: "Activity Logs",
            point5: "Announcements",
            point6: "Surveys",
            point7: "To-do Checklist",
            divClass: "serv_9",
            link: "Get Started"
        },
    ];

    const dataArr = [
        {
            id: 1,
            icon: icon_1,
            title: " .أدارة العلاقات الخاصة بالعملاء ",
            point1: " تتبع العملاء المستقبليين   ",
            point2: " بريد إلكتروني ",
            point3: "المواعيد ",
            point4: " تقويم  ",
            point5: " سجلات المكالمات  ",
            point6: " تقنية الاجتماع عبر زووم  ",
            point7: "  اشتراكات العملاء   ",
            divClass: "serv_1",
            link: "أبدأ "
        },

        {
            id: 2,
            icon: icon_2,
            title: "  إدارة المشروع   ",
            point1: " مشروع لكل عميل  ",
            point2: " مسار تقدم المشروع  ",
            point3: " فاتورة لكل مشروع  ",
            point4: "المهام",
            point5: " تتبع الوقت  ",
            point6: "  تحديد الأهداف  ",
            point7: " منصة للناقش   ",
            divClass: "serv_2",
            link: "أبدأ "
        },

        {
            id: 3,
            icon: icon_3,
            title: " أدارة الموارد البشرية   ",
            point1: "  سجلات الموظفين  ",
            point2: "  قائمة التحقق للمرشحين الجدد    ",
            point3: "التوظيف ",
            point4: "التقارير ",
            point5: "أدوات العمل الإيجابي",
            point6: " قائمة التحقق الخاصة بالتسريح  ",
            point7: " إعدادات   ",
            divClass: "serv_3",
            link: "أبدأ "
        },

        {
            id: 4,
            icon: icon_4,
            title: "  العمل التعاوني  ",
            point1: " المحادثة الفردية   ",
            point2: " المحادثة الجماعية   ",
            point3: "    مشاركة الملفات ",
            point4: " احالة المهمة  ",
            point5: " اجتماعات الزووم   ",
            point6: "  تتبع الهدف  ",
            point7: " الرسائل الصوتية / الملفات  ",
            divClass: "serv_4",
            link: "أبدأ "
        },

        {
            id: 5,
            icon: icon_5,
            title: "  المحاسبة   ",
            point1: "الفواتير",
            point2: "  سجل المبيعات  ",
            point3: "  سجل المصاريف  ",
            point4: "تتبع الشراء",
            point5: "تتبع المخزون",
            point6: " سجل الأصول   ",
            point7: "إدخالات دفتر اليومية",
            divClass: "serv_5",
            link: "أبدأ "
        },

        {
            id: 6,
            icon: icon_6,
            title: " العمليات الداخلية    ",
            point1: " رسائل البريد الإلكتروني   ",
            point2: "تصميم المخططات",
            point3: " أوراق الجداول الألكترونية    ",
            point4: "سجلات النشاط",
            point5: "الإعلانات",
            point6: "الدراسات الاستقصائية",
            point7: "  بناء قائمة الأعمال   ",
            divClass: "serv_6",
            link: "أبدأ "
        },
        {
            id: 7,
            icon: icon_7,
            title: "  الدوام  ",
            point1: " الحضور   ",
            point2: "طلبات الإجازة",
            point3: " تعيين طرق العمل   ",
            point4: "  جدول الورديات   ",
            point5: " أماكن التواجد   ",
            point6: "مكان العمل",
            point7: "التقارير",
            divClass: "serv_7",
            link: "أبدأ "
        },
        {
            id: 8,
            icon: icon_8,
            title: " أدوات التسويق والتحليل   ",
            point1: " حملة تسويق   ",
            point2: "سيو",
            point3: "التسويق الآلي",
            point4: " إدارة العملاء المستقبليين   ",
            point5: " إدارة مواقع التواصل الإجتماعي   ",
            point6: " جدولة الحملات الأعلانية   ",
            point7: " البريد الإلكتروني الدعائي  ",
            divClass: "serv_8",
            link: "أبدأ "
        },
        {
            id: 9,
            icon: icon_9,
            title: "الدعم",
            point1: "قاعدة المعرفة",
            point2: " تذاكر الدعم  ",
            point3: " دردشة المساعدة   ",
            point4: " سجل النشاط  ",
            point5: "الإعلانات",
            point6: " الدراسات الاستقصائية   ",
            point7: "  بناء قائمة الأعمال    ",
            divClass: "serv_9",
            link: "أبدأ "
        },
    ];
    return (
        <>


            <section id="service" className='overflow-hidden position-relative service__section'>

                <div className='col-12 col-md-5 mx-auto text-center'>
                    <h2 className='fw-medium lh-sm'>{t("our_awesome_tool")}</h2>
                    <p className='text-gray'>{t("our_awesome_desc")}</p>
                </div>
                <div className='clearfix'></div>
                <div class="mt-3 pt-0 mt-md-5 d-flex">
                    <div className='left-image'>
                        <img src={banner} alt="" />
                    </div>



              
                        {t("service_condition") == "2" ? (
                        
                            <Ser />

                                )




                                :(

                                <Sero />

                                )
                        }

                          
                </div>




            </section>


        </>
    )
}
export default Service  