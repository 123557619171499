import React from "react";
import Global_Section_Main from "./Global_Section_Main";
import Global_Section_A from "./Global_Section_A";
import Global_Section_B from "./Global_Section_B";
import Banner from "../../../assets/images/hr_crm.png";
import Section_A from "../../../assets/images/hr-section-1a.png";
import Section_B from "../../../assets/images/hr-section-2a.png";
import Section_C from "../../../assets/images/hr-section-3a.png";

import { useTranslation } from "react-i18next";

const Cloudbase_Crm = () => {
  const { t } = useTranslation(["innerpages"]);
  return (
    <>

      <Global_Section_Main
        title={'Cloud Based CRM Software'}
        description={'TaskTak cloud CRM, headquartered in the USA, facilitates applications and data to be kept on secure OVH cloud servers. It is an optimal solution for businesses hoping to establish a secured and trustworthy BMP that can be easily accessed from anywhere via the internet. It helps companies improve customer interactions while allowing their sales staff to clinch more transactions and grow their income streams.'}
        image={Banner}
      />
      <Global_Section_A
        title={'Accessible from anywhere, at any time.'}
        description={'TaskTak cloud-based BMP provides round-the-clock accessibility, a distinct advantage over traditional on-premise CRMs that can only be accessed during office hours. This SaaS-based BMP makes it possible for teams to access information from anywhere, whether they are at work or on the go, and on any device - desktop, laptop, mobile phone, or tablet. In turn, this improves business process efficiency.'}
        image={Section_A}
        color="gray mt-top"
      />
      <Global_Section_B
        title={'Cost-effective choice'}
        description={'Since TaskTak cloud-based BMP; is hosted on a remote server, you will not have to worry about the cost of maintaining and upgrading it. You also don\'t have to worry about your data being secure, as we take complete responsibility for all necessary security measures. Our servers are encrypted using the latest security standards, and all data is stored securely and backed up regularly.'}
        image={Section_B}
        color="gray"
      />
      <Global_Section_A
        title={'Improves customer journey and promotes loyalty'}
        description={'With Tasktak cloud BMP, businesses can acquire, retain and manage their customers more efficiently. It facilitates large and small businesses; to pinpoint exactly where their sales process needs to be changed. They can then make changes to their process that will make it more customer-friendly and ensure that customers have a positive experience with the business.'}
        image={Section_C}
        color="white"
      />
    </>
  );
};

export default Cloudbase_Crm;
