import React from "react";
import Global_Section_Main from "./Global_Section_Main";
import Global_Section_A from "./Global_Section_A";
import Global_Section_B from "./Global_Section_B";
import Banner from "../../../assets/images/digital-banner.svg";
import Section_A from "../../../assets/images/digital-a.svg";
import Section_B from "../../../assets/images/digital-b.svg";
import Section_C from "../../../assets/images/digital-c.svg";
import { useTranslation } from "react-i18next";

const Digital = () => {
    const { t } = useTranslation(["innerpages"]);
    return (
      <>

        <Global_Section_Main
          title={'Digital Marketing CRM'}
          description={'Adding Tasktak CRM to your digital marketing strategies can add an additional layer of human interaction and personalization. It helps you build a stronger, more long-lasting relationship with customers.          '}
          image={Banner}
        />
        <Global_Section_A
          title={'Get deeper insights into your intended audience!'}
          description={'TaskTak CRM maintains customer data, offering you to generate a comprehensive picture of your customers and monitor any changes as your company expands. Knowing your clients origins, hobbies, and purchase habits helps you to focus on those who are most likely to be interested in your products.'}
          image={Section_A}
          color="gray mt-top"
        />
        <Global_Section_B
          title={'Streamline your marketing activities'}
          description={'With TaskTak CRM, you can effectively target, divide, automate, and analyze your digital marketing efforts. You may, for example, automate and tailor mailing campaigns or use remarketing/retargeting techniques to convert more prospects.'}
          image={Section_B}
          color="gray"
        />
        <Global_Section_A
          title={'Guaranteed customer satisfaction'}
          description={'By understanding each customer\'s needs, the customer service representatives will be better equipped; to address those needs and provide solutions to their concerns. It will improve customer satisfaction and help to create a more positive experience for the customer, leading to better customer loyalty.'}
          image={Section_C}
          color="white"
        />
      </>
  )
}



export default Digital