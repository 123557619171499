import React from "react";
import Global_Section_Main from "./Global_Section_Main";
import Global_Section_A from "./Global_Section_A";
import Global_Section_B from "./Global_Section_B";
import Banner from "../../../assets/images/social-banner.png";
import Section_A from "../../../assets/images/social-a.svg";
import Section_B from "../../../assets/images/social-b.png";
import Section_C from "../../../assets/images/social-a.svg";
import icon_1 from "../../../assets/images/home/facebook.png";
import icon_2 from "../../../assets/images/home/instagram.png";
import icon_3 from "../../../assets/images/home/twitter.png";
import icon_4 from "../../../assets/images/home/linkedin.png";
import icon_5 from "../../../assets/images/home/medium.png";
import icon_6 from "../../../assets/images/home/blogger.png";
import icon_7 from "../../../assets/images/home/reddit.png";
import icon_8 from "../../../assets/images/home/wp.png";
import icon_9 from "../../../assets/images/home/post.png";
import icon_10 from "../../../assets/images/home/schedule.png";
import icon_11 from "../../../assets/images/home/bulk.png";
import icon_12 from "../../../assets/images/home/csv.png";
import icon_13 from "../../../assets/images/home/repost.png";
import icon_14 from "../../../assets/images/home/image.png";
import icon_15 from "../../../assets/images/home/multipost.png";
import icon_16 from "../../../assets/images/home/text.png";


import { useTranslation } from "react-i18next";

const Social = () => {
    const { t } = useTranslation(["innerpages"]);
    const datas = [
      {
        id: 1,
        icon: icon_1,
        title: "Facebook",
      },
      {
        id: 2,
        icon: icon_2,
        title: "Instagram",
      },
      {
        id: 3,
        icon: icon_3,
        title: "Twitter",
      },
      {
        id: 4,
        icon: icon_4,
        title: "Linkedin",
      },
      {
        id: 5,
        icon: icon_5,
        title: "Medium",
      },
      {
        id: 6,
        icon: icon_6,
        title: "Blogger",
      },
      {
        id: 7,
        icon: icon_7,
        title: "Reddit",
      },
      {
        id: 8,
        icon: icon_8,
        title: "Wordpress",
      },
      {
        id: 9,
        icon: icon_9,
        title: "Post Now",
      },
      {
        id: 10,
        icon: icon_10,
        title: "Post Now",
      },
      {
        id: 11,
        icon: icon_11,
        title: "Bulk Post",
      },
      {
        id: 12,
        icon: icon_12,
        title: "CSV Bulk Post Planner",
      },
      {
        id: 13,
        icon: icon_13,
        title: "Repost",
      },
      {
        id: 14,
        icon: icon_14,
        title: "Image & Video",
      },
      {
        id: 15,
        icon: icon_15,
        title: "Carousel Post & Multi-image",
      },
      {
        id: 16,
        icon: icon_16,
        title: "Text Post",
      },
    ]
    return (
      <>

        <Global_Section_Main
          title={'Social Media CRM Software'}
          description={'The idea of a social media CRM; is to integrate all your social media channels within a single CRM platform. With TaskTak’s social media CRM, you can track conversations, sentiments, and engagement across multiple channels.'}
          image={Banner}
        />
              <section
        id="service"
        className="overflow-hidden  position-relative service__section inner_main  mt-top npo-inner-sections "
      >
        <div className="container">
          <div className="col-12 text-center">
            <h2 className="text-second fs-4 mb-3 pt-5 position-relative h2 ">
            Integrated Social Media for Posting </h2>
            <p className="min-width-para">Integrate different social media accounts to use bot, auto reply, social posting etc features.</p>
           
          </div>
          <div className="clearfix"></div>
          <div class="mt-5 pt-0 mt-md-5 row custom-pt">
            {datas.map((item) => (
              <div className="item col-md-3" key={item}>
                <div className="feature-inner single-feature">
                    <img src={item.icon} alt={item.title}/>
                    <p>{item.title}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
        <Global_Section_A
          title={'No More Painful Integration'}
          description={'Get insights straight from Facebook, Twitter, and LinkedIn within TaskTak CRM. It allows you to gain insights into customer behavior and preferences so that; you can tailor services and marketing campaigns accordingly.'}
          image={Section_A}
          color="gray mt-top"
        />
        <Global_Section_B
          title={'Multi-Channel Social Management'}
          description={'Multi-channel social media management through TaskTak CRM allows you to post content directly from the CRM to all channels at once, monitor brand mentions on the dashboard, and communicate with customers from a single location.'}
          image={Section_B}
          color="gray"
        />
        <Global_Section_A
          title={'Generate more Leads'}
          description={'With TaskTak CRM, you can reach out to prospects on social media when they engage with your content, discuss products or mention keywords related to your business. You can also automate your business processes with perfectly integrated apps and reap savings and benefits.'}
          image={Section_C}
          color="white"
        />
      </>
    )
}

export default Social