import React, { useState } from 'react'
import "../../contact.css";
import axios from "axios";
import $ from "jquery";
import Constant from "../../utils/Constant";
import footerImg from '../../assets/images/home/footer-right.png'
import footerImgL from '../../assets/images/home/footer-left.png'
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";


const FooterTop = () => {
  const { t } = useTranslation(["home"]);
  const { pathname } = useLocation();

  const hideClick = () => {
    $("#f-orm").addClass("d-none");
    $("#f-orms").removeClass("d-none");
    $("#cus-hi").addClass("d-none");
    $("#cus-his").removeClass("d-none");
  };
  const showClick = () => {
    $("#f-orms").addClass("d-none");
    $("#f-orm").removeClass("d-none");
    $("#cus-his").addClass("d-none");
    $("#cus-hi").removeClass("d-none");
  };
const sendQuery = () => {
  $("#errorMsg").hide();
  $("#successMsg").hide();
  var name = $("#name").val();
  var email = $("#email").val();
  var subject = $("#subject").val();
  var message = $("#message").val();
  
  if (name === "" || email === "" || subject === "" || message === "") {
    $("#errorMsg").show();
    $("#successMsg").hide();
  } else {
    $("#send_button").val("Processing...");
    
    axios({
      method: "GET",
      url: "https://" + Constant.url + ".tasktak.com/Subscription/sendMessage?email=" + email + "&name=" + name + "&subject=" + subject + "&message=" + message,
    })
    .then(function (result) {
      $("#send_button").val("Send Message");
      $("#successMsg").show();
      $("#errorMsg").hide(); // Hide the error message
      $("#contactForm").trigger("reset");
    })
    .catch(function (error) {
      $("#send_button").val("Send Message");
      console.error(error); // Handle the error, e.g., show an error message
    });
  }
};

    return (
      <>
        <div className="footer-top-wrapper">
          <img src={footerImgL} className="footer-img-left" alt="" />
          <img src={footerImg} className="footer-img-right" alt="" />
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <div className="footer-cta-wrapper">
                  <div className="footer-cta text-center" id="f-orm">
                    <div className="footer-cta-description">
                      <h3>
                        {t("let_work")} <br/>{t("together")}
                        
                      </h3>
                      <p>{t("ready_to")}</p>
                    </div>
                    <div class="btna" bis_skin_checked="1">
                    {pathname === "/npo" ? (
                      <a
                        href="#npo"
                        class="btn-11 btnss btn"
                      >
                        <small>
                          {t("try")} <i class="fas fa-long-arrow-alt-right"></i>
                        </small>
                      </a>
                    ):(<a
                      href="https://app.tasktak.com/authentication/register"
                      class="btn-11 btnss btn"
                    >
                      <small>
                        {t("try")} <i class="fas fa-long-arrow-alt-right"></i>
                      </small>
                    </a>)}
                    </div>
                  </div>
                  <div className="footer-btn">
                    <a
                      href="javascript:void"
                      onClick={hideClick}
                      id="cus-hi"
                    >
                      {t("contact")} <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                    </a>
                  </div>

                  <div className="footer-form d-none" id="f-orms">
                    <div className="contact-wrap w-100 ">
                      <div id="form-message-warning" className="mb-4"></div>
                      <div id="form-message-success" className="mb-4">
                        Your message was sent, thank you!
                      </div>
                      <div className="mb-4">
                        <h4>{t("send_mm")}</h4>
                      </div>
                      <form
                        id="contactForm"
                        name="contactForm"
                        className="contactForm"
                        action="javascript:void(0)"
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="name"
                                id="name"
                                placeholder="Name"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                id="email"
                                placeholder="Email"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="subject"
                                id="subject"
                                placeholder="Subject"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <textarea
                                name="message"
                                className="form-control"
                                id="message"
                                cols="30"
                                rows="4"
                                placeholder="Message"
                              ></textarea>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="form-group m-0 p-0">
                              <input
                                type="submit"
                                value={t("j_send")}
                                id="send_button"
                                className="btns btn-primary m-0"
                                onClick={sendQuery}
                              />
                              <div className="submitting"></div>
                            </div>

                            <div className="footer-btns">
                              <a
                                href="javascript:void"
                                onClick={showClick}
                                id="cus-his"
                              >
                                <i class="fa fa-long-arrow-left" aria-hidden="true"></i> {t("back")}
                              </a>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <p
                                className="p-0 pl-1 m-0"
                                id="successMsg"
                                style={{ display: "none", color: "green" }}
                              >
                                Your message has been sent
                              </p>
                              <p
                                className="p-0 m-0"
                                id="errorMsg"
                                style={{ display: "none", color: "red" }}
                              >
                                Please select all required fields
                              </p>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="cta-right">
                  <div className="footer-widget">
                    <h3 className="ft-title">{t("touch")}</h3>
                    <ul className="ft-link ft-cta">
                      <li>
                        <span >{t("call")}</span>
                        <a href="tel:+14252150293">+1 425 215 0293</a>
                      </li>
                      <li>
                        <span >{t("sms")}</span>
                        <a href="mailto:info@tasktak.com">info@tasktak.com</a>
                      </li>
                      <li>
                        <span >{t("timing")}</span>
                        <p >{t("mon")} </p>
                        <p >{t("sun")}</p>
                      </li>
                      <li>
                        <span >{t("contact")}</span>
                      </li>
                      <li className="d-block">
                      <a href="https://www.facebook.com/tasktakofficial">
                        <i className="fa fa-facebook"></i>
                      </a>
                 
                      <a href="https://twitter.com/tasktakofficial">
                        <i className="fa fa-twitter"></i>
                      </a>
                      <a href="https://www.linkedin.com/company/tasktak/">
                        <i className="fa fa-linkedin"></i>
                      </a>
                      <a href="https://www.youtube.com/@tasktak">
                        <i className="fa fa-youtube"></i>
                      </a>
                    </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </>
    );
  }

export default FooterTop;
