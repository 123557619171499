import React from "react";
import Global_Section_Main from "./Global_Section_Main";
import Global_Section_A from "./Global_Section_A";
import Global_Section_B from "./Global_Section_B";
import Banner from "../../../assets/images/bmp-banner.svg";
import Section_A from '../../../assets/images/bmp-a.svg';
import Section_B from '../../../assets/images/bmp-b.svg';
import Section_C from '../../../assets/images/bmp-c.svg';
import { useTranslation } from "react-i18next";

const Bmp = () => {
  const { t } = useTranslation(["innerpages"]);
  return (
    <>

      <Global_Section_Main
        title={'CRM Software'}
        description={'TaskTak CRM is a user-friendly and affordable solution that makes it easy to track your sales pipeline, automates tedious tasks, nurtures customer relations, and closes deals faster. This CRM software gives you the features and flexibility you need to manage and scale your sales process without the hefty price tag that comes with other CRMs. '}
        image={Banner}
      />
      <Global_Section_A
        title={'All-in-one tool to facilitate business operations'}
        description={'Automate your entire sales cycle, manage client interactions, and link your projects, marketing efforts, and much more within a single CRM database. It can help you identify new leads and opportunities while streamlining communication with existing customers.'}
        image={Section_A}
        color="gray mt-top"
      />
      <Global_Section_B
        title={'Lead Management'}
        description={'With TaskTak CRM software, businesses can monitor and identify their most effective lead sources, channels, and campaigns. It enables small and large businesses; to make more informed marketing decisions to maximize their ROI.'}
        image={Section_B}
        color="gray"
      />
      <Global_Section_A
        title={'Keeping sales and marketing aligned'}
        description={'CRM software promotes sales and marketing alignment by letting sales and marketing people know precisely; when to push clients forward. This, in turn, leads to more effective outreach and higher conversion rates. '}
        image={Section_C}
        color="white"
      />
    </>
  );
};

export default Bmp;
