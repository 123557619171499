import React from "react";
import { Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import img from '../../../assets/images/home/list-dot.svg'

const NpoGlobal = ({
    title,
    description,
    image,
    color,
    text,
    npoTitle,
    npoClasses,
    npoDesc,
    ngo1,ngo2,ngo3,ngo4,ngo5,ngo6,npoLast
  }) => {
    const { pathname } = useLocation();
    return (
      <div className={`inner_main ${color} ${npoClasses}`}>
        <Container>

       
              <h2 className="text-second fs-4 mb-3 position-relative h2 text-center">{npoTitle}</h2>
              <p className="text-center min-width-ps">{npoDesc}</p>
     

          <div className="mt-3 pt-0 mt-md-5 row custom-pt align-items-center">
            <div className="col-md-6 col-lg-6 col-12">
              <div className="col-text">
                <h3 className="text-second fs-4 mb-3 position-relative h2 ">
                  {title}
                </h3>
                <p >{description}</p>
                {ngo1 == ngo1 ? (
                <div
                  class="ngo-check style4 mb-40 list-center"
                  bis_skin_checked="1"
                >
                  
                  <ul>
                    {ngo1 == null ? (
                      ""
                    ) : (
                      <>
                    <li><img src={img} alt="" />{ngo1}</li>
                    </>
                    )
                    }
                    {ngo2 == null ? (
                      ""
                    ) : (
                      <>
                    <li><img src={img} alt="" />{ngo2}</li>
                    </>
                    )
                    }
                    {ngo3 == null ? (
                      ""
                    ) : (
                      <>
                    <li><img src={img} alt="" />{ngo3}</li>
                    </>
                    )
                    }
                    {ngo4 == null ? (
                      ""
                    ) : (
                      <>
                    <li><img src={img} alt="" />{ngo4}</li>
                    </>
                    )
                    }
                    {ngo5 == null ? (
                      ""
                    ) : (
                      <>
                    <li><img src={img} alt="" />{ngo5}</li>
                    </>
                    )
                    }
                    {ngo6 == null ? (
                      ""
                    ) : (
                      <>
                    <li><img src={img} alt="" />{ngo6}</li>
                    </>
                    )
                    }
                    
                  </ul>
                </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-12">
              <div className="col-image">
                <img src={image} alt="" />
              </div>
            </div>
          
              <p className="pt-4 last-ngo-desc">{npoLast}</p>
           
          </div>
        </Container>
      </div>
    );
  };

export default NpoGlobal