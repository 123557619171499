import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { useTranslation } from "react-i18next";

const Counter = () => {
  const [counterOn, setCounterOn] = useState(false);
  const { t } = useTranslation(["home"]);

  return (
    <>
    <section className="counter-section">
      <div className="container">
        <ScrollTrigger
          onEnter={() => setCounterOn(true)}
          onExit={() => setCounterOn(false)}
        >
          {counterOn && (
            <>
              <div className="row">
                <div className="col-md-3 col-sm-6 col-6">
                  <div className="counter-wrapper">
                    <CountUp
                      start={0}
                      end={30}
                      delay={0}
                      decimal=","
                      suffix=" +"
                    >
                      {({ countUpRef }) => (
                        <div className="counter-number">
                          <span ref={countUpRef} />
                        </div>
                      )}
                    </CountUp>
                    <div className="counter-client">
                      <p>{t("module")}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 col-6">
                  <div className="counter-wrapper">
                    <CountUp
                      start={0}
                      end={5}
                      delay={0}
                      decimal=","
                      // suffix=" +"
                    >
                      {({ countUpRef }) => (
                        <div className="counter-number">
                          <span ref={countUpRef} />
                        </div>
                      )}
                    </CountUp>
                    <div className="counter-client">
                      <p>{t("add-ons")}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 col-6">
                  <div className="counter-wrapper">
                    <CountUp
                      start={0}
                      end={100}
                      delay={0}
                      decimal=","
                      suffix=" +"
                    >
                      {({ countUpRef }) => (
                        <div className="counter-number">
                          <span ref={countUpRef} />
                        </div>
                      )}
                    </CountUp>
                    <div className="counter-client">
                      <p>{t("industry_exp")}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 col-6">
                  <div className="counter-wrapper">
                    <CountUp
                      start={0}
                      end={10}
                      delay={0}
                      decimal=","
                      suffix=" +"
                    >
                      {({ countUpRef }) => (
                        <div className="counter-number">
                          <span ref={countUpRef} />
                        </div>
                      )}
                    </CountUp>
                    <div className="counter-client">
                      <p>{t("global_part")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </ScrollTrigger>
      </div>
      </section>
    </>
  );
};

export default Counter;
