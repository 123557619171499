import React, { Suspense, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Header from "./components/layout/header";
import Footer from "./components/layout/Footer";
import "./root.css";
import "./style.css";
import Contact from "./components/pages/Contact";
import Landing from "./components/pages/Landing";
import Hrm_Software from "./components/pages/Inner_components/Hrm_Software";
import NotFound from "./components/pages/NotFound";
import { Routes, Route, useLocation } from "react-router-dom";
import Cloudbase_Crm from "./components/pages/Inner_components/Cloudbase_Crm";
import AOS from "aos";
import "aos/dist/aos.css";
import Saas from "./components/pages/Inner_components/Saas";
import B2b from "./components/pages/Inner_components/B2b";
import Project from "./components/pages/Inner_components/Project";
import Bmp from "./components/pages/Inner_components/Bmp";
import Collarborative from "./components/pages/Inner_components/Collarborative";
import Accounting from "./components/pages/Inner_components/Accounting";
import Marketing from "./components/pages/Inner_components/Marketing";
import Digital from "./components/pages/Inner_components/Digital";
import Lead from "./components/pages/Inner_components/Lead";
import BmpMarketing from "./components/pages/Inner_components/BmpMarketing";
import Erp from "./components/pages/Inner_components/Erp";
import Social from "./components/pages/Inner_components/Social";
import Task from "./components/pages/Inner_components/Task";
import Assets from "./components/pages/Inner_components/Assets";
import Excel from "./components/pages/Inner_components/Excel";
import Inventory from "./components/pages/Inner_components/Inventory";
import CustomerSupport from "./components/pages/Inner_components/CustomerSupport";
import HelpDesk from "./components/pages/Inner_components/HelpDesk";
import SalesMarketing from "./components/pages/Inner_components/SalesMarketing";
import Privacy from "./components/pages/Privacy";
import Terms from "./components/pages/Terms";
import Pricings from "./components/pages/Pricings";
import AboutPage from "./components/pages/Inner_components/AboutPage";
import Npo from "./components/pages/Inner_components/Npo";
import Webmail from "./components/pages/Inner_components/Webmail";
import "../src/components/layout/Jqeury"
import Thankyou from "./components/pages/Thankyou";
import Thank from "./components/pages/Thank";
import Oil from "./components/pages/Inner_components/Oil";
import Gym from "./components/pages/Inner_components/Gym";
import Appointly from "./components/pages/Inner_components/Appointly";




function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    AOS.init({
      offset: 300,
      duration: 600,
      easing: "ease-in-sine",
      delay: 100,
    });
    AOS.refresh();
  }, []);

  return (
    <>    
    {pathname === "/contact-thankyou" || pathname === "/enterprise-thankyou" ? "" :
       <Header />
      }
     

      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/hr-crm-software" element={<Hrm_Software />} />
        <Route exact path="/cloud-based-crm-software" element={<Cloudbase_Crm />} />
        <Route exact path="/crm-saas-software" element={<Saas />} />
        <Route exact path="/crm-for-b2b" element={<B2b />} />
        <Route exact path="/project-management-crm-software" element={<Project />} />
        <Route exact path="/crm-software" element={<Bmp />} />
        <Route exact path="/collaborative-crm-software" element={<Collarborative />} />
        <Route exact path="/crm-accounting-software" element={<Accounting />} />
        <Route exact path="/crm-email-marketing" element={<Marketing />} />
        <Route exact path="/digital-marketing-crm" element={<Digital />} />
        <Route exact path="/lead-management" element={<Lead />} />
        <Route exact path="/crm-marketing-software" element={<BmpMarketing />} />
        <Route exact path="/erp-crm-software" element={<Erp />} />
        <Route exact path="/social-media-crm-software" element={<Social />} />
        <Route exact path="/crm-task-management" element={<Task />} />
        <Route exact path="/asset-management-crm-software" element={<Assets />} />
        <Route exact path="/crm-spreadsheet-template" element={<Excel />} />
        <Route exact path="/crm-inventory-management-software" element={<Inventory />} />
        <Route exact path="/crm-customer-support-software" element={<CustomerSupport />} />
        <Route exact path="/crm-and-helpdesk-software" element={<HelpDesk />} />
        <Route exact path="/crm-software-for-sales-and-marketing" element={<SalesMarketing />} />
        <Route exact path="/about-us" element={<AboutPage />} />
        <Route exact path="/privacy-policy" element={<Privacy />} />
        <Route exact path="/terms-and-condition" element={<Terms />} />
        {/* <Route exact path="/testimonials" element={<Testimonials />} /> */}
        <Route exact path="/contact" element={<Contact />} />
        {/* <Route exact path="/faqs" element={<Faqs />} /> */}
        <Route exact path="/pricing" element={<Pricings />} />
        <Route exact path="/npo" element={<Npo />} />
        <Route exact path="/oil-and-gas" element={<Oil />} />
        <Route exact path="/gym" element={<Gym />} />
        <Route exact path="/email-management-software" element={<Webmail />} />
        <Route exact path="/contact-thankyou" element={<Thank />} />
        <Route exact path="/enterprise-thankyou" element={<Thankyou />} />
        <Route exact path="/appointly" element={<Appointly />} />
        <Route exact path="*" element={<NotFound />} />
      </Routes>
      {pathname === "/" || pathname === "/contact" || pathname ==="*" ? null : ''}


      {pathname === "/contact-thankyou" || pathname === "/enterprise-thankyou" ? "" :
      <Footer />
      }

    </>
  );
}

export default App;
