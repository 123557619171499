import React, { useState } from "react";
import Global_Section_Main from "./Global_Section_Main";
import Global_Section_A from "./Global_Section_A";
import Global_Section_B from "./Global_Section_B";
import Tab from "react-bootstrap/Tab";
import Banner from "../../../assets/images/hr-banner.svg";
import Section_A from "../../../assets/images/hr-a.svg";
import Section_B from "../../../assets/images/hr-b.svg";
import Section_C from "../../../assets/images/hr-c.svg";
import { useTranslation } from "react-i18next";
import Nav from "react-bootstrap/Nav";
import Lead from "../../../assets/images/lead-images.png";
import TabComponent from "./TabComponent";

const Hrm_Software = () => {
   const [show, setShow] = useState(false);
   const [hrmshow, sethrmShow] = useState(false);
   const [recordshow, setrecordShow] = useState(false);
   const [payrollshow, setpayrollShow] = useState(false);
   const data = [
     {
       image: Lead,
       link: "Dashboard",
       discription:
         "A user interface that provides an overview of key recruitment metrics and data, such as open job postings, campaigns, interviews and . It allows users to quickly access and manage recruitment tasks and information.",
       key: "first",
       hrm_title: "Dashboard",
       hrm_description:
         "A dashboard is a graphical interface that provides an overview of key HR metrics and KPIs, such as headcount, turnover rate, employee engagement, and performance indicators. The dashboard can be customized to display data relevant to different stakeholders, such as HR managers, executives, and employees.      ",
       hr_payroll: "Employees",
       hr_payroll_desc:
         "This refers to all individuals who are employed by your organization and are eligible to receive a salary or other compensation.",
       hr_record: "Dasboard",
       hr_record_desc:
         "This is a summary page that provides an overview of key HR metrics and information, such as headcount, turnover rate, and employee satisfaction.",
     },
     {
       image: Lead,
       link: "Campaign",
       discription:
         "A series of recruitment activities focused on filling a specific job opening or hiring for a particular role. A campaign may include multiple recruitment channels, such as job postings, social media advertising, or employee referrals.",
       key: "second",
       hrm_title: "Staff",
       hrm_description:
         "The staff module is where HR managers can manage and store information related to employee demographics, job history, performance, and other relevant data. This module may also include features such as employee self-service, which allows employees to view and update their personal and employment information.",
       hr_payroll: "Attendance",
       hr_payroll_desc:
         "This is a record of when employees show up for work and how many hours they work, which is used to calculate their pay.",
       hr_record: "Job descriptions",
       hr_record_desc:
         "These are written descriptions of the roles and responsibilities for each position in your organization. Org chart: This is a visual representation of the hierarchy and reporting structure within your organization.",
     },
     {
       image: Lead,
       link: "Candidate profile",
       discription:
         "A record of a candidate's personal and professional information, including their resume, work experience, education, and contact details. Candidate profiles are typically stored in a recruitment management platform to help recruiters track and evaluate candidate applications.",
       key: "third",
       hrm_title: "Contract",
       hrm_description:
         "The contract module is where HR managers can manage employment contracts and other legal documents, including offer letters, performance reviews, and termination agreements. The module may also include features such as e-signatures, version control, and alerts for contract renewals.",
       hr_payroll: "Commissions, Deductions & Bonus KPI",
       hr_payroll_desc:
         "This refers to any additional payments that employees may receive based on their sales performance or other measurable results.These are reductions in an employee's pay, such as taxes, retirement contributions, or wage garnishments.\nThis refers to bonuses that are paid out based on an employee's performance against key performance indicators (KPIs).",
       hr_record: "Org chart",
       hr_record_desc:
         "This is a visual representation of the hierarchy and reporting structure within your organization.",
     },
     {
       image: Lead,
       link: "Interview Schedule",
       discription:
         "A calendar or schedule that lists the dates and times of candidate interviews, as well as the interviewers involved. This allows recruiters to manage and schedule interviews more efficiently and avoid scheduling conflicts.",
       key: "forth",
       hrm_title: "Insurance",
       hrm_description:
         "The insurance module is where HR managers can manage employee benefits and insurance policies, including health, dental, vision, and life insurance. This module may also include features such as open enrollment management, claims processing, and compliance tracking.",
       hr_payroll: "Insurance & Taxes",
       hr_payroll_desc:
         "This refers to any health or other insurance policies that your organization may offer to employees as part of their compensation package. This refers to the taxes that employees must pay on their income, which are withheld from their paychecks by their employer. ",
       hr_record: "HR Records",
       hr_record_desc:
         "This is a database that contains information about all employees in your organization, such as their personal details, employment history, and performance reviews.",
     },
     {
       image: Lead,
       link: "Recruitment channels",
       discription:
         "The different methods or sources that businesses use to attract and recruit candidates. Recruitment channels can include job postings, social media, employee referrals, career fairs, and more.",
       key: "fifth",
       hrm_title: "Timesheets",
       hrm_description:
         "The timesheets module is where employees can log their hours worked, request time off, and manage their schedules. The module may also include features such as time tracking, overtime calculations, and shift management.",
       hr_payroll: "Payslip & Templates",
       hr_payroll_desc:
         "These are documents that show the breakdown of an employee's pay, including their gross pay, deductions, and net pay. These are pre-designed templates that can be used to create uniform, professional-looking payslips for all employees.",
       hr_record: "Layoff checklist",
       hr_record_desc:
         "This is a list of tasks that HR must complete when a layoff is necessary, such as notifying affected employees, coordinating severance packages, and updating HR records.",
     },
     {
       image: Lead,
       link: "Recruitment Portal",
       discription:
         "An online platform or website where candidates can search and apply for job openings, as well as upload their resumes and other application materials. A recruitment portal may be part of a larger recruitment management platform, or it may be a standalone website.",
       key: "sixth",
       hrm_title: "Payroll",
       hrm_description:
         "The payroll is where HR managers can access an overview of employee pay slips, as well as add and edit pay slip templates. Once the templates are configured, the “HR Payroll” module  will automatically generate pay slips based on the payroll data entered into the system.",
       hr_payroll: "Reports",
       hr_payroll_desc:
         "These are documents that provide information and insights about HR and payroll-related topics, such as employee attendance, payroll expenses, or tax withholdings.",
       hr_record: "Reports",
       hr_record_desc:
         "These are documents that provide information and insights about HR-related topics, such as employee performance, turnover, and diversity.",
     },
     {
       image: Lead,
       link: "Settings",
       discription:
         "The configuration options and preferences that can be set by users of a recruitment management platform. Settings can include things like user permissions, email notifications, and system preferences.",
       key: "seventh",
       hrm_title: "Settings",
       hrm_description:
         "The settings module is where HR managers can customize and configure the HRM system to meet the needs of their organization. This may include settings related to contract type, allowance type, payroll, job position and workplace.",
       hr_payroll: "Settings",
       hr_payroll_desc:
         "This refers to the configuration and customization options for your HR and payroll software, such as setting up tax rates or adjusting the format of payslips.",
       hr_record: "Settings",
       hr_record_desc:
         "This refers to the configuration and customization options for your HR software or other tools, such as setting up permissions for different users or adjusting notification settings.",
     },
   ];
  const { t } = useTranslation(["innerpages"]);

  return (
    <>

      <Global_Section_Main
        title={t("hr_main_heading")}
        description="Employees are critical to the success of any company, but managing them manually is a time-consuming and challenging task. Here comes the TaskTak HR CRM; it helps maintain the entire employee lifecycle in one place. From onboarding to performance evaluation, hours worked, holidays, and attendance; it automates and simplifies the whole employee lifecycle."
        image={Banner}
      />

      {/* section 1 */}

      <Global_Section_B
        title="HR MANAGEMENT"
        description="HR Management is a module that helps businesses streamline and automate their HR processes. It provides a centralized platform to manage employee data, payroll, benefits, performance reviews, and other HR-related activities. With Tasktak BMP's HR Management module, businesses can shrink administrative overhead, improve data accuracy, and ensure compliance with labor laws and regulations. By automating recurring processes, HR managers can focus on more strategic activities, such as talent acquisition, employee development, and workforce planning."
        image={Section_B}
        color="gray"
         text={
           <>
             <div className="pb-5 align-items-center d-flex gray">
               <button
                 className="cs-btn pl-0 d-flex"
                 onClick={() => sethrmShow((prev) => !prev)}
               >
                 Key Features
               </button>
             </div>
           </>
         }
      />

     {hrmshow && (
        <div className="container  mb-5 pb-5 d-lg-block anim ov-x">
          <TabComponent
            link={data.map((item) => (
              <Nav.Link eventKey={item.key}>{item.hrm_title}</Nav.Link>
            ))}
            pane={data.map((item) => (
              <Tab.Pane eventKey={item.key} key={item.link}>
                <div className="row justify-content-center align-items-center">
                  <div className="col-md-6">
                    <div className="tabs-heading">
                      <h4>{item.hrm_title}</h4>
                    </div>
                    <div className="tabs-description">
                      <p>{item.hrm_description}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="tabs-image">
                      <img src={item.image} alt="" />
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            ))}
          />
        </div>
      )} 

      {/* section 1 */}

      {/* section 2 */}

      <Global_Section_A
        title="HR Recruitment"
        description="Using Tasktak CRM software, you can automate and enhance the speed of entire recruitment process, and keep all stakeholders involved when recruiting candidates to acquire their feedback without waste of anyone’s time."
        image={Section_A}
        color="gray mt-top"
         text={
           <>
             <div className="pb-5 align-items-center d-flex gray">
               <button
                 className="cs-btn pl-0 d-flex"
                 onClick={() => setShow((prev) => !prev)}
               >
                 Key Features
               </button>
             </div>
           </>
         }
      />

       {show && (
        <div className="container  mb-5 pb-5 d-lg-block anim ov-x">
          <TabComponent
            link={data.map((item) => (
              <Nav.Link eventKey={item.key}>{item.link}</Nav.Link>
            ))}
            pane={data.map((item) => (
              <Tab.Pane eventKey={item.key} key={item.link}>
                <div className="row justify-content-center align-items-center">
                  <div className="col-md-6">
                    <div className="tabs-heading">
                      <h4>{item.link}</h4>
                    </div>
                    <div className="tabs-description">
                      <p>{item.discription}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="tabs-image">
                      <img src={item.image} alt="" />
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            ))}
          />
        </div>
      )} 

      {/* section 2 */}

      {/* section 3 */}

      <Global_Section_B
        title="Reliable employee reports "
        description="Having access to real-time data and insights of the human resource, managers can instantly identify areas where employees may not be performing or need guidance and resources to help them to become a productive resource for organization. It will help employees improve their performance and lead to better business outcomes."
        image={Section_B}
        color="gray"
         text={
           <>
             <div className="pb-5 align-items-center d-flex gray">
               <button
                 className="cs-btn pl-0 d-flex"
                 onClick={() => setrecordShow((prev) => !prev)}
               >
                 Key Features
               </button>
             </div>
           </>
         }
      />

      {recordshow && (
        <div className="container  mb-5 pb-5 d-lg-block anim ov-x">
          <TabComponent
            link={data.map((item) => (
              <Nav.Link eventKey={item.key}>{item.hr_record}</Nav.Link>
            ))}
            pane={data.map((item) => (
              <Tab.Pane eventKey={item.key} key={item.hr_record}>
                <div className="row justify-content-center align-items-center">
                  <div className="col-md-6">
                    <div className="tabs-heading">
                      <h4>{item.hr_record}</h4>
                    </div>
                    <div className="tabs-description">
                      <p>{item.hr_record_desc}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="tabs-image">
                      <img src={item.image} alt="" />
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            ))}
          />
        </div>
      )}

      {/* section 3 */}

      {/* section 4 */}

      <Global_Section_A
        title="HR Payroll"
        description="With Tasktak BMP HR Payroll, you can effortlessly generate salary slips and manage leave data, increments, bonuses, deductions, taxes and more within the CRM. Not just that, employees can view all payroll-related features and receive salary statements through the system.   "
        image={Section_C}
        color="white"
         text={
           <>
             <div className="pb-5 align-items-center d-flex gray">
               <button
                 className="cs-btn pl-0 d-flex"
                 onClick={() => setpayrollShow((prev) => !prev)}
               >
                 Key Features
               </button>
             </div>
           </>
         }
      />

       {payrollshow && (
        <div className="container  mb-5 pb-5 d-lg-block anim ov-x">
          <TabComponent
            link={data.map((item) => (
              <Nav.Link eventKey={item.key}>{item.hr_payroll}</Nav.Link>
            ))}
            pane={data.map((item) => (
              <Tab.Pane eventKey={item.key} key={item.hr_payroll}>
                <div className="row justify-content-center align-items-center">
                  <div className="col-md-6">
                    <div className="tabs-heading">
                      <h4>{item.hr_payroll}</h4>
                    </div>
                    <div className="tabs-description">
                      <p>{item.hr_payroll_desc}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="tabs-image">
                      <img src={item.image} alt="" />
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            ))}
          />
        </div>
      )} 

      {/* section 4 */}
    </>
  );
};

export default Hrm_Software;
