import React, { useState } from "react";
import GlobalMain from "./Global_Section_Main";
import GlobalA from "./Global_Section_A";
import GlobalB from "./Global_Section_B";
import Banner from "../../../assets/images/acc-banner.svg";
import Section_A from '../../../assets/images/acc-a.svg';
import Section_B from '../../../assets/images/acc-b.svg';
import Section_C from '../../../assets/images/acc-c.svg';
import Nav from "react-bootstrap/Nav";
import Lead from "../../../assets/images/lead-images.png";
import TabComponent from "./TabComponent";
import Tab from "react-bootstrap/Tab";
// import { useTranslation } from "react-i18next";

const Accounting = () => {
  const [hrmshow, sethrmShow] = useState(false);
  const data = [
    {
      image: Lead,
      link: "Dashboard",
      discription: "A visual display of key financial information, such as income, expenses, and profit/loss, that provides a quick overview of the financial health of a business.",
      key: "first",
      },
    {
      image: Lead,
      link: "Banking",
      discription: "The module in accounting software used for managing bank accounts, including deposits, withdrawals, and reconciliations.",
      key: "second",
      },
    {
      image: Lead,
      link: "Transactions",
      discription: "The record of any financial activity in an accounting system, such as sales, purchases, expenses, and payments.",
      key: "third",
    },
    {
      image: Lead,
      link: "Journal Entry",
      discription: "A manual record of a financial transaction that includes the account name, amount, and a brief description of the transaction.",
      key: "forth",
      },
    {
      image: Lead,
      link: "Transfer",
      discription: "A record of moving money from one account to another, often used for interbank or intercompany transactions.",
      key: "sixth",
      },
      {
        image: Lead,
        link: "Chart of Accounts",
        discription: "A list of all accounts used in an accounting system to record financial transactions. It usually includes assets, liabilities, equity, revenues, and expenses.",
        key: "fifth",
        },
        {
          image: Lead,
          link: "Budget",
          discription: "A financial plan that outlines expected income and expenses for a specific period.",
          key: "seventh",
          },
  ];

    // const { t } = useTranslation(["innerpages"]);
    return (
      <>
        <GlobalMain
          title={'CRM Accounting Software'}
          description={'Our accounting CRM facilitates chartered accountants, tax professionals, and auditors in keeping and managing client data together with client interactions. Using this CRM for accountants might also save your firm time and money that could be spent elsewhere.'}
          image={Banner}
        />
        <GlobalA
          title={'Monitor Customer Interactions'}
          description={'TaskTak accounting CRM software enables chartered accountants to store all communication with clients in one centralized location, making it easier to view past conversations and keeps track of any ongoing threads. This history will also be beneficial to anyone who speaks to the client next time; in order to provide more efficient service.'}
          image={Section_A}
          color="gray mt-top"
          text={
            <>
              <div className="pb-5 align-items-center d-flex gray">
                <button
                  className="cs-btn pl-0 d-flex"
                  onClick={() => sethrmShow((prev) => !prev)}
                >
                  Key Features
                </button>
              </div>
            </>
          }
        />
              {hrmshow && (
        <div className="container  mb-5 pb-5 d-lg-block anim ov-x">
          <TabComponent
            link={data.map((item) => (
              <Nav.Link eventKey={item.key}>{item.link}</Nav.Link>
            ))}
            pane={data.map((item) => (
              <Tab.Pane eventKey={item.key} key={item.link}>
                <div className="row justify-content-center align-items-center">
                  <div className="col-md-6">
                    <div className="tabs-heading">
                      <h4>{item.link}</h4>
                    </div>
                    <div className="tabs-description">
                      <p>{item.discription}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="tabs-image">
                      <img src={item.image} alt="" />
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            ))}
          />
        </div>
      )}

        <GlobalB
          title={'Prioritize your actions'}
          description={'TaskTak’s automated dashboard provides a comprehensive overview of the performance of each project and customer, showing key metrics such as revenue, expenses, and profits. By using it, firms can quickly identify profit-maximizing projects and clients.'}
          image={Section_B}
          color="gray"
        />
        <GlobalA
          title={'Capture a 360 ° view'}
          description={'TaskTak CRM gives you a single source of truth for all your team’s deals. It provides an overview of the entire sales process, from prospecting to closing, giving you a clear picture of where each deal stands. TaskTak helps you in identifying issues quickly and take the necessary action to close deals efficiently and on time.'}
          image={Section_C}
          color="white"
        />
      </>
  )
}

export default Accounting