import React from "react";
import { useTranslation } from "react-i18next";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import { Container, Row } from "react-bootstrap";
import call from "../../assets/images/home/call-log.svg";
import customer from "../../assets/images/home/customer-service.svg";
import log from "../../assets/images/home/log-file-format.svg";

const Caro = () => {
  const { t } = useTranslation(["service"]);
  // const options = {
  //   margin: 30,
  //   responsiveClass: true,
  //   nav: false,
  //   rewind: false,
  //   dots: true,
  //   lazyLoad: "true",
  //   lazyContent: "true",
  //   loop: false,
  //   autoplay: false,
  //   navText: ["Prev", "Next"],
  //   smartSpeed: 1000,
  //   responsive: {
  //     0: {
  //       items: 1,
  //     },
  //     400: {
  //       items: 1,
  //     },
  //     600: {
  //       items: 2,
  //     },
  //     700: {
  //       items: 2,
  //     },
  //     1000: {
  //       items: 2,
  //     },
  //     1200: {
  //       items: 3,
  //     },
  //   },
  // };
  return (
    <>
      <section id="Features" className="f">
        <Container>
          <Row className="align-items-center position-relative">
            <div className="col-12">
              <div className="feature-box position-relative p-5">
                <h2 className="fw-medium text-white text-center col-12 mx-auto">
                  {t("features")}
                </h2>
                <p className="text-white d-md-block medium text-center col-12 col-md-12 mx-auto ">
                  {t("task_help")}
                  <br />
                  <span className="text-orange">*</span> {t("nature")} <br />{" "}
                  <span className="text-orange">*</span> {t("interactive")}{" "}
                  <br /> <span className="text-orange">*</span>{" "}
                  {t("team_connected")}
                </p>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    <div className="container">
      <div className="mt-3 pt-0 mt-md-5 d-block d-md-flex">
        <div className="item custom-item">
          <div className="custom-box">
            <img src={customer} alt="" />
          </div>
          <div className="custom-box-description">
            <h5 className="fw-medium text-dark">منصة العملاء التفاعلية </h5>
            <p className="text-dark m-0">.مع منصة TaskTak، سيحصل عملاءك على منصة تفاعلية مع شركتك. أرسل ووقع القعود، ناقش المشاريع ، أعرض ورتب المواعيد وأكثر
            </p>
          </div>
        </div>
        <div className="item custom-item">
          <div className="custom-box">
            <img src={log} alt="" />
          </div>
          <div className="custom-box-description">
            <h5 className="fw-medium text-dark">سجل النشاطات </h5>
            <p className="text-dark m-0">
            .أحصل على ايجاز سريع لعمليات التواصل والتفاعل بين فريق عملك والعملاء المتوقعين والعملاء الفعليين. تعرف على أعضاء الفريق الذين يعملون بجد وأين الحاجة إلى إجراء تحسينات
            </p>
          </div>
        </div>
        <div className="item custom-item">
          <div className="custom-box">
            <img src={call} alt="" />
          </div>
          <div className="custom-box-description">
            <h5 className="fw-medium text-dark">سجل المكالمات </h5>
            <p className="text-dark m-0">
            .احتفظ بسجل للمكالمات الواردة والصادرة مع القدرة على بدء تذاكر الدعم لحل جميع أسئلة العملاء ومشاكلهم بكفاءة
            </p>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};
export default Caro;
