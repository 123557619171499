import React from 'react'
import icon_1 from "../../../../assets/images/webmail/icon-1.png";
import icon_2 from "../../../../assets/images/webmail/icon-2.png";
import icon_3 from "../../../../assets/images/webmail/icon-3.png";
import icon_4 from "../../../../assets/images/webmail/icon-4.png";
import icon_5 from "../../../../assets/images/webmail/icon-5.png";
import icon_6 from "../../../../assets/images/webmail/icon-6.png";
import icon_7 from "../../../../assets/images/webmail/icon-7.png";
import icon_8 from "../../../../assets/images/webmail/icon-8.png";
import icon_9 from "../../../../assets/images/webmail/icon-9.png";
import icon_10 from "../../../../assets/images/webmail/icon-10.png";
import icon_11 from "../../../../assets/images/webmail/icon-11.png";
import icon_12 from "../../../../assets/images/webmail/icon-12.png";
import icon_13 from "../../../../assets/images/webmail/icon-13.png";
import icon_14 from "../../../../assets/images/webmail/icon-14.png";
import icon_15 from "../../../../assets/images/webmail/icon-15.png";
import icon_16 from "../../../../assets/images/webmail/icon-16.png";


import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Web1 = () => {
    const options = {
        margin: 20,
        responsiveClass: true,
        nav: true,
        autoHeight: true,
        rewind: false,
        dots: false,
        lazyLoad: "true",
        lazyContent: "true",
        loop: true,
        touchDrag: true,
        mouseDrag: true,
        autoplay: true,
        navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
        smartSpeed: 1000,
        responsive: {
          0: {
            items: 1,
          },
          400: {
            items: 1,
          },
          600: {
            items: 2,
          },
          700: {
            items: 2,
          },
          1000: {
            items: 3,
          },
          1200: {
            items: 4,
            autoHeight: true,
          },
        },
      };
    const data = [
        {
          id: 1,
          icon: icon_1,
          title: "Email Accounts",
          point1: "People use email accounts for both personal and professional communications, utilized by individuals, businesses, and organizations alike.",
          divClass: "serv_1",
          link: "Get Started",
        },
    
        {
          id: 2,
          icon: icon_2,
          title: "Email Forwarders",
          point1: "It enables users to forward incoming emails from one email address to another, without having to access multiple email accounts.",
          divClass: "serv_2",
          link: "Get Started",
        },
    
        {
          id: 3,
          icon: icon_3,
          title: "Email Routing",
          point1: "It enables users to forward incoming emails from one email address to another, without having to access multiple email accounts.",
          divClass: "serv_3",
          link: "Get Started",
        },
    
        {
          id: 4,
          icon: icon_4,
          title: "Autoresponders",
          point1: "Email autoresponders are pre-written messages that are automatically sent to subscribers or customers based on specific triggers.",
          divClass: "serv_4",
          link: "Get Started",
        },
        {
          id: 5,
          icon: icon_5,
          title: "Default Address",
          point1: "It is commonly used to prevent email bounce backs and ensure that all messages sent to a particular domain are collected in one location.",
          divClass: "serv_4",
          link: "Get Started",
        },
        {
          id: 6,
          icon: icon_6,
          title: "Mailing Lists",
          point1: "A mailing list is a collection of email addresses used by individuals or businesses to send messages to multiple recipients at once.",
          divClass: "serv_4",
          link: "Get Started",
        },
        {
          id: 7,
          icon: icon_7,
          title: "Track Delivery",
          point1: "This feature provides real-time updates on the delivery status, including whether the email was successfully delivered, bounced back, or marked as spam.",
          divClass: "serv_4",
          link: "Get Started",
        },
        {
          id: 8,
          icon: icon_8,
          title: "Global Email Filters",
          point1: "Global email filters refer to a feature that enables users to set specific criteria for filtering incoming emails across an entire email system or domain.",
          divClass: "serv_4",
          link: "Get Started",
        },
        {
          id: 9,
          icon: icon_9,
          title: "Email Filters",
          point1: "It sort incoming emails based on specific criteria and move them to designated folders, helping to keep inboxes organized and improve productivity.",
          divClass: "serv_4",
          link: "Get Started",
        },
        {
          id: 10,
          icon: icon_10,
          title: "Email Deliverability",
          point1: "Email deliverability refers to the ability of an email to successfully reach its intended recipient's inbox without being filtered out by spam filters.",
          divClass: "serv_4",
          link: "Get Started",
        },
        {
          id: 11,
          icon: icon_11,
          title: "Address Importer",
          point1: "This tool allows users to import data from various file formats, including CSV, Excel, and text files, and automatically extract the relevant information.",
          divClass: "serv_4",
          link: "Get Started",
        },
        {
          id: 12,
          icon: icon_12,
          title: "Spam Filters",
          point1: "Spam filters are software applications or tools designed to identify and block unwanted or unsolicited email messages from reaching a user's inbox.",
          divClass: "serv_4",
          link: "Get Started",
        },
        {
          id: 13,
          icon: icon_13,
          title: "Encryption",
          point1: "It is the process of converting plaintext data into a coded format known as ciphertext, which can only be deciphered using a secret key or password.",
          divClass: "serv_4",
          link: "Get Started",
        },
        {
          id: 14,
          icon: icon_14,
          title: "Box Trapper",
          point1: "An email filtering tool that helps users combat spam by requiring senders to verify their identity before their email is delivered to the recipient's inbox.",
          divClass: "serv_4",
          link: "Get Started",
        },
        {
          id: 15,
          icon: icon_15,
          title: "Configure Grey Listing",
          point1: "It is a spam filtering technique that temporarily rejects incoming emails from unknown senders, requiring them to be resent before they are accepted.",
          divClass: "serv_4",
          link: "Get Started",
        },
        {
          id: 16,
          icon: icon_16,
          title: "Email Disk Usage",
          point1: "Email disk usage is an important metric to track, as it can impact the performance of the email service and the device or server on which it is hosted.",
          divClass: "serv_4",
          link: "Get Started",
        },
      ];
    return (
        <OwlCarousel className="owl-theme" {...options}>
            {data.map((item, i) => (
                <div className="item webmail">
                    <div className="save-time">
                        <div className="save-icon">
                            <div className="custom-save-img">
                                <img src={item.icon} alt="" />
                            </div>
                            <h5 className="text-center save-text pt-3">{item.title}</h5>
                            <p>{item.point1}</p>
                        </div>
                    </div>
                </div>
            ))}
        </OwlCarousel>
    )
}

export default Web1