import React, { useState } from 'react'
import { Container, Row } from "react-bootstrap";
import axios from "axios";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import Constant from "../../utils/Constant";
import circle from '../../assets/images/home/pricing-check.svg';
import arrow from '../../assets/images/home/arrow-icon.svg'
import LeadPopup1 from './Inner_components/LeadPopup1';

class NewPrice extends React.Component {
  constructor() {
    super();
    this.handlePricingList = this.handlePricingList.bind(this);
    this.callApi = this.callApi.bind(this);
    this.state = {
      isYearly: false,
      pricingPlan: {
        isLoaded: false,
        msg: "Loading...",
        list: [],

      },

    };

  }

  componentDidMount() {
    // get_product_ar
    const { t } = this.props;


    t("service_condition") == "2"
      ? this.callApi(
        "https://" + Constant.url + ".tasktak.com/Subscription/get_product"
      )
      : this.callApi(
        "https://" + Constant.url + ".tasktak.com/Subscription/get_product"
      );
  }
  componentWillReceiveProps() {
    const { t } = this.props;

    t("service_condition") == "2"
      ? this.callApi(
        "https://" + Constant.url + ".tasktak.com/Subscription/get_product"
      )
      : this.callApi(
        "https://" + Constant.url + ".tasktak.com/Subscription/get_product"
      );
  }

  randomNum = () => {
    return Math.floor(Math.random() * (4 - 1 + 1)) + 1;
  };

  radioButton = (item_id) => {
    if (item_id == "month") {
      this.setState({ isYearly: false }, function () {
        $(".lbl_mo").addClass("pricechecbox");
        $(".lbl_yr").removeClass("pricechecbox");
      });
    } else {
      this.setState({ isYearly: true }, function () {
        $(".lbl_mo").removeClass("pricechecbox");
        $(".lbl_yr").addClass("pricechecbox");
      });
    }
  };

  handlePricingList = () => {
    const { t } = this.props;
    let pricings = "";
    pricings = this.state.pricingPlan.list.map((item) =>
      item.id == 25 ? (
        <div id={"price_" + item.id} className="col-md-6 col-lg-3 offset-md-5"  key={item.id} >
          <div className="price-box rounded-3 position-relative overflow-hidden mb-4">
          
            <div className="best-seller">
                <h3 className="text-second fs-4 mb-3 text-left position-relative">
                  {item.name}
                </h3>
            </div>
            <span
              className="align-items-center d-flex justify-content-start rounded-pill mb-2 mb-0 mx-auto fw-bold  " style={{"margin-bottom": '0px'+'!important'}}
              id={"price_sec_" + item.id}
            >
              {item.price_symbol}{item.price}* <span className="month">/month</span>
            </span>

            <p className='alert-name'>Promotional price, valid until 3/31/2024. Regular price $99.99/month</p>
          

            
  
                <a
                  href={"https://" +
                    Constant.url +
                    ".tasktak.com/authentication/register?a=" +
                    item.id
                  }
                  className="text-decoration-none  text-white"
                >
                  Get it Now
                </a>


              <LeadPopup1 
              text={<>Try it now for free <img src={arrow} alt="" className="ml-3" /></>}
              Class="text-decoration-underline  free-packages"
              />


            <ul className="list-unstyled text-gray small mb-3 custom-uls">
              <li className="mb-2">
                <img src={circle} alt="" className="me-2" />
                15 Volunteers
              </li>
              <li className="mb-2">
                <img src="" alt="" />
                <img src={circle} alt="" className="me-2" />
                2 Users
              </li>
              <li className="mb-2">
                <img src={circle} alt="" className="me-2" />
                Unlimited Leads
              </li>
              <li className="mb-2">
                <img src={circle} alt="" className="me-2" />
                Unlimited Meeting
              </li>
              <li className="mb-2">
                <img src={circle} alt="" className="me-2" />
                Unlimited Support
              </li>
            </ul>

            <ul className="list-unstyled text-gray small mb-3 custom-uls">
              <li className="mb-2">
                <img src={circle} alt="" className="me-2" />
                Unlimited Donors
              </li>
              <li className="mb-2">
                <img src="" alt="" />
                <img src={circle} alt="" className="me-2" />
                Unlimited Events
              </li>
              <li className="mb-2">
                <img src={circle} alt="" className="me-2" />
                Unlimited Email
              </li>
              <li className="mb-2">
                <img src={circle} alt="" className="me-2" />
                Unlimited Project
              </li>
              <li className="mb-2">
                <img src={circle} alt="" className="me-2" />
                Unlimited Tasks
              </li>
            </ul>
            
          </div>
        </div>

      ) : (
        ""
      )
    );
    return pricings;
  };

  handlePricingListtt = () => {
    const { t } = this.props;
    let pricingss = "";
    pricingss = this.state.pricingPlan.list.map((item) =>
    item.id == 26 ? (
      <div id={"price_" + item.id} className="col-md-6 col-lg-3 offset-md-5"  key={item.id} >
        <div className="price-box rounded-3 position-relative overflow-hidden mb-4">
        
          <div className="best-seller">
              <h3 className="text-second fs-4 mb-3 text-left position-relative">
                {item.name}
              </h3>
          </div>
          <span
            className="align-items-center d-flex justify-content-start rounded-pill mb-2 mb-0 mx-auto fw-bold  " style={{"margin-bottom": '0px'+'!important'}}
            id={"price_sec_" + item.id}
          >
            {item.price_symbol}{item.price}* <span className="month">/year</span>
          </span>


          <p className='alert-name'>Promotional price, valid until 3/31/2024. Regular price $1,079/year</p>
        

          

              <a
                href={"https://" +
                  Constant.url +
                  ".tasktak.com/authentication/register?a=" +
                  item.id
                }
                className="text-decoration-none  text-white"
              >
                Get it Now
              </a>


            <LeadPopup1 
            text={<>Try it now for free <img src={arrow} alt="" className="ml-3" /></>}
            Class="text-decoration-underline  free-packages"
            />


          <ul className="list-unstyled text-gray small mb-3 custom-uls">
            <li className="mb-2">
              <img src={circle} alt="" className="me-2" />
              15 Volunteers
            </li>
            <li className="mb-2">
              <img src="" alt="" />
              <img src={circle} alt="" className="me-2" />
              2 Users
            </li>
            <li className="mb-2">
              <img src={circle} alt="" className="me-2" />
              Unlimited Leads
            </li>
            <li className="mb-2">
              <img src={circle} alt="" className="me-2" />
              Unlimited Meeting
            </li>
            <li className="mb-2">
              <img src={circle} alt="" className="me-2" />
              Unlimited Support
            </li>
          </ul>

          <ul className="list-unstyled text-gray small mb-3 custom-uls">
            <li className="mb-2">
              <img src={circle} alt="" className="me-2" />
              Unlimited Donors
            </li>
            <li className="mb-2">
              <img src="" alt="" />
              <img src={circle} alt="" className="me-2" />
              Unlimited Events
            </li>
            <li className="mb-2">
              <img src={circle} alt="" className="me-2" />
              Unlimited Email
            </li>
            <li className="mb-2">
              <img src={circle} alt="" className="me-2" />
              Unlimited Project
            </li>
            <li className="mb-2">
              <img src={circle} alt="" className="me-2" />
              Unlimited Tasks
            </li>
          </ul>
          
        </div>
      </div>

    ) : (
      ""
    )
    );
    return pricingss;
  };

  callApi = (urll) => {
    let classRef = this;
    axios({
      method: "GET",
      url: urll,
    }).then(function (result) {
      console.log(result.data.data)
      classRef.setState({
        pricingPlan: {
          isLoaded: true,
          list: result.data.data,
        },
      });
    });
  };

  render() {
    const { t } = this.props;
    return (
      <section id="npo" className="pt-0">
        <Container>
          <Row>
            <div className="col-12 col-md-12 mx-auto text-center mt-5 pt-4">
              <h2 className="fw-medium">Enhance your team's collaboration and communication<br /> with a well-tailored workflow plan!</h2>
              <div className="check-tt">
                <ul>
                  <li><img src={circle} alt="" /> Switch Plans Anytime</li>
                  <li><img src={circle} alt="" /> No Credit Card Required</li>
                  <li><img src={circle} alt="" /> Cancel Anytime</li>

                </ul>
              </div>

            </div>

            <div className="clearfix"></div>
            <div className="col-lg-12 col-xl-12 mx-auto mt-5">
              <Row>
                <div className="tt_pricing">
                  <fieldset id={"group"} className="group2">
                    <input
                      type="radio"
                      onClick={(e) => this.radioButton("month", e)}
                      id={"mont"}
                      value={"month"}
                      name={"group"}
                    />
                    <label className="lbl_mo pricechecbox" for={"mont"}>
                      Pay Monthly <br />
                      <span>Commit monthly</span>
                    </label>
                    <input
                      type="radio"
                      onClick={(e) => this.radioButton("year", e)}
                      id={"year"}
                      value={"year"}
                      name={"group"}
                    />
                    <label className="lbl_yr" for={"year"}>
                      Pay Yearly save <span className="lbl__yr">10%</span>
                      <br />
                      <span>Commit yearly</span>
                    </label>
                  </fieldset>
                </div>
                {this.state.pricingPlan.isLoaded === true &&
                  this.state.isYearly == false ? (
                  <div id="monthly-packages" className="rows">
                    {this.handlePricingList()}
                  </div>
                ) : this.state.pricingPlan.isLoaded === true &&
                  this.state.isYearly == true ? (
                  <div id="yearly-packages" className="rows">
                    {this.handlePricingListtt()}
                  </div>
                ) : (
                  ""
                )}
              </Row>
            </div>
          </Row>
        </Container>
      </section>
    );
  }
}
export default withTranslation("pricing")(NewPrice);