import React, { useState } from 'react'
import GlobalMain from "./Global_Section_Main";
import GlobalA from "./NpoGlobal";
import Banner from "../../../assets/images/home/ngo-banner.svg";
import Section_A from '../../../assets/images/home/npo-a.svg';
import Section_B from '../../../assets/images/home/npo-b.svg';
// import { useTranslation } from "react-i18next";
import Work from "./Sections/Work";
import Screens from "../Screens";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import NgoGlob from "./NgoGlob";
import img from '../../../assets/images/home/list-dot.svg'
import BannerS from "../../../assets/images/project-banner.svg";
import BannerT from "../../../assets/images/task-banner.svg";
import BannerC from "../../../assets/images/coll-banner.svg";
import BannerAc from "../../../assets/images/acc-banner.svg";
import BannerH from "../../../assets/images/webmail/support-new.svg";
import BannerHi from "../../../assets/images/webmail/healp-hand.jpg";
import img1 from "../../../assets/images/home/icon-a.svg";
import img2 from "../../../assets/images/home/icon-b.svg";
import img3 from "../../../assets/images/home/icon-c.svg";
import img4 from "../../../assets/images/home/icon-d.svg";
import img5 from "../../../assets/images/home/icon-e.svg";
import Slide from '@mui/material/Slide';
import Nav from "react-bootstrap/Nav";
import Lead from "../../../assets/images/lead-images.png";
import TabComponent from "./TabComponent";
import Tab from "react-bootstrap/Tab";
import NewPrice from '../NewPrice';
import LeadPopup from './LeadPopup';
import Accordions from '../Accordions';
import Reviews from '../Reviews';
import AboutLogo from '../AboutLogo';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const data = [
    {
      image: BannerS,
      link: "Dashboard",
      discription: "A visual display of key financial information, such as income, expenses, and profit/loss, that provides a quick overview of the financial health of a business.",
      key: "first",
      },
    {
      image: BannerS,
      link: "Donors",
      discription: "The module in accounting software used for managing bank accounts, including deposits, withdrawals, and reconciliations.",
      key: "second",
      },
    {
      image: BannerS,
      link: "Donors List",
      discription: "The record of any financial activity in an accounting system, such as sales, purchases, expenses, and payments.",
      key: "third",
    },
    {
      image: BannerS,
      link: "Donation Order",
      discription: "A manual record of a financial transaction that includes the account name, amount, and a brief description of the transaction.",
      key: "forth",
      },
    {
      image: BannerS,
      link: "Donation Report",
      discription: "A record of moving money from one account to another, often used for interbank or intercompany transactions.",
      key: "sixth",
      },
      {
        image: BannerS,
        link: "Donation Report",
        discription: "A list of all accounts used in an accounting system to record financial transactions. It usually includes assets, liabilities, equity, revenues, and expenses.",
        key: "fifth",
        },
        {
          image: BannerS,
          link: "Settings",
          discription: "A financial plan that outlines expected income and expenses for a specific period.",
          key: "seventh",
          },
  ];

  const datae = [
    {
      title: "Make the most out of your donations by eliminating processing fees.",
      image: img2,
    },
    {
      title: "Capture donor information and send invoicing in real time.",
      image: img1,
    },
    {
      title: "Ready to go reports to keep you on your mission.",
      image: img3,
    },
    {
      title: "Easy tracking of disbursement of funds and expenses.",
      image: img4,
    },
  ];

const Npo = () => {
    // const { t } = useTranslation(["innerpages"]);
    const options = {
      margin: 30,
      responsiveClass: true,
      nav: true,
      dots: true,
      loop: true,
      autoplay: false,
      navText: ["<i class='bi bi-arrow-left fs-3'></i>", "<i class='bi bi-arrow-right fs-3'></i>"],
      smartSpeed: 500,
      responsive: {
          0: {
              items: 1,
              stagePadding: 0,
          },
          400: {
              items: 1,
              stagePadding: 0,
          },
          600: {
              items: 1,
              stagePadding: 0,
          },
          700: {
              items: 1,
              stagePadding: 0,
          },
          1199: {
              items: 1,

          }
      },
  };
  const optionsss = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    rewind: false,
    dots: false,
    lazyLoad: "true",
    lazyContent: "true",
    loop: false,
    autoplay: false,
    navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      600: {
        items: 3,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 4,
      },
      1200: {
        items: 4,
      },
    },
  };
    return (
      <>
        <div className='oil-section npo banner'>
            <div className='container'>
                <div className='oil-wrapper'>
                    <div className='npo-main d-flex justify-content-center align-items-center flex-column'>
                        <h1 class="inner-title fw-bold display-4 mb-3 aos-init aos-animate">TaskTak Non-Profit Solution (NPS) Platform</h1>
                        <p>With TaskTak NPS Donations, your donors have an easy-to-use donation process that is linked directly to your Business Management Platform (NPS).  Remove the hassle of sending end of year reports and easily see the donation frequency your donors have selected so you can plan accordingly.</p>
                  
                        <div className="container">
                        
                          <div className=" pt-0 mb-md-5 row">
                            <OwlCarousel className="owl-theme" {...optionsss}>
                              {datae.map((items, i) => (
                                <div className="item m-npo-item">
                                  <div className="save-time">
                                    <div className="save-icon">
                                      <img src={items.image} alt="" />
                                      <p className="text-center save-text pt-3">{items.title}</p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </OwlCarousel>
                          </div>
                        </div>
                
                        <div className='btn-group'>
                        <a style={{color: '#fff'}} href="#npo" class="col-md-auto col-5 btn btn-yellow border-0 rounded-pill fs-5 lh-23 px-4 py-2 me-2 me-md-4 inner-btn-ngo">Let's Get Started</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        


        <GlobalA
          title={'Easy to Use Donor Portal'}
          description={<><b>TaskTak's NPS Donation</b> allows your donors to donate one time or set up regular donations (daily/monthly/yearly) from anywhere in the world. <br/><br/>Your donors can login to their dedicated donor portal to increase, decrease, or cancel their donation, while having access to their donation history without any hassle. <br/><br/>Use the built in chat support tool to answer any donor questions so donations don't get stuck in midair.</>}
          image={BannerHi}
          npo={'Experience our interactive demo and get a personalized quote based on your business needs for our easy-to-use software.'}
        />
        
        <Screens />
        {/* <div className="container  mb-5 pb-5 d-lg-block anim ov-x">
          <TabComponent
            link={data.map((item) => (
              <Nav.Link eventKey={item.key}>{item.link}</Nav.Link>
            ))}
            pane={data.map((item) => (
              <Tab.Pane eventKey={item.key} key={item.link}>
                <div className="row justify-content-center align-items-center">
                  <div className="col-md-6">
                    <div className="tabs-heading">
                      <h4>{item.link}</h4>
                    </div>
                    <div className="tabs-description">
                      <p>{item.discription}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="tabs-image">
                    <img src='assets/images/s-1.png?v=3' alt='img' />
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            ))}
          />
        </div> */}
        
        <GlobalA
          title={''}
          description={'The goal of a non-profit is to spend money where it really matters. Start using TaskTak\'s NPS today to help increase efficiency and decrease the extra expenses.'}
          image={Section_A}
          color="mt-top"
          npoTitle={'One Stop Solution for Non-Profit Organizations'}
          npoClasses="npo-inner-section"
          npoDesc={<>We don't just help you real-in those donations you can also utilize TaskTak's NPS to manage those daily tasks and overall project milestones, with a plethora of communication tools at your disposal to help your non-profit team run smoothly. <br/> You no longer need to jump around to different systems trying to manage your non-profit. With TaskTak’s cloud-based system with a centralized and integrated platform you can manage all your operations from a single platform from any location, thereby streamlining processes, improving efficiency, and reducing the need for manual data entry.</>}
        />


        <div className="section-carousel">
            <div className="container">
              <div className='mt-5'>
            <h2 className="text-second fs-4 mb-3 position-relative h2 text-center">TaskTak NPS - All-In-One Platform for Business Growth</h2>
              <p className="text-center min-width-ps">Unlock the power of TaskTak NPS, where you can access a wide range of user friendly tools and features under a single monthly subscription. Experience unparalleled performance as you leverage the benefits of all the tools to propel your business to new heights.</p>
              </div>
            <OwlCarousel className='owl-theme screen mt-5 pt-5 npo-ct-cc' {...options}>
                    <div className='item'>
                        <NgoGlob 
                        title={"Project Management"}
                        description={'TaskTak provides comprehensive features and tools to help fundraising teams streamline their processes, manage tasks, and drive successful fundraising campaigns.'}
                        text={<>
                          <li><img src={img} alt="" />	Task and project tracking for fundraising campaigns</li>
                          <li><img src={img} alt="" />	Team collaboration and communication tools</li>
                          <li><img src={img} alt="" />	Donor management and tracking features</li>
                          <li><img src={img} alt="" />	Budgeting and expense tracking capabilities</li>
                          <li><img src={img} alt="" />	Document and file sharing for fundraising materials</li>
                          <li><img src={img} alt="" />	Reporting and analytics for campaign performance</li>
                          <li><img src={img} alt="" />	Task assignment and deadline management</li>
                          <li><img src={img} alt="" />	Integration with fundraising platforms and payment gateways</li>
                          <li><img src={img} alt="" />	Customizable workflows and templates for fundraising processes</li>
                          <li><img src={img} alt="" />	Mobile accessibility for on-the-go fundraising management.</li>
                        
                        </>}
                        image={BannerS}
                        />
                    </div>

                    <div className='item'>
                        <NgoGlob 
                        title={"Task Management"}
                        description={'With effective task management, fundraising teams can streamline their activities, ensure timely execution of tasks, and maximize their impact.'}
                        text={<>
                          <li><img src={img} alt="" />	Efficiently organize fundraising tasks and activities</li>
                          <li><img src={img} alt="" />	Assign tasks to team members responsible for specific fundraising initiatives</li>
                          <li><img src={img} alt="" />	Set deadlines and track task progress to ensure timely completion</li>
                          <li><img src={img} alt="" />	Streamline communication and collaboration among team members involved in fundraising efforts</li>
                          <li><img src={img} alt="" />	Prioritize tasks based on urgency and importance to maximize fundraising outcomes</li>
                          <li><img src={img} alt="" />	Easily monitor and evaluate fundraising tasks and performance</li>
                          <li><img src={img} alt="" />	Generate reports and analytics to assess the effectiveness of fundraising strategies</li>
                          <li><img src={img} alt="" />	Increase efficiency and productivity in managing fundraising projects</li>
                          <li><img src={img} alt="" />	Improve coordination and alignment among team members working on fundraising campaigns</li>
                          <li><img src={img} alt="" />	Enhance overall fundraising success by effectively managing and tracking tasks.</li>
                        
                        </>}
                        image={BannerT}
                        />
                    </div>

                    <div className='item'>
                        <NgoGlob 
                        title={"Team Collaboration"}
                        description={'A powerful team collaboration software designed specifically for fundraising organizations, providing the tools and features you need to streamline teamwork, enhance communication, and drive successful fundraising campaigns.'}
                        text={<>
                          <li><img src={img} alt="" />	Seamless communication: Foster collaboration and streamline communication among team members working on fundraising initiatives.</li>
                          <li><img src={img} alt="" />	Task assignment and tracking: Assign tasks to team members, set deadlines, and track progress to ensure accountability and efficient workflow.</li>
                          <li><img src={img} alt="" />	Document sharing: Share fundraising materials, documents, and resources easily within the team for efficient collaboration.</li>
                          <li><img src={img} alt="" />	Centralized information: Store and access donor information, campaign details, and progress updates in a centralized database for easy reference.</li>
                          <li><img src={img} alt="" />	Real-time collaboration: Work together in real-time, enabling instant updates and feedback for quicker decision-making.</li>
                          <li><img src={img} alt="" />	Calendar management: Schedule meetings, events, and deadlines to keep the team organized and on track.</li>
                          <li><img src={img} alt="" />	Reporting and analytics: Generate reports to track fundraising progress, donor engagement, and measure campaign success.</li>
                          <li><img src={img} alt="" />	Integration capabilities: Integrate with other fundraising tools and platforms to streamline workflows and enhance efficiency.</li>
                        </>}
                        image={BannerC}
                        />
                    </div>

                    <div className='item'>
                        <NgoGlob 
                        title={"Accounting Software"}
                        description={'Accounting software helps nonprofits comply with financial regulations and maintain transparency by providing features for tracking expenses, managing budgets, and generating financial statements.'}
                        text={<>
                          <li><img src={img} alt="" />	Streamline financial management for fundraising activities</li>
                          <li><img src={img} alt="" />	Track and manage donations, grants, and funding sources</li>
                          <li><img src={img} alt="" />	Generate accurate financial reports and statements</li>
                          <li><img src={img} alt="" />	Simplify budgeting and expense tracking for fundraising campaigns</li>
                          <li><img src={img} alt="" />	Manage donor information and communication effectively</li>
                          <li><img src={img} alt="" />	Ensure compliance with financial regulations and reporting requirements</li>
                          <li><img src={img} alt="" />	Automate invoicing and payment processes for efficient transactions</li>
                          <li><img src={img} alt="" />	Integrate with fundraising platforms and CRM systems for seamless data flow</li>
                          <li><img src={img} alt="" />	Improve transparency and accountability in financial operations</li>
                          <li><img src={img} alt="" />	Enhance financial decision-making for fundraising initiatives.</li>
                        
                        </>}
                        image={BannerAc}
                        />
                    </div>

                    <div className='item'>
                        <NgoGlob 
                        title={"Support"}
                        description={'Our support software is created with specialized system that nonprofits and organizations use to manage and provide efficient support to their donors. This software is designed to streamline donor communication, track inquiries, and resolve issues promptly, ensuring a positive and seamless donor experience. Some of its key features are as under:'}
                        text={<>
                          <li><img src={img} alt="" />	Ticketing System</li>
                          <li><img src={img} alt="" />	Donor Feedback Collection</li>
                          <li><img src={img} alt="" />	Multi-Channel Support</li>
                          <li><img src={img} alt="" />	Internal Collaboration</li>
                          <li><img src={img} alt="" />	Donor Database Integration</li>
                          <li><img src={img} alt="" />	Email Integration</li>
                          <li><img src={img} alt="" />	Categorization and Prioritization</li>
                          <li><img src={img} alt="" />	Automation and Templates</li>
                          <li><img src={img} alt="" />	Analytics and Reporting</li>
                          <li><img src={img} alt="" />	Security and Data Privacy</li>
                        
                        </>}
                        image={BannerH}
                        />
                    </div>
                    
            </OwlCarousel>
            
            </div>
        </div>


        
        {/* <GlobalA
          title={'Coming Soon'}
          image={Section_B}
          color="mt-top"
          npoTitle={'FEATURES FOR Non-profit Organizations'}
          npoClasses="npo-inner-section"
          npoDesc={'NPS recruitment management platform is designed to help businesses streamline their recruitment processes, reduce manual effort, and improve overall efficiency in the hiring process.'}
          ngo1={'Identify and understand customer segments (stakeholders).'}
          ngo2={'Articulate the value proposition (mission and benefits).'}
          ngo3={'Map and optimize channels for engagement with stakeholders.'}
          ngo4={'Identify and understand sources of income (revenue streams).'}
          ngo5={'Identify key partners and activities.'}
          ngo6={'NPS provides automatic donors management and also manage clients individual portals.'}
          npoLast={'By using NPS, Non-profit organization can develop a clear and comprehensive understanding of their business model and identify areas for improvement. This can help organizations to be more effective, efficient, and sustainable in their operations.'}
        /> */}
        <Work />
        <Accordions />
        <Reviews/>
      </>
    )
}

export default Npo