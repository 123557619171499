import React, { useState, useEffect,useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import Constant from "../../utils/Constant";
import FooterTop from "./FooterTop";
import $ from "jquery";

const Footer = () => {


  const data = [
    {
      title: "Assets Management",
      link: "/asset-management-crm-software",
    },
    {
      title: "Accounting Software",
      link: "/crm-accounting-software",
    },

    {
      title: "CRM Software",
      link: "/crm-software",
    },

    {
      title: "Business to Business",
      link: "/crm-for-b2b",
    },

    {
      title: "Marketing Software",
      link: "/crm-marketing-software",
    },
    {
      title: "Collaboration",
      link: "/collaborative-crm-software",
    },
    {
      title: "Cloud Based",
      link: "/cloud-based-crm-software",
    },
    {
      title: "Spreadsheet",
      link: "/crm-spreadsheet-template",
    },
    {
      title: "ERP",
      link: "/erp-crm-software",
    },
    {
      title: "Hrm Software",
      link: "/hr-crm-software",
    },
    {
      title: "Inventory Software",
      link: "/crm-inventory-management-software",
    },

    {
      title: "Lead Management",
      link: "/lead-management",
    },
    {
      title: "Project Management",
      link: "/project-management-crm-software",
    },
    {
      title: "Saas Software",
      link: "/crm-saas-software",
    },
    {
      title: "Task Management",
      link: "/crm-task-management",
    },
    {
      title: "Help Desk",
      link: "/crm-and-helpdesk-software",
    },
    {
      title: "Sales & Marketing",
      link: "/crm-software-for-sales-and-marketing",
    },
  ];

  const { t } = useTranslation(["accordion"]);
  const today = new Date();
  const year = today.getFullYear();

  const [show, setShow] = useState(false);
 


  return (
    <>
      <FooterTop />
      <footer className="containers task-footer footer-style-1 bg-color-white overflow-hidden">
        <div className="copyright-area copyright-style-1 ptb--20">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <div className="footer-bar">
                <span
                  
                  className="task-btns"
                  id="move-footer"
                >
                  <i class="fa fa-bars"></i> Menu
                </span>
              </div>
              <div className="footer-bar">
                <p className="task-link-hover text-center text-lg-start">
                  Copyright © {year} <a href="/">TaskTak Technologies Inc</a>. All Rights
                  Reserved
                </p>
              </div>
              <div className="footer-bar">
                <ul className="copyright-link task-link-hover justify-content-center justify-content-lg-end mt_sm--10 mt_md--10">
                  <li>
                    <a href="/terms-and-condition">Terms of service</a>
                  </li>
                  <li>
                    <a href="/privacy-policy">Privacy policy</a>
                  </li>
                  <li className="li-subs">
                    <a href="/pricing" className="f-subs">
                      Subscription
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
            <div
              className=" d-none anim ov-x section-footer-top"
              id="section-footer"
            >
              <div className="footer-top p-0">
                <div className="container">
                  <div className="row g-5">
                    <div className="col-lg-3 col-md-6 col-sm-6 col-6 f-col-order">
                      <div className="footer-widget">
                        <h5 className="ft-title">TaskTak</h5>
                        <ul className="ft-link">
                          <li>
                            <a href="/">Home</a>
                          </li>
                          <li>
                            <a href="/about-us">Who We Are</a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                      <div className="footer-widget">
                        <h5 className="ft-title">Services</h5>
                        <ul className="ft-link">
                          {data.map((item, i) =>
                            i < 9 ? (
                              <li key={item.link}>
                                <a href={item.link}>
                                  {item.title}
                                </a>
                              </li>
                            ) : (
                              ""
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                      <div className="footer-widget">
                        <h5 className="ft-title ft-hide-custom">Services</h5>
                        <ul className="ft-link">
                          {data.map((item, i) =>
                            i >= 9 ? (
                              <li key={item.link}>
                                <a href={item.link}>
                                  {item.title}
                                </a>
                              </li>
                            ) : (
                              ""
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                      <div className="footer-widget">
                        <h5 className="ft-title">Customer Services</h5>
                        <ul className="ft-link">
                          <li>
                            <a href="contact">Contact us</a>
                          </li>
                          <li>
                            <a
                              href={
                                "https://" +
                                Constant.url +
                                ".tasktak.com/authentication/register"
                              }
                            >
                              Free Sign up
                            </a>
                          </li>
                          <li>
                            <a
                              href={
                                "https://" +
                                Constant.url +
                                ".tasktak.com/knowledge-base"
                              }
                            >
                              Knowledge Base
                            </a>
                          </li>
                        </ul>
                        <h5 className="ft-title">Add-Ons</h5>
                        <ul className="ft-link">
                          <li>
                            <a href="/digital-marketing-crm" >Digital Marketing</a>
                          </li>
                          <li>
                          <a href="/social-media-crm-software">Social Media</a>
                          </li>
                          <li>
                          <a href="/crm-email-marketing">Email Marketing</a>
                          </li>
                          <li className="nowwrap">
                          <a href="/crm-customer-support-software" id="myDiv">TaskTak Customer Support Board</a>
                          </li>
                          <li>
                          <a href="/email-management-software">Email Management Software</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </footer>

    
    </>
  );
};

export default Footer;
