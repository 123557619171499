import React from "react";
import Global_Section_Main from "./Global_Section_Main";
import Global_Section_A from "./Global_Section_A";
import Global_Section_B from "./Global_Section_B";
import Banner from "../../../assets/images/support-banner.svg";
import Section_A from "../../../assets/images/support-a.svg";
import Section_B from "../../../assets/images/support-b.svg";
import Section_C from "../../../assets/images/support-c.svg";
import { useTranslation } from "react-i18next";

const CustomerSupport = () => {
    const { t } = useTranslation(["innerpages"]);
    return (
      <>

        <Global_Section_Main
          title={'CRM Customer Support Software'}
          description={'By having centralized customer data, customer service agents can quickly access the information they need to answer customer inquiries. Automated services can also help answer common questions quickly and easily, freeing up agents to focus on more complex issues. It results in improved customer service, increased customer satisfaction, and higher customer lifetime value—all of which are beneficial to the business.'}
          image={Banner}
        />
        <Global_Section_A
          title={'Unlimited everything'}
          description={'Is your business growing, and do you need an online ticket system that can keep up? We\'ve got you covered with unlimited tickets, users, and agents. TaskTak is designed to help businesses maximize their customer service efficiency. Its intuitive interface allows you to easily assign tickets to teams, and enable you to track customer inquiries from start to finish.'}
          image={Section_A}
          color="gray mt-top"
        />
        <Global_Section_B
          title={'Email handling is made simple!'}
          description={'TaskTak\'s intuitive dashboard; allows you to see all your business emails at a glance, quickly respond to customer inquiries, and easily organize your emails into folders and labels. Additionally, our advanced features let you set customized templates and monitor your email performance.'}
          image={Section_B}
          color="gray"
        />
        <Global_Section_A
          title={'Increase sales and conversions!'}
          description={'It is common for customers to perceive your company in a positive light after having a good experience with your service team. Referrals from satisfied customers can help you expand your client base. Sales teams can have more opportunities to facilitate upselling and cross-selling this way. With TaskTak’s web-based support software, you can take advantage of all these opportunities.'}
          image={Section_C}
          color="white"
        />
      </>
  )
}

export default CustomerSupport