import React from "react";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { useLocation } from "react-router-dom";

const TabComponent = ({link , key ,pane}) => {
  const { pathname } = useLocation();
  return (
 
    <section id="tabss">
      <div className="container">
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <Row>
        <Col sm={12}>
          <Nav variant="pills" className="flex-column">
            <Nav.Item>
              {link}
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={12}>
          <Tab.Content>     
          
                {pane}
         
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
    {pathname === "/npo" ? (
    <div className="tabs-footer">
      <div className="tabs-footer-inner">
            <a href="#npo" >Let's Get Started</a>
      </div>
    </div>) : (
    <div className="tabs-footer">
      <div className="tabs-footer-inner">
            <a href="/pricing">Let's Get Started</a>
      </div>
    </div>) }
      </div>
    </section>
 
  
  );
};

export default TabComponent;
