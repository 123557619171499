import React from 'react'
import { useTranslation } from "react-i18next";
import arrow from '../../assets/images/home/arrow-icon.svg'
import { Container, Row } from 'react-bootstrap';
import Constant from "../../utils/Constant";


const Addons = () => {
    const { t } = useTranslation(["home"]);
    const datas = [
        {
            id: 1,
            title: "Digital Marketing",
            price: "$119.99",
            point1: "User (Limited by storage space for the corresponding subscription)",
            month: "month",
            divClass: "serv_1",
            link: "Read More",
            links: "/digital-marketing-crm",
        },

        {
            id: 2,
            title: "Social Media",
            price: "$59.99",
            point1: "User (Limited by storage space for the corresponding subscription)",
            month: "month",
            divClass: "serv_1",
            link: "Read More",
            links: "/social-media-crm-software",
        },

        {
            id: 3,
            title: "Bulk Email Marketing",
            price: "$14.99",
            point1: "User Emails allowed upto 5000 emails / month, with a daily limit of 2000 Campaigns limited to 10)",
            month: "month",
            divClass: "serv_1",
            link: "Read More",
            links: "/crm-email-marketing",
        },
        {
            id: 4,
            title: "Support Board",
            price: "$19.99",
            point1: "User (Limited by storage space for the corresponding subscription)",
            month: "month",
            divClass: "serv_1",
            link: "Read More",
            links: "/crm-customer-support-software",
        },
        {
            id: 5,
            title: "Email Management",
            price: "$4.00",
            point1: "User (Limited by storage space for the corresponding subscription)",
            month: "month",
            divClass: "serv_1",
            link: "Read More",
            links: "/email-management-software",
        },
    ];
    return (
        <section className='overflow-hidden position-relative mt-5 addons-bg'>
            <div className='col-10 col-md-7 mb-5 mx-auto text-center'>
                <h5 className='fw-medium addons-title'>Add-Ons</h5>
                <p>Boost your business process management capabilities with our suite of powerful<br/> software add-ons.</p>
            </div>

            <Container className="px-5 px-md-3">

                <Row>
                    {datas.map(item => (
                        <div id={"add" + item.id} className="col-md-6 col-lg-2 custom-col-width">
                            <div className='addon-box rounded-3 position-relative overflow-hidden mb-4 mt-5'>
                                <h3 className=" fs-4 mb-3 position-relative">{item.title}</h3>
                                
                                <span className='align-items-center d-flex'>
                                    <span className='addons-price'>{item.price}</span>
                                    <span className="month-addons">/{item.month}</span>
                                </span>

                                <span className='addons-line text-center'><p>{item.point1}</p></span>
                                <a
                                    href={item.links}
                                    className="text-decoration-underline  free-packages free-addons"
                                    >
                                    {item.link}<img src={arrow} alt="" className="ml-3" />
                                </a>
                            </div>
                        </div>

                    ))
                    }

                </Row>
            </Container>
        </section>

    )
}

export default Addons;