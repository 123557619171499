import React, { useState } from "react";
import Global_Section_Main from "./Global_Section_Main";
import Global_Section_A from "./Global_Section_A";
import Global_Section_B from "./Global_Section_B";
import Banner from "../../../assets/images/in-banner.svg";
import Section_A from "../../../assets/images/in-a.svg";
import Section_B from "../../../assets/images/in-b.svg";
import Section_C from "../../../assets/images/in-c.svg";
import { useTranslation } from "react-i18next";
import Nav from "react-bootstrap/Nav";
import Lead from "../../../assets/images/lead-images.png";
import TabComponent from "./TabComponent";
import Tab from "react-bootstrap/Tab";

const Inventory = () => {
  const [hrmshow, sethrmShow] = useState(false);
  const data = [
    {
      image: Lead,
      link: "Items",
      discription: "This section contains the commodity list for all the items available in the stock.",
      key: "first",
      },
    {
      image: Lead,
      link: "Inventory Receiving Voucher",
      discription: "This section contains all the vouchers for the received items.",
      key: "second",
      },
    {
      image: Lead,
      link: "Inventory Delivery Voucher",
      discription: "This section contains all the vouchers for the delivered items.",
      key: "third",
    },
    {
      image: Lead,
      link: "Packing Lists",
      discription: "This section is for the packing list management including the items that has been shipped.",
      key: "forth",
      },
    {
      image: Lead,
      link: "Loss & Adjustment",
      discription: "Items that have been lost, can be mentioned in this section, user can made adjustment for the lost items including any damaged, expired or stock discrepancies.",
      key: "sixth",
      },
      {
        image: Lead,
        link: "Warehouse",
        discription: "This feature enables the user to manage the inventory location(s) and the physical organization of the inventory in the warehouse.",
        key: "fifth",
        },
        {
          image: Lead,
          link: "Inventory History",
          discription: "This feature allows the user to view the complete history of each inventory item, including receiving and delivery dates, location, and any changes in quantity.",
          key: "seventh",
          },
  ];
    const { t } = useTranslation(["innerpages"]);
    return (
      <>
        <Global_Section_Main
          title={'CRM Inventory Management Software'}
          description={'TaskTak CRM has a comprehensive inventory and sales management system that allows businesses to keep track of their products and orders in real-time. The software provides real-time data and analytics, which help businesses make more informed and better decisions regarding stock levels, pricing, and promotions.'}
          image={Banner}
        />
        <Global_Section_A
          title={'Top-notch inventory management'}
          description={'Inventory management is a time-consuming job for many small-medium-sized enterprises. However, TaskTak helps you to properly manage your stock, so you always know what you have and what you need. This allows you to guarantee that you always have the goods you require without wasting time or money.'}
          image={Section_A}
          color="gray mt-top"
          text={
            <>
              <div className="pb-5 align-items-center d-flex gray">
                <button
                  className="cs-btn pl-0 d-flex"
                  onClick={() => sethrmShow((prev) => !prev)}
                >
                  Key Features
                </button>
              </div>
            </>
          }
        />
      {hrmshow && (
        <div className="container  mb-5 pb-5 d-lg-block anim ov-x">
          <TabComponent
            link={data.map((item) => (
              <Nav.Link eventKey={item.key}>{item.link}</Nav.Link>
            ))}
            pane={data.map((item) => (
              <Tab.Pane eventKey={item.key} key={item.link}>
                <div className="row justify-content-center align-items-center">
                  <div className="col-md-6">
                    <div className="tabs-heading">
                      <h4>{item.link}</h4>
                    </div>
                    <div className="tabs-description">
                      <p>{item.discription}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="tabs-image">
                      <img src={item.image} alt="" />
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            ))}
          />
        </div>
      )}
        <Global_Section_B
          title={'Stay on top of everything!'}
          description={'The TaskTak app allows you to gather supplier information in one place. It makes restocking easier and faster and removes the need to browse through many records to find the right supplier. It can lead to improved stock management, increased sales, and higher profits.'}
          image={Section_B}
          color="gray"
        />
        <Global_Section_A
          title={'Keep an eye out for backorders!'}
          description={'Our low-stock alerts will notify you when it is time to restock inventory. Once the inventory comes, we ensure that you pay your supplier on time. It helps to guarantee that your company never runs out of stock, your customers do not have to wait for the things they want, and your supplier is getting paid on time.'}
          image={Section_C}
          color="white"
        />
      </>
  )
}

export default Inventory