import React from "react";
import Global_Section_Main from "./Global_Section_Main";
import Global_Section_A from "./Global_Section_A";
import Global_Section_B from "./Global_Section_B";
import Banner from "../../../assets/images/b2b-banner.png";
import Section_A from '../../../assets/images/b2b-a.svg';
import Section_B from '../../../assets/images/b2b-b.png';
import Section_C from '../../../assets/images/b2b-c.svg';

import { useTranslation } from "react-i18next";

const B2b = () => {
  const { t } = useTranslation(["innerpages"]);
  return (
    <>

      <Global_Section_Main
        title={'CRM for B2B'}
        description={'TaskTak B2B CRM is a customer relationship management platform designed specifically for businesses; that sell their products and services to other businesses. This CRM system is really essential to an organization\'s growth and success.'}
        image={Banner}
      />
      <Global_Section_A
        title={'Ensures better business insights'}
        description={'With a deeper understanding of your customers\' likes, dislikes, behaviors, and preferences, you can either fine-tune the sale approach or devise an entirely new strategy. It ensures that high-potential prospects receive the attention they require and that the sales funnel does not have any holes. '}
        image={Section_A}
        color="gray mt-top"
      />
      <Global_Section_B
        title={'Developing and launching targeted marketing campaigns'}
        description={'With B2B CRM, companies may create campaigns; targeted to the requirements and interests of their target audience. It helps companies to be more efficient and effective in their marketing efforts, and they can quickly identify new customers and focus their efforts on engaging with them.'}
        image={Section_B}
        color="gray"
      />
      <Global_Section_A
        title={'Get real-time quotations'}
        description={'With real-time data, the sales department can ensure that the quotations they provide; are accurate and up to date. It eliminates any potential confusion caused by outdated information and allows customers to trust that they are getting the most accurate quotes and that no false promises are made.'}
        image={Section_C}
        color="white"
      />
    </>
  );
};

export default B2b;
