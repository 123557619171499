import React from "react";
import { useTranslation } from "react-i18next";
import Screens from "../../../pages/Screens"
import img1 from "../../../../assets/images/home/icon-a.svg";
import img2 from "../../../../assets/images/home/icon-b.svg";
import img3 from "../../../../assets/images/home/icon-c.svg";
import img4 from "../../../../assets/images/home/icon-d.svg";
import img5 from "../../../../assets/images/home/icon-e.svg";
import icon_1 from "../../../../assets/images/home/icons-a.svg";
import icon_2 from "../../../../assets/images/home/icons-b.svg";
import icon_3 from "../../../../assets/images/home/icons-c.svg";
import icon_4 from "../../../../assets/images/home/icons-d.svg";
import icon_5 from "../../../../assets/images/home/icons-e.svg";
import icon_6 from "../../../../assets/images/home/icons-f.svg";
import icon_7 from "../../../../assets/images/home/icons-g.svg";
import icon_8 from "../../../../assets/images/home/icons-h.svg";
import img from "../../../../assets/images/home/list-dot.svg";
import GlobalA from "../NpoGlobal";
import Section_B from '../../../../assets/images/home/npo-b.svg';
import { useLocation } from "react-router-dom";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import LeadPopup from "../LeadPopup";
import NewPrice from "../../NewPrice";

const Work = () => {
  const { t } = useTranslation(["service"]);
  const { pathname } = useLocation();

  const datas = [
    {
      id: 1,
      icon: icon_1,
      title: "Process optimization:",
      point1: "Optimize processes",
      point2: "Reduce manual workload",
      point3: "Free up staff time",
      divClass: "serv_1",
      link: "Get Started",
    },

    {
      id: 2,
      icon: icon_2,
      title: "Real time insights:",
      point1: "Real time performance view",
      point2: "Helps informed decision making",
      point3: "Reduce research time",
      divClass: "serv_2",
      link: "Get Started",
    },

    {
      id: 3,
      icon: icon_3,
      title: "Collaboration & Communication:",
      point1: "Ensure goal congruency",
      point2: "Strong connectivity",
      point3: "Increase social engagement up to 25%",
      divClass: "serv_3",
      link: "Get Started",
    },

    {
      id: 4,
      icon: icon_4,
      title: "Document Management:",
      point1: "Centralized documents",
      point2: "Accessibility",
      point3: "Saving $25000 on documents",
      divClass: "serv_4",
      link: "Get Started",
    },
  ];
  const datass = [
    {
      id: 1,
      icon: icon_5,
      title: "Streamlining Processes:",
      point1: "Streamlined process",
      point2: "Identify areas of improvement",
      point3: "Better output quality",
      divClass: "serv_1",
      link: "Get Started",
    },

    {
      id: 2,
      icon: icon_6,
      title: "Automation:",
      point1: "Automating repetitive tasks",
      point2: "Saves time and reduces errors",
      point3: "Boost productivity by 15% - 27%",
      divClass: "serv_2",
      link: "Get Started",
    },

    {
      id: 3,
      icon: icon_7,
      title: "Standardization:",
      point1: "Standardizing processes and procedures",
      point2: "Ensures consistency in output quality",
      point3: "",
      divClass: "serv_3",
      link: "Get Started",
    },

    {
      id: 4,
      icon: icon_8,
      title: "Monitoring and Evaluation:",
      point1: "Track progress and identify issues",
      point2: "Early identification of issues",
      point3: "",
      divClass: "serv_4",
      link: "Get Started",
    },
  ];

  const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    rewind: false,
    dots: true,
    lazyLoad: "true",
    lazyContent: "true",
    loop: false,
    autoplay: false,
    navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 3,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 5,
      },
      1200: {
        items: 5,
      },
    },
  };
  const optionss = {
    margin: 20,
    responsiveClass: true,
    nav: false,
    autoHeight: true,
    rewind: false,
    dots: false,
    lazyLoad: "true",
    lazyContent: "true",
    loop: false,
    touchDrag: false,
    mouseDrag: false,
    autoplay: false,
    navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
        autoWidth: true,
      },
      400: {
        items: 1,
        autoWidth: true,
      },
      600: {
        items: 3,
        autoWidth: true,
      },
      700: {
        items: 2,
        autoWidth: true,
      },
      1000: {
        items: 4,
        autoWidth: true,
      },
      1200: {
        items: 4,
        autoHeight: true,
      },
    },
  };
  const data = [
    {
      title: "Manual data entry time overhead is vastly Reduced.",
      image: img1,
    },
    {
      title: "50% of time saved due to automation.",
      image: img2,
    },
    {
      title: "Better and real-time internal communication of organization",
      image: img3,
    },
    {
      title: "Report generation time is reduced by 40% - 60%.",
      image: img4,
    },
    {
      title: "Cost and time wasted on recurring processes can be saved.",
      image: img5,
    },
  ];
  return (
    <>
      <section className="inner_main mt-top npo-inner-section ">
        <div className="container">
          <div className="col-12 text-center">
            <h2 className="text-second fs-4 mb-3 position-relative h2 ">
              SAVES TIME
            </h2>
          </div>
          <div className="clearfix"></div>
          <div className="mt-3 pt-0 mt-md-5 row custom-pt">
            <OwlCarousel className="owl-theme" {...options}>
              {data.map((item, i) => (
                <div className="item">
                  <div className="save-time">
                    <div className="save-icon">
                      <img src={item.image} alt="" />
                      <p className="text-center save-text pt-3">{item.title}</p>
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </section>

      <section
        id="service"
        className="overflow-hidden  position-relative service__section inner_main  mt-top npo-inner-sections "
      >
        <div className="container">
          <div className="col-12 text-center">
            <h2 className="text-second fs-4 mb-3 position-relative h2 ">
              PRODUCTIVITY
            </h2>
          </div>
          <div className="clearfix"></div>
          <div class="mt-5 pt-0 mt-md-5 row custom-pt">
            {datas.map((item) => (
              <div className="item col-md-3" key={item}>
                <div id={item.divClass} className="features-boxs">
                  <div className="service-box mb-4 p-3">
                    <span className="align-items-center d-flex justify-content-around rounded-pill mb-2">
                      <img
                        src={item.icon}
                        alt="img"
                        className="service-images"
                      />
                    </span>
                    <h3 className="text-second fs-4">{item.title}</h3>
                    <ul className="list-unstyled text-gray small mb-3">
                      <li className="mb-1">
                        <img src={img} alt="" />
                        {item.point1}
                      </li>
                      <li className="mb-1">
                        <img src={img} alt="" />
                        {item.point2}
                      </li>
                      <li className="mb-1">
                        <img src={img} alt="" />
                        {item.point3}
                      </li>
                    </ul>
                    {/* <a
                        href="https://app.tasktak.com/authentication/register"
                        className="text-decoration-none"
                      >
                        {" "}
                        {item.link}{" "}
                      </a> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section
        id="service"
        className="overflow-hidden position-relative service__section inner_main  mt-top npo-inner-sections "
      >
        <div className="container">
          <div className="col-12 text-center">
            <h2 className="text-second fs-4 mb-3 position-relative h2 ">
            Avoid Rework and Maximize Output
            </h2>
          </div>
          <div className="clearfix"></div>
          <div class="mt-5 row pt-0 mt-md-5 custom-pt">
            {datass.map((item) => (
              <div className="item col-md-3" key={item}>
                <div id={item.divClass} className="features-boxs">
                  <div className="service-box mb-4 p-3">
                    <span className="align-items-center d-flex justify-content-around rounded-pill mb-2">
                      <img
                        src={item.icon}
                        alt="img"
                        className="service-images"
                      />
                    </span>
                    <h3 className="text-second fs-4">{item.title}</h3>
                    <ul className="list-unstyled text-gray small mb-3">
                      <li className="mb-1">
                        <img src={img} alt="" />
                        {item.point1}
                      </li>
                      <li className="mb-1">
                        <img src={img} alt="" />
                        {item.point2}
                      </li>
                      {item.point3 === '' ? "" : 
                      <li className="mb-1">
                
                        <img src={img} alt="" />{item.point3}
                      
                      </li>
                    }
                    </ul>
                    {/* <a
                        href="https://app.tasktak.com/authentication/register"
                        className="text-decoration-none"
                      >
                        {" "}
                        {item.link}{" "}
                      </a> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <GlobalA
          title={'Coming Soon'}
          description={<>Get potential donors and current donors together in your fundraising event using the TaskTak Fundraising Event module coming soon. This will allow you to have signups for free and paid events. You can have a paid fundraising dinner or a free raffle event. The possibilities are endless.</>}
          image={Section_B}
         />

{pathname === "/npo" ? (               
        <NewPrice />
):''}

     

      <section className="cta">
        <div className="container">
          <div className="cta-inner-ngo">
          <div className="row">
            <div className="col-md-8">
              <h3>Experience our interactive demo and get a personalized quote based on your business needs for our easy-to-use software.</h3>
            </div>
            <div className="col-md-4">
            {pathname === "/npo" ? (
              <div className="d-flex justify-content-end">
              <LeadPopup 
              text={'GET A DEMO'}
              /></div>
            )
              :
              (
                <a
                  href="https://app.tasktak.com/authentication/register"
                  className="col-md-auto col-5 btn btn-yellow border-0 rounded-pill fs-5 lh-23 px-4 py-2 me-2 me-md-4 inner-btn-ngo"
                  style={{color: 'rgb(255, 255, 255)' , float: 'right'}}
                >
                  GET A DEMO
                </a>
              )

              }
            </div>
          </div>
          </div>
        </div>
      </section>
      
      


    </>
  );
};

export default Work;
