import React from 'react'
import icon1 from '../../../assets/images/webmail/oil-1.svg'
import icon2 from '../../../assets/images/webmail/oil-2.svg'
import icon3 from '../../../assets/images/webmail/oil-3.svg'
import icon4 from '../../../assets/images/webmail/oil-4.svg'
import icon5 from '../../../assets/images/webmail/oil-5.svg'
import icon6 from '../../../assets/images/webmail/oil-6.svg'
import icon7 from '../../../assets/images/webmail/oil-7.svg'
import icon_1 from '../../../assets/images/webmail/icon_1.svg'
import icon_2 from '../../../assets/images/webmail/icon_2.svg'
import icon_3 from '../../../assets/images/webmail/icon_3.svg'
import Screens from '../Screens'
import img from '../../../assets/images/home/list-dot.svg'
import Global_Section_A from "./Global_Section_A";
import Section_A from "../../../assets/images/webmail/circle-img.svg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import HomeReview from './HomeReview'


const Oil = () => {
    const data = [{
        title : 'Project Management',
    },
    {
        title : 'Resource Planning & Time Sheets'
    },
    {
        title : 'RCRM (Customer Relationship Management)'
    },
    {
        title : 'HR Management'
    },
    {
        title : 'Accounting'
    },
    {
        title : 'Asset Inventory Purchasing & Management'
    }

    ]
    const datas = [{
        icon : icon1,
        title : 'Marketing Tools',
        describe : 'SEO, Bulk Email, Marketing Campaigns'
    },
    {
        icon : icon2,
        title : 'Support Board',
        describe : 'Chat, Knowledge Base, Ticketing System'
    },
    {
        icon : icon3,
        title : 'Team Collaboration',
        describe : 'Collaborate with your employees, customers and win customer opportunities'
    },

    ]
    const logo = [{
        icon : icon4,
    },
    {
        icon : icon5,
    },
    {
        icon : icon6,
    },
    {
        icon : icon7,
    },

    ]
    const options = {
        margin: 30,
        responsiveClass: true,
        nav: false,
        rewind: false,
        dots: true,
        lazyLoad: "true",
        lazyContent: "true",
        loop: false,
        autoplay: false,
        navText: ["Prev", "Next"],
        smartSpeed: 1000,
        responsive: {
          0: {
            items: 1,
          },
          400: {
            items: 1,
          },
          600: {
            items: 3,
          },
          700: {
            items: 3,
          },
          1000: {
            items: 3,
          },
          1200: {
            items: 3,
          },
        },
      };
      const optionss = {
        margin: 30,
        responsiveClass: true,
        nav: false,
        rewind: false,
        dots: true,
        lazyLoad: "true",
        lazyContent: "true",
        loop: false,
        autoplay: false,
        navText: ["Prev", "Next"],
        smartSpeed: 1000,
        responsive: {
          0: {
            items: 1,
          },
          400: {
            items: 1,
          },
          600: {
            items: 3,
          },
          700: {
            items: 3,
          },
          1000: {
            items: 4,
          },
          1200: {
            items: 4,
          },
        },
      };
      const datasss = [
        {
          id: 1,
          icon: icon_1,
          title: "Improve daily productivity Increase knowledge reuse",
          point1: "4% to 6%",
          point2: "Overall productivity increase for a thousand people owner-operator team during a large capital project ",
        },
    
        {
          id: 2,
          icon: icon_2,
          title: "Minimize costs overruns Reduce schedule deviations",
          point1: "10% to 15%",
          point2: "Cost reduction during the detailed engineering of a complex facility ",
        },
    
        {
          id: 3,
          icon: icon_3,
          title: "Increase output quality Avoid rework",
          point1: "10% to 15%",
          point2: "Time reduction for critical path work processes, including proofing, reviewing, validating & certifying construction procedures",
        },
      ];
  return (
    <>

    <div className='oil-section banner'>
        <div className='container'>
            <div className='oil-wrapper'>
                <div className='oil-main d-flex justify-content-center align-items-center flex-column'>
                    <h1 class="inner-title fw-bold display-4 mb-3 aos-init aos-animate">How TaskTak enhances collaboration and communication
                    in the Oil & Gas Industry</h1>
                    <p>TaskTak's business management platform can help companies operating in the oil and gas industry with their digital transformation efforts. The platform is designed to be user-friendly and easy to navigate, making it simple for businesses to manage their day-to-day operations, automate their workflows, and gain valuable insights into their operations. By leveraging the features and tools offered by TaskTak, companies in the oil and gas industry can streamline their processes, increase their efficiency, reduce their costs, and ultimately drive their business forward.</p>
                    <div className='btn-group'>
                    <a
                        href="/pricing"
                        className="col-md-auto col-5 btn btn-yellow border-0 rounded-pill fs-5 lh-23 px-4 py-2 me-2 me-md-4 inner-btn-ngo"
                        bis_skin_checked="1"
                        style={{color: '#fff'}}
                        >
                        Get Started
                    </a>
                    </div>
                    <p className='no-req'>No credit card required *</p>
                </div>
            </div>
        </div>
    </div>
    <section className='section-2'>
        <div className='container'>
            <div className='section-2-inner'>
                <h3 className='text-uppercase text-second fs-4 mb-3 position-relative h2 text-center'>Supporting key functions and any industry to run <br/>and
                grow the business efficiently</h3>
            </div>
            <div className='section-2-main'>
                <h4>Key Functions</h4>
            
            <div className='section-2-border'>
                <ul>
                <div className='d-flex justify-content-between align-items-center pt-4'>
                    {data.map((item ,i) => (
                        i < 3 ? (
                        
                        <li><img src={img} alt="" /> {item.title}</li>
                        ):  ''
                    ))}
                    </div>
                    <div className='d-flex d-flex justify-content-between align-items-center pt-4 px-70'>
                        {data.map((item ,i) => (
                            i > 2 ? (
                  
                            <li><img src={img} alt="" /> {item.title}</li>
                            ):''
                        ))}
                    </div>
                    
                    
                </ul>
            </div>
            </div>
            <div className="mt-3 pt-0 mt-md-5 row custom-pt mx-width">
            <OwlCarousel className="owl-theme" {...options}>
              {datas.map((item, i) => (
                <div className="item">
                  <div className="save-time">
                    <div className="save-icon">
                      <img src={item.icon} alt="" />
                      <h5 className="text-center save-text pt-3">{item.title}</h5>
                      <p className="text-center save-text">{item.describe}</p>
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>

            
        </div>
    </section>
    <section className='bg-grey'>
    <div className='container'>
    <div className='text-center'>
        <h2 className='text-second fs-4 mb-3 position-relative h2 text-center'>Integration for popular industry standard productivity tools</h2>
    </div>
    <div className='mt-5 custom-owl-slider'>
            <OwlCarousel className="owl-theme" {...optionss}>
              {logo.map((item, i) => (
                <div className="item">
                  <div className="save-time">
                    <div className="save-icon custom-logo">
                      <img src={item.icon} alt="" />    
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>
    </div>
    </div>
    </section>

    <Global_Section_A
        title={'TaskTak\'s Tools to Digitally Transform Businesses in Oil & Gas Industry'}
        description={'By using BMP, Non-profit organization can develop a clear and comprehensive understanding of their business model and identify areas for improvement. This can help organizations to be more effective, efficient, and sustainable in their operations.'}
        image={Section_A}
        color="grayd mt-top"
    />

<section
        id="service"
        className="overflow-hidden position-relative service__section inner_main  mt-top npo-inner-sections "
      >
        <div className="container">
          <div className="col-12 text-center">
            <h2 className="text-second fs-4 mb-3 position-relative h2 text-center">
            How TaskTak BMP can help?
            </h2>
          </div>
          <div className="clearfix"></div>
          <div class="mt-5 row pt-0 mt-md-5 custom-pt">
            {datasss.map((item) => (
              <div className="item col-md-4" key={item}>
                <div id={item.divClass} className="features-boxs">
                  <div className="service-box mb-4 p-3">
                    <span className="align-items-center d-flex justify-content-around rounded-pill mb-2 mb-2" style={{'margin': '0 auto'}}>
                      <img
                        src={item.icon}
                        alt="img"
                        className="service-images ser-custom-img"
                      />
                    </span>
                    <h3 className="text-second fs-4 text-center custom-oil-title">{item.title}</h3>
                    <ul className="list-unstyled text-center text-gray small mb-3">
                      <li className="mb-1 text-center d-block">
                        {item.point1}
                      </li>
                      <li className="mb-1">
                        {item.point2}
                      </li>                   
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Screens />
      <HomeReview />

    </>
    
  )
}

export default Oil