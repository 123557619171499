import React from "react";
import "../../contact.css";
import axios from "axios";
import $ from "jquery";
import Constant from "../../utils/Constant";


class Contact extends React.Component {
  constructor() {
    super();

    this.state = {};
  }

  sendQuery = () => {
    let classRef = this;
    $("#errorMsg").hide();
    $("#successMsg").hide();
    var name = $("#name").val();
    var email = $("#email").val();
    var subject = $("#subject").val();
    var message = $("#message").val();
    if (name == "" || email == "" || subject == "" || message == "") {
      $("#errorMsg").show();
    }
    else{
    $("#send_button").val("Processing..");
    axios({
      method: "GET",
      url:
        "https://" +
        Constant.url +
        ".tasktak.com/Subscription/sendMessage?email=" +
        email +
        "&name=" +
        name +
        "&email=" +
        email +
        "&subject=" +
        subject +
        "&message=" +
        message,
    }).then(function (result) {
      $("#send_button").val("Send Message");
      $("#successMsg").show();
      $("#errorMsg").hide(); // Hide the error message
      $("#contactForm").trigger("reset");
      setTimeout(function(){
        window.location.href = '/contact-thankyou';
      },2000)
    })
    .catch(function (error) {
      $("#send_button").val("Send Message");
      console.error(error); // Handle the error, e.g., show an error message
    });
  }
  };

  render() {
    return (
      <>
      <section
        id="contact"
        className="overflow-hidden position-relative service__sections pb-5 mt-5 mb-5"
      >
        <div className="container">
          
          <h2 className="fw-medium lh-sm text-center">Contact us</h2>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="wrapper">
                <div className="row no-gutters">
                  <div className="col-lg-5 col-md-5 order-md-last d-flex align-items-stretch">
                    <div className="contact-wrap w-100 p-md-5 p-4">
                      <div id="form-message-warning" className="mb-4"></div>
                      <div id="form-message-success" className="mb-4">
                        Your message was sent, thank you!
                      </div>
                      <form
                        id="contactForm"
                        name="contactForm"
                        className="contactForm"
                        action="javascript:void(0)"
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="label" for="name">
                                Full Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="name"
                                id="name"
                                placeholder="Name"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="label" for="email">
                                Email Address
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                id="email"
                                placeholder="Email"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="label" for="subject">
                                Subject
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="subject"
                                id="subject"
                                placeholder="Subject"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="label" for="#">
                                Message
                              </label>
                              <textarea
                                name="message"
                                className="form-control"
                                id="message"
                                cols="30"
                                rows="4"
                                placeholder="Message"
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <input
                                type="submit"
                                value="Send Message"
                                id="send_button"
                                className="btns btn-primary"
                                onClick={this.sendQuery}
                              />
                              <div className="submitting"></div>
                            </div>
                          </div>
                          <p
                            id="successMsg"
                            style={{ display: "none", color: "green" }}
                          >
                            Your message has been sent
                          </p>
                          <p
                            id="errorMsg"
                            style={{ display: "none", color: "red" }}
                          >
                            Please select all required fields
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="col-lg-7 col-md-7 d-flex align-items-stretch">
                    <div className="info-wrap bg-primary w-100 p-md-5 p-4">
                      <h3>Let's get in touch</h3>
                      {/* <p className="mb-4">We're open for any suggestion or just to have a chat</p> */}
                      <div className="dbox w-100 d-flex align-items-start">
                        <div className="icon d-flex align-items-center justify-content-center">
                          <span className="fa fa-map-marker"></span>
                        </div>
                        <div className="text pl-3">
                          <p>
                            <span>Headquarters:</span> Redmond, Washington
                          </p>
                        </div>
                      </div>
                      <div className="dbox w-100 d-flex align-items-center">
                        <div className="icon d-flex align-items-center justify-content-center">
                          <span className="fa fa-phone"></span>
                        </div>
                        <div className="text pl-3">
                          <p>
                            <span>Phone:</span>{" "}
                            <a href="tel:+14252150293">+1 425 215 0293</a>
                          </p>
                        </div>
                      </div>
                      <div className="dbox w-100 d-flex align-items-center">
                        <div className="icon d-flex align-items-center justify-content-center">
                          <span className="fa fa-paper-plane"></span>
                        </div>
                        <div className="text pl-3">
                          <p>
                            <span>Email:</span>{" "}
                            <a href="mailto:info@tasktak.com">
                              info@tasktak.com
                            </a>
                          </p>
                        </div>
                      </div>
                      <div className="dbox w-100 d-flex align-items-center">
                        <div className="icon d-flex align-items-center justify-content-center">
                          <span className="fa fa-globe"></span>
                        </div>
                        <div className="text pl-3">
                          <p>
                            <span>Website</span>{" "}
                            <a href="/">https://tasktak.com</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </>
    );
  }
}
export default Contact;
