import React from "react";
import Global_Section_Main from "./Global_Section_Main";
import Global_Section_A from "./Global_Section_A";
import Global_Section_B from "./Global_Section_B";
import Banner from "../../../assets/images/project-banner.svg";
import Section_A from "../../../assets/images/project-a.svg";
import Section_B from "../../../assets/images/project-b.svg";
import Section_C from "../../../assets/images/project-c.png";

import { useTranslation } from "react-i18next";

const Project = () => {
  const { t } = useTranslation(["innerpages"]);
  return (
    <>
      <Global_Section_Main
        title={'Project Management CRM Software'}
        description={'TaskTak CRM includes a project management feature that helps teams manage tasks and projects related to cultivating customer relationships. With this feature, teams can assign tasks to members, set deadlines, track progress, and monitor the overall success of the projects. '}
        image={Banner}
      />
      <Global_Section_A
        title={'Visual Project Tracking '}
        description={'TaskTak helps you track your entire project from beginning to end with beautiful visuals. Additionally, you can set deadlines and receive notifications when tasks are completed or when someone is having trouble completing tasks. '}
        image={Section_A}
        color="gray mt-top"
      />
      <Global_Section_B
        title={'Data-driven insights'}
        description={'Why guess about the effectiveness of your initiatives when data-driven insights can back up your decision? TaskTak CRM gives you a 360-degree view of customer data that helps you make more informed business decisions.         '}
        image={Section_B}
        color="gray"
      />
      <Global_Section_A
        title={'Centralize team communication '}
        description={'TaskTak combines marketing, sales, and support teams together for efficient and clearer project management. Communication can be seen all in one place, making it easier to communicate with colleagues and minimizing communication barriers.'}
        image={Section_C}
        color="white"
      />
    </>
  );
};

export default Project;
