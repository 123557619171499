import React from 'react';
import { useTranslation } from "react-i18next";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import banner from '../../assets/images/home/section-girl.png'
import icon_1 from '../../assets/images/home/icon-1.svg'
import icon_2 from '../../assets/images/home/icon-2.svg'
import icon_3 from '../../assets/images/home/icon-3.svg'
import icon_4 from '../../assets/images/home/icon-4.svg'
import icon_5 from '../../assets/images/home/icon-5.svg'
import icon_6 from '../../assets/images/home/icon-6.svg'
import icon_7 from '../../assets/images/home/icon-7.svg'
import icon_8 from '../../assets/images/home/icon-8.svg'
import icon_9 from '../../assets/images/home/icon-8.svg'

const Ser = () => {
    const { t } = useTranslation(["service", "home"]);
    const options = {
        margin: 30,
        responsiveClass: true,
        nav: false,
        rewind: false,
        dots: true,
        lazyLoad: 'true',
        lazyContent: 'true',
        loop: false,
        autoplay: false,

        navText: ["Prev", "Next"],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
                autoWidth: true,
            },
            400: {
                items: 2,
                autoWidth: true,
            },
            600: {
                items: 2,
            },
            700: {
                items: 2,
                autoWidth: true,
            },
            1000: {
                items: 4,
                autoWidth: true,

            },
            1200: {
                items: 4,
                autoWidth: true,

            },
            1600: {
                items: 8,
                autoWidth: true,

            },
            1700: {
                items: 8,
                autoWidth: true,

            },
            2000: {
                items: 8,
                autoWidth: true,

            }
        },
    };
    const data = [
        {
            id: 1,
            icon: icon_1,
            title: "CRM",
            point1: "Lead Tracking",
            point2: "Email",
            point3: "Appointments",
            point4: "Calendar",
            point5: "Call Logs",
            point6: "Zoom Meetings",
            point7: "Client Subscriptions",
            divClass: "serv_1",
            link: "Get Started"
        },

        {
            id: 2,
            icon: icon_2,
            title: "Project Management",
            point1: "Per Client Projects",
            point2: "Project Progress",
            point3: "Invoice Per Project",
            point4: "Tasks",
            point5: "Time Tracking",
            point6: "Milestones",
            point7: "Discussion Board",
            divClass: "serv_2",
            link: "Get Started"
        },

        {
            id: 3,
            icon: icon_3,
            title: "HR Management",
            point1: "Employee Records",
            point2: "Onboarding Checklist",
            point3: "Recruiting",
            point4: "Reports",
            point5: "Affirmative Action Tools",
            point6: "Layoff Checklist",
            point7: "Settings",
            divClass: "serv_3",
            link: "Get Started"
        },

        {
            id: 4,
            icon: icon_4,
            title: "Team Collaboration",
            point1: "Individual Chat",
            point2: "Group Chat",
            point3: "File Sharing",
            point4: "Task Assignment",
            point5: "Zoom Meetings",
            point6: "Goal Tracking",
            point7: "Voice / File Messages",
            divClass: "serv_4",
            link: "Get Started"
        },

        {
            id: 5,
            icon: icon_5,
            title: "Accounting",
            point1: "Invoicing",
            point2: "Sales Recording",
            point3: "Expense Recording",
            point4: "Purchase Tracking",
            point5: "Inventory Tracking",
            point6: "Asset Recording",
            point7: "Journal Entries",
            divClass: "serv_5",
            link: "Get Started"
        },

        {
            id: 6,
            icon: icon_6,
            title: "Operations",
            point1: "Emails",
            point2: "Diagrams Design",
            point3: "Online Spreadsheets",
            point4: "Activity Logs",
            point5: "Announcements",
            point6: "Surveys",
            point7: "To-do Checklist",
            divClass: "serv_6",
            link: "Get Started"
        },
        {
            id: 7,
            icon: icon_7,
            title: "Timesheets",
            point1: "Attendance",
            point2: "Leave Requests",
            point3: "Assign Work Routes",
            point4: "Shift Scheduling",
            point5: "Location Based",
            point6: "Workplace",
            point7: "Reports",
            divClass: "serv_7",
            link: "Get Started"
        },
        {
            id: 8,
            icon: icon_8,
            title: "Marketing Tools & Analytics",
            point1: "Marketing Campaign",
            point2: "SEO",
            point3: "Automated Marketing",
            point4: "Lead Management",
            point5: "Social Media Management",
            point6: "Campaign scheduling",
            point7: "Bulk email",
            divClass: "serv_9",
            link: "Get Started"
        },
        {
            id: 9,
            icon: icon_9,
            title: "Support",
            point1: "Knowledge Base",
            point2: "Ticket Support",
            point3: "Help Chat",
            point4: "Activity Logs",
            point5: "Announcements",
            point6: "Surveys",
            point7: "To-do Checklist",
            divClass: "serv_9",
            link: "Get Started"
        },
    ];

    return (
        <>


      


                    <OwlCarousel className="owl-theme" {...options}>
                        {

                            data.map(item => (

                                <div className='item' key={item}>
                                    <div id={item.divClass} className="features-box" >
                                        <div className='service-box mb-4 p-3'>
                                            <span className='align-items-center d-flex justify-content-around rounded-pill mb-2'>
                                                <img src={item.icon} alt='img' className='service-images' />
                                            </span>
                                            <h3 className="text-second fs-4">{item.title}</h3>
                                            <ul className='list-unstyled text-gray small mb-3 white-space-nowrap'>
                                                <li className="mb-1">{item.point1}</li>
                                                <li className="mb-1">{item.point2}</li>
                                                <li className="mb-1">{item.point3}</li>
                                                <li className="mb-1">{item.point4}</li>
                                                <li className="mb-1">{item.point5}</li>
                                                <li className="mb-1">{item.point6}</li>
                                                <li className="mb-1">{item.point7}</li>
                                            </ul>
                                            <a href="/pricing" className='text-decoration-none'> {item.link} </a>
                                        </div>
                                    </div>

                                </div>
                            ))



                        }

                    </OwlCarousel>
             


        </>
    )
}
export default Ser  