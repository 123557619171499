import React, { useState } from "react";
import Global_Section_Main from "./Global_Section_Main";
import Global_Section_A from "./Global_Section_A";
import Global_Section_B from "./Global_Section_B";
import Banner from "../../../assets/images/asset-banner.svg";
import Section_A from "../../../assets/images/asset-a.svg";
import Section_B from "../../../assets/images/asset-b.svg";
import Section_C from "../../../assets/images/asset-c.svg";
import Nav from "react-bootstrap/Nav";
import Lead from "../../../assets/images/lead-images.png";
import TabComponent from "./TabComponent";
import Tab from "react-bootstrap/Tab";


import { useTranslation } from "react-i18next";

const Assets = () => {
  const [hrmshow, sethrmShow] = useState(false);
  const data = [
    {
      image: Lead,
      link: "Assets",
      discription: "This refers to all the recorded assets either physical or virtual that a business owns and uses for business purposes. This includes all kinds of fixed assets such as equipment, software, vehicles, and more.",
      key: "first",
      },
    {
      image: Lead,
      link: "Allocation",
      discription: "This section allows you to assign specific assets to relevant employees and departments within your organization. It helps businesses to track who is using which asset and ensure that everyone has the resources they need to do their job.",
      key: "second",
      },
    {
      image: Lead,
      link: "Revoke",
      discription: "This section allows businesses to recall assets if it is damaged, need maintenance or replacing with other assets. This feature is useful whenever an employee leaves organization or if an asset needs to be reallocated to another employee. It is also used to revoke assets from one department and allocate it to another department.",
      key: "third",
    },
    {
      image: Lead,
      link: "Depreciation",
      discription: "This refers to the decrease in value of an asset over time. This can happen due to factors such as wear and tear, obsolescence, and market fluctuations. Depreciation tracking can help you keep track of the value of your assets over time and plan for their replacement or upgrade.",
      key: "forth",
      },
    {
      image: Lead,
      link: "Settings",
      discription: "This section is used for configuration and customization of options of the asset management module. This section includes setting up asset categories or groups, units and its location. Customized locations can be added as per the organization’s priority.",
      key: "fifth",
      },
  ];

 
  const { t } = useTranslation(["innerpages"]);

  return (
    <>


      <Global_Section_Main
      title={t('assets')}
      description={t('assets_desc')}
      image={Banner}
    />
    <Global_Section_A
      title={t('assets-a')}
      description={t('assets-desc-a')}
      image={Section_A}
      color="gray mt-top"
      text={
        <>
          <div className="pb-5 align-items-center d-flex gray">
            <button
              className="cs-btn pl-0 d-flex"
              onClick={() => sethrmShow((prev) => !prev)}
            >
              Key Features
            </button>
          </div>
        </>
      }
    />

      {hrmshow && (
        <div className="container  mb-5 pb-5 d-lg-block anim ov-x">
          <TabComponent
            link={data.map((item) => (
              <Nav.Link eventKey={item.key}>{item.link}</Nav.Link>
            ))}
            pane={data.map((item) => (
              <Tab.Pane eventKey={item.key} key={item.link}>
                <div className="row justify-content-center align-items-center">
                  <div className="col-md-6">
                    <div className="tabs-heading">
                      <h4>{item.link}</h4>
                    </div>
                    <div className="tabs-description">
                      <p>{item.discription}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="tabs-image">
                      <img src={item.image} alt="" />
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            ))}
          />
        </div>
      )} 



    <Global_Section_B
      title={'Prevent human error'}
      description={'TaskTak BMP\'s Asset Management module has changed the way of asset management and tracking procedures from manual and traditional ways to more efficient and productive. It saves your both time and energy which you can use to enhance your productivity and performance.  Now, you can focus and BMP will take care of rest. '}
      image={Section_B}
      color="gray"
    />



    <Global_Section_A
      title={'Prolong the asset’s lifespan!'}
      description={'Organizations may guarantee that they are not replacing assets as frequently by effectively maintaining them, which helps to lower the expenses involved with acquiring new equipment and other assets. Furthermore, it aids in waste reduction by requiring fewer assets to be disposed of, which benefits the environment.'}
      image={Section_C}
      color="white"
    />


    </>
  );
};

export default Assets;
