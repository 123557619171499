import React from 'react';
import { useTranslation } from "react-i18next";
import { Container, Row } from 'react-bootstrap';
import Car  from './Car';
import Caro  from './Caro';
import banner from '../../assets/images/project-management.svg'
import { Link } from 'react-router-dom';
import Counter from './Counter';


const Features = () => {
    const { t } = useTranslation(["service"]);
    return (

        <>

        <section className='features-mains'>
        {t("service_condition") == "1" ?
        <Caro />
        :
        <Car />
        }
        </section>
        <section id="feature" className="text-center text-md-start">
            <Container className="px-5 px-md-3">
                <Row className='align-items-center position-relative'>
                    <div className='col-12 col-md-6'>
                        {/* <img className='ellipse-img' src='assets/images/Ellipse-10.png' alt="" /> */}
                        <img src={banner} alt="" className='p-3 p-md-5 img-fluid' />
                    </div>
                    <div className='col-12 col-md-6'>
                        <h2 className='fw-medium'>{t("efficient_project")}</h2>
                        <p className='text-gray col-12 col-md-8'>{t("efficient_desc")}</p>
                        <a href="/pricing" className="text-decoration-none mb-5 mb-md-0 d-block">{t("try_it_button")}</a>
                    </div>
                </Row>
            </Container>
        </section>

        <Counter/>
        </>
        
    )
}
export default Features;
