import React from "react";
import Global_Section_Main from "./Global_Section_Main";
import Global_Section_A from "./Global_Section_A";
import Global_Section_B from "./Global_Section_B";
import Banner from "../../../assets/images/lead-banner.png";
import Section_A from "../../../assets/images/digital-a.svg";
import Section_B from "../../../assets/images/lead-b.png";
import Section_C from "../../../assets/images/lead-c.png";
import { useTranslation } from "react-i18next";

const Lead = () => {
    const { t } = useTranslation(["innerpages"]);
    return (
      <>
        <Global_Section_Main
          title={'CRM Lead Management Software'}
          description={'Lead management involves qualifying, analyzing, and nurturing incoming leads to turn them into new business opportunities. With the TaskTak lead management tool, you can capture high-quality inbound and outbound leads before they fade away. '}
          image={Banner}
        />
        <Global_Section_A
          title={'Get leads straight to your inbox!'}
          description={'TaskTak leads inbox helps you manage all your leads by showing "Verified", "Converted", and "Not interested" labels. Tagging leads makes it easy to track and schedule follow-up calls, emails, or other activities to convert leads.'}
          image={Section_A}
          color="gray mt-top"
        />
        <Global_Section_B
          title={'Get more revenue'}
          description={'TaskTak lead management CRM helps you identify your target prospects and create tailored content that speaks directly to them. With this software, you can start a personalized and engaging journey for potential customers, track their progress, and allocate leads to the right sales team members. It helps you maximize your conversion rate and your revenue'}
          image={Section_B}
          color="gray"
        />
        <Global_Section_A
          title={'Maintain visitor’s history'}
          description={'TaskTak CRM keeps chat transcripts for each record so you can view all customer interactions as well as a summary of all their visits. Additionally, you\'ll receive a notification if you miss a chat message while offline.'}
          image={Section_C}
          color="white"
        />
      </>
  )
}


export default Lead