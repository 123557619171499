import React from 'react'
import icon_1 from "../../../../assets/images/webmail/icon-1.png";
import icon_2 from "../../../../assets/images/webmail/icon-2.png";
import icon_3 from "../../../../assets/images/webmail/icon-3.png";
import icon_4 from "../../../../assets/images/webmail/icon-4.png";
import icon_5 from "../../../../assets/images/webmail/icon-5.png";
import icon_6 from "../../../../assets/images/webmail/icon-6.png";
import icon_7 from "../../../../assets/images/webmail/icon-7.png";
import icon_8 from "../../../../assets/images/webmail/icon-8.png";
import icon_9 from "../../../../assets/images/webmail/icon-9.png";
import icon_10 from "../../../../assets/images/webmail/icon-10.png";
import icon_11 from "../../../../assets/images/webmail/icon-11.png";
import icon_12 from "../../../../assets/images/webmail/icon-12.png";
import icon_13 from "../../../../assets/images/webmail/icon-13.png";
import icon_14 from "../../../../assets/images/webmail/icon-14.png";
import icon_15 from "../../../../assets/images/webmail/icon-15.png";
import icon_16 from "../../../../assets/images/webmail/icon-16.png";


import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Web2 = () => {
    const options = {
        margin: 20,
        responsiveClass: true,
        nav: true,
        autoHeight: true,
        rewind: false,
        dots: false,
        lazyLoad: "true",
        lazyContent: "true",
        loop: true,
        touchDrag: true,
        mouseDrag: true,
        autoplay: true,
        navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
        smartSpeed: 1000,
        responsive: {
          0: {
            items: 1,
          },
          400: {
            items: 1,
          },
          600: {
            items: 2,
          },
          700: {
            items: 2,
          },
          1000: {
            items: 3,
          },
          1200: {
            items: 4,
            autoHeight: true,
          },
        },
      };
    const data = [
        {
          id: 1,
          icon: icon_1,
          title: " حسابات البريد الإلكتروني  ",
          point1: "  يستخدم الأشخاص حسابات البريد الإلكتروني لكل من الاتصالات الشخصية والمهنية ، والتي يستخدمها الأفراد والشركات والمؤسسات على حدٍ سواء.          ",
          divClass: "serv_1",
          link: "Get Started",
        },
    
        {
          id: 2,
          icon: icon_2,
          title: "  وكلاء البريد الإلكتروني          ",
          point1: "  يتيح للمستخدمين إعادة توجيه رسائل البريد الإلكتروني الواردة من عنوان بريد إلكتروني إلى آخر ، دون الحاجة إلى الوصول إلى حسابات بريد إلكتروني متعددة. ",
          divClass: "serv_2",
          link: "Get Started",
        },
    
        {
          id: 3,
          icon: icon_3,
          title: " توجيه البريد الإلكتروني  ",
          point1: " يتيح للمستخدمين إعادة توجيه رسائل البريد الإلكتروني الواردة من عنوان بريد إلكتروني إلى آخر ، دون الحاجة إلى الوصول إلى حسابات بريد إلكتروني متعددة.          ",
          divClass: "serv_3",
          link: "Get Started",
        },
    
        {
          id: 4,
          icon: icon_4,
          title: "المجيب التلقائ ",
          point1: " الرد الآلي للبريد الإلكتروني عبارة عن رسائل مكتوبة مسبقًا يتم إرسالها تلقائيًا إلى المشتركين أو العملاء بناءً على مشغلات محددة.          ",
          divClass: "serv_4",
          link: "Get Started",
        },
        {
          id: 5,
          icon: icon_5,
          title: " العنوان الافتراضي",
          point1: " يتم استخدامه بشكل شائع لمنع ارتداد البريد الإلكتروني والتأكد من جمع جميع الرسائل المرسلة إلى مجال معين في مكان واحد.            ",
          divClass: "serv_4",
          link: "Get Started",
        },
        {
          id: 6,
          icon: icon_6,
          title: " القوائم البريدية            ",
          point1: "القائمة البريدية هي مجموعة من عناوين البريد الإلكتروني التي يستخدمها الأفراد أو الشركات لإرسال رسائل إلى عدة مستلمين في وقت واحد",
          divClass: "serv_4",
          link: "Get Started",
        },
        {
          id: 7,
          icon: icon_7,
          title: " تتبع التسليم            ",
          point1: "توفر هذه الميزة تحديثات في الوقت الفعلي حول حالة التسليم ، بما في ذلك ما إذا تم تسليم البريد الإلكتروني بنجاح أو ارتداده أو وضع علامة عليه كرسائل غير مرغوب فيها            ",
          divClass: "serv_4",
          link: "Get Started",
        },
        {
          id: 8,
          icon: icon_8,
          title: " مرشحات البريد الإلكتروني العالمية            ",
          point1: " تشير عوامل تصفية البريد الإلكتروني العامة إلى ميزة تمكّن المستخدمين من تعيين معايير محددة لتصفية رسائل البريد الإلكتروني الواردة عبر نظام أو مجال بريد إلكتروني بأكمله.            ",
          divClass: "serv_4",
          link: "Get Started",
        },
        {
          id: 9,
          icon: icon_9,
          title: " مرشحات البريد الإلكتروني  ",
          point1: "يقوم بفرز رسائل البريد الإلكتروني الواردة بناءً على معايير محددة ونقلها إلى مجلدات معينة ، مما يساعد على تنظيم صناديق البريد الوارد وتحسين الإنتاجية.            ",
          divClass: "serv_4",
          link: "Get Started",
        },
        {
          id: 10,
          icon: icon_10,
          title: "تسليم البريد الإلكترون ",
          point1: " تشير إمكانية تسليم البريد الإلكتروني إلى قدرة البريد الإلكتروني على الوصول بنجاح إلى صندوق الوارد الخاص بالمستلم المقصود دون تصفيته بواسطة عوامل تصفية البريد العشوائي.            ",
          divClass: "serv_4",
          link: "Get Started",
        },
        {
          id: 11,
          icon: icon_11,
          title: " مستورد العنوان            ",
          point1: " تتيح هذه الأداة للمستخدمين استيراد البيانات من تنسيقات ملفات مختلفة ، بما في ذلك .والملفات النصية واستخراج المعلومات ذات الصلة تلقائيًاCSV and Excel            ",
          divClass: "serv_4",
          link: "Get Started",
        },
        {
          id: 12,
          icon: icon_12,
          title: " مرشحات البريد العشوائي ",
          point1: " عوامل تصفية البريد العشوائي هي تطبيقات أو أدوات برمجية مصممة لتحديد ومنع رسائل البريد الإلكتروني غير المرغوب فيها أو من دون دعوة من الوصول إلى صندوق الوارد الخاص بالمستخدم            ",
          divClass: "serv_4",
          link: "Get Started",
        },
        {
          id: 13,
          icon: icon_13,
          title: "التشفير",
          point1: "إنها عملية تحويل بيانات النص العادي إلى تنسيق مشفر يُعرف بالنص المشفر ، والذي لا يمكن فك تشفيره إلا باستخدام مفتاح سري أو كلمة مرور.            ",
          divClass: "serv_4",
          link: "Get Started",
        },
        {
          id: 14,
          icon: icon_14,
          title: "صندوق الصيا",
          point1: "أداة لتصفية البريد الإلكتروني تساعد المستخدمين على مكافحة البريد العشوائي من خلال مطالبة المرسلين بالتحقق من هويتهم قبل تسليم بريدهم الإلكتروني إلى صندوق الوارد الخاص بالمستلم.            ",
          divClass: "serv_4",
          link: "Get Started",
        },
        {
          id: 15,
          icon: icon_15,
          title: " تكوين القائمة الرمادية            ",
          point1: "  إنها تقنية تصفية البريد العشوائي التي ترفض مؤقتًا رسائل البريد الإلكتروني الواردة من مرسلين غير معروفين ، مما يتطلب إعادة إرسالها قبل قبولها.            ",
          divClass: "serv_4",
          link: "Get Started",
        },
        {
          id: 16,
          icon: icon_16,
          title: " استخدام قرص البريد الإلكتروني            ",
          point1: " يعد استخدام قرص البريد الإلكتروني مقياسًا مهمًا للتتبع ، حيث يمكن أن يؤثر على أداء خدمة البريد الإلكتروني والجهاز أو الخادم الذي يتم استضافته عليه            ",
          divClass: "serv_4",
          link: "Get Started",
        },
      ];
    return (
        <OwlCarousel className="owl-theme" {...options}>
            {data.map((item, i) => (
                <div className="item webmail">
                    <div className="save-time">
                        <div className="save-icon">
                            <div className="custom-save-img">
                                <img src={item.icon} alt="" />
                            </div>
                            <h5 className="text-center save-text pt-3">{item.title}</h5>
                            <p>{item.point1}</p>
                        </div>
                    </div>
                </div>
            ))}
        </OwlCarousel>
    )
}

export default Web2