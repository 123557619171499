import React from "react";
import Global_Section_Main from "./Global_Section_Main";
import Global_Section_A from "./Global_Section_A";
import Global_Section_B from "./Global_Section_B";
import Banner from "../../../assets/images/sales-banner.svg";
import Section_A from "../../../assets/images/sales-a.svg";
import Section_B from "../../../assets/images/sales-b.svg";
import Section_C from "../../../assets/images/sales-c.svg";
import { useTranslation } from "react-i18next";

const SalesMarketing = () => {
    const { t } = useTranslation(["innerpages"]);
    return (
      <>
        <Global_Section_Main
          title={'CRM Software for Sales and Marketing'}
          description={'TaskTak unified sales and marketing platform is a low-cost solution for small businesses, and they also function as a central repository for all customer’s data. It allows teams to be more efficient in their outreach and communication, as they can quickly find the customer data, they need without having to search through multiple applications or systems'}
          image={Banner}
        />
        <Global_Section_A
          title={'Reduce expenses'}
          description={'Using a cloud-based unified CRM means you don\'t have to subscribe to multiple standalone systems or platforms. Your TaskTak account combines marketing, sales, and support, delivering a seamless experience. This not only saves you money but also reduces training costs and time spent switching between systems.'}
          image={Section_A}
          color="gray mt-top"
        />
        <Global_Section_B
          title={'Outperform your monthly sales goals'}
          description={'TaskTak CRM speeds up the sales cycle by obtaining higher leads, engaging customers in real-time, and administering diverse sales territories. It also allows you to segment leads, keep track of customer interactions, and quickly create personalized campaigns to reach the right prospects.'}
          image={Section_B}
          color="gray"
        />
        <Global_Section_A
          title={'Connect across many channels!'}
          description={'TaskTak lets you reach your audience wherever they are. By utilizing omnichannel marketing, your customers can communicate with you through live chat, social media, and email - all smoothly. Additionally, it allows you to track and analyze customer behavior across multiple channels, so you can better understand their needs and preferences.'}
          image={Section_C}
          color="white"
        />
      </>
  )
}

export default SalesMarketing