import React from 'react'
import Pricing from '../../components/pages/Pricing_Plane'
import Table from './Table';
import Accordions from './Accordions';
import Addons from './Addons';
import Reviews from './Reviews';


const Pricings = () => {
  return (
    <>
    <div className='pricing-bgw bannerss inner-bannersw position-relative text-center text-md-start'>
        <Pricing />
        <Table />
        <Addons />
        <Accordions />
        <Reviews />
        
    </div>
    
    </>
  )
}

export default Pricings